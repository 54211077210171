import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SectionType } from '@sparx/api/apis/sparx/reading/books/v2/book_v2';
import {
  Background,
  Font,
  FontScale,
  LetterSpacing,
  LineHeight,
  ReadingGuide,
  Settings,
} from '@sparx/api/apis/sparx/reading/users/v1/settings';
import { useClearAlert } from 'components/alert/alert';
import { BookContent } from 'components/book/book-content';
import {
  backgroundColorOptions,
  bookFontOptions,
  readingGuideOptions,
} from 'components/book/book-settings';
import { SectionParagraph } from 'components/bookv2/bookv2';
import { IBookSection } from 'components/bookv2/sections';
import { Button } from 'components/buttons/button';
import { Info } from 'components/panel/info';
import panelStyles from 'components/tasks/panel.module.css';
import { useUpdateUserSettings, useUserSettings } from 'queries/session';

import styles from './settings-alert.module.css';

const exampleV2: IBookSection = {
  sectionId: '1',
  type: SectionType.NORMAL,
  content: {
    oneofKind: 'paragraph',
    paragraph: {
      spans: [
        {
          spanId: 'span1',
          runs: [
            {
              content:
                'Alice was beginning to get very tired of sitting by her sister ' +
                'on the bank, and of having nothing to do: once or twice she had ' +
                'peeped into the book her sister was reading, but it had no pictures ' +
                'or conversations in it, “and what is the use of a book,” thought ' +
                'Alice “without pictures or conversations?”',
            },
          ],
          wordCount: 57,
          isAnchor: false,
        },
      ],
    },
  },
};

// Font scale is stored as an integer to avoid floating point complications. The stored value is 10 times the scale factor, e.g. 10 is 1x scaling.
const DEFAULT_FONT_SCALE_CONTINUOUS = 10; // 1x scaling
const MIN_FONT_SCALE_CONTINUOUS = 9; // 0.9x scaling
const MAX_FONT_SCALE_CONTINUOUS = 20; // 2x scaling

/**
 * This function converts from the old discrete font scale setting to the new. We can remove it when we have backfilled
 * the new setting for all students.
 */
const convertDiscreteFontScaleToContinuous = (fontScale: FontScale): number => {
  switch (fontScale) {
    case FontScale.NORMAL:
      return 10;
    case FontScale.LARGE:
      return 11;
    case FontScale.LARGER:
      return 12;
    case FontScale.LARGEST:
      return 13;
    default:
      return DEFAULT_FONT_SCALE_CONTINUOUS;
  }
};

const defaultSettings: Settings = {
  fontScale: FontScale.UNSPECIFIED,
  fontScaleContinuous: DEFAULT_FONT_SCALE_CONTINUOUS,
  lineHeightScale: LineHeight.UNSPECIFIED,
  letterSpacingScale: LetterSpacing.LETTER_SPACING_UNSPECIFIED,
  font: Font.UNSPECIFIED,
  background: Background.UNSPECIFIED,
  guide: ReadingGuide.UNSPECIFIED,
};

export const SettingsAlert = () => {
  const close = useClearAlert();

  const { data: settings } = useUserSettings();
  const mutateSettings = useUpdateUserSettings();

  const updateNumberValue = (update: Partial<Settings>) => {
    const newSettings = {
      ...defaultSettings,
      ...settings,
      ...update,
    };
    mutateSettings.mutate({ settings: newSettings });
  };

  const markerValues = [];
  for (let i = MIN_FONT_SCALE_CONTINUOUS; i <= MAX_FONT_SCALE_CONTINUOUS; i++) {
    markerValues.push(i);
  }
  let fontScaleContinuous: number = DEFAULT_FONT_SCALE_CONTINUOUS;
  if (settings?.fontScaleContinuous) {
    fontScaleContinuous = settings.fontScaleContinuous;
  } else if (settings?.fontScale) {
    fontScaleContinuous = convertDiscreteFontScaleToContinuous(settings.fontScale);
  }

  const fontScaleSlider = (
    <div className={styles.OptionButtonContainer}>
      <label className={styles.OptionButtonTitle}>Font Scale</label>
      <div className={styles.OptionSliderContainer}>
        <span className={styles.LittleA}>A</span>
        <div className={styles.OptionSlider}>
          <input
            type="range"
            id="font_scale_continuous"
            name="font_scale_continuous"
            min={MIN_FONT_SCALE_CONTINUOUS}
            max={MAX_FONT_SCALE_CONTINUOUS}
            value={fontScaleContinuous}
            onInput={value =>
              updateNumberValue({
                fontScaleContinuous:
                  parseInt(value.currentTarget.value) || DEFAULT_FONT_SCALE_CONTINUOUS,
              })
            }
            list="markers"
          />
          <datalist id="markers">
            {markerValues.map(value => (
              <option key={value} value={value}></option>
            ))}
          </datalist>
        </div>
        <span className={styles.BigA}>A</span>
        <Button
          variant="secondary"
          onClick={() => {
            updateNumberValue({
              fontScaleContinuous: Math.max(fontScaleContinuous - 1, MIN_FONT_SCALE_CONTINUOUS),
            });
          }}
          size="small"
          className={styles.PlusMinusButton}
          analyticsEvent={undefined}
          debounceMs={1}
        >
          -
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            updateNumberValue({
              fontScaleContinuous: Math.min(fontScaleContinuous + 1, MAX_FONT_SCALE_CONTINUOUS),
            });
          }}
          size="small"
          className={styles.PlusMinusButton}
          analyticsEvent={undefined}
          debounceMs={1}
        >
          +
        </Button>
      </div>
    </div>
  );

  return (
    <div className={panelStyles.OnboardingModal}>
      <div className={panelStyles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={panelStyles.ModalTitle}>Settings</h2>
      <p className={styles.Text}>
        If you&apos;re having trouble reading the text, try changing some of the font settings to
        something that you find easier to read.
      </p>
      <div>
        <BookContent className={styles.SettingsBookPreview}>
          <SectionParagraph section={exampleV2} />
        </BookContent>
        {fontScaleSlider}
        <OptionButtons
          title="Line Height"
          options={[
            { content: <>Normal</>, value: LineHeight.NORMAL },
            { content: <>Large</>, value: LineHeight.LARGE },
            { content: <>Larger</>, value: LineHeight.LARGER },
            { content: <>Largest</>, value: LineHeight.LARGEST },
          ]}
          onClick={value => updateNumberValue({ lineHeightScale: value })}
          current={settings?.lineHeightScale || 1}
        />
        <OptionButtons
          title="Letter Spacing"
          options={[
            { content: <>0</>, value: LetterSpacing.LETTER_SPACING_UNSPECIFIED },
            { content: <>1</>, value: LetterSpacing.LETTER_SPACING_1 },
            { content: <>2</>, value: LetterSpacing.LETTER_SPACING_2 },
            { content: <>3</>, value: LetterSpacing.LETTER_SPACING_3 },
          ]}
          onClick={value => updateNumberValue({ letterSpacingScale: value })}
          current={settings?.letterSpacingScale || 0}
        />
        <OptionButtons
          title="Font"
          options={bookFontOptions.map(font => ({
            content: <>{font.font}</>,
            value: font.key,
          }))}
          onClick={value => updateNumberValue({ font: value })}
          current={settings?.font}
        />
        <OptionButtons
          title="Background"
          options={backgroundColorOptions.map(color => ({
            content: <>{color.name}</>,
            value: color.key,
          }))}
          onClick={value => updateNumberValue({ background: value })}
          current={settings?.background}
        />

        <Info className="pointer-fine-hide" style={{ marginBottom: 20 }} showIcon={true}>
          The reading guide may not be supported on your current browser.
        </Info>

        <OptionButtons
          title="Reading Guide"
          options={readingGuideOptions.map(guide => ({
            content: <>{guide.name}</>,
            value: guide.key,
          }))}
          onClick={value => updateNumberValue({ guide: value })}
          current={settings?.guide}
        />
      </div>
      <div className={panelStyles.Buttons}>
        <Button
          variant="secondary"
          onClick={close}
          analyticsEvent={{
            category: 'Student Display Settings',
            action: 'Save settings',
            labels: settings
              ? {
                  fontScale: FontScale[settings.fontScale],
                  lineHeight: LineHeight[settings.lineHeightScale],
                  letterSpacing: LetterSpacing[settings.letterSpacingScale],
                  font: Font[settings.font],
                  background: Background[settings.background],
                  readingGuide: ReadingGuide[settings.guide],
                }
              : {},
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

interface IOptionButtonsProps<T> {
  options: {
    content: React.ReactNode;
    value: T;
  }[];
  current: T;
  onClick: (value: T) => void;
  title: string;
}

const OptionButtons = <T,>({ options, current, onClick, title }: IOptionButtonsProps<T>) => (
  <div className={styles.OptionButtonContainer}>
    <div className={styles.OptionButtonTitle}>{title}</div>
    <div className={styles.OptionButtons}>
      {options.map((option, i) => (
        <Button
          key={i}
          variant={current !== option.value ? 'secondary' : 'primary'}
          onClick={() => onClick(option.value)}
          size="small"
          className={styles.OptionButton}
          // We send the full options through on close not on each change.
          analyticsEvent={undefined}
        >
          {option.content}
        </Button>
      ))}
    </div>
  </div>
);
