// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reports/reportgen/v1/reader.proto" (package "sparx.reports.reportgen.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Date } from '../../../../../google/type/date';
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetActivityReportRequest
 */
export interface GetActivityReportRequest {
  /**
   * School to generate the report for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Whether to include student demographic data.
   *
   * @generated from protobuf field: bool include_demographic_data = 2;
   */
  includeDemographicData: boolean;
  /**
   * The earliest due date that should be considered. Must be specified with end_date.
   * The service will use current school year dates if these fields are not supplied.
   *
   * @generated from protobuf field: google.type.Date start_date = 3;
   */
  startDate?: Date;
  /**
   * The latest due date that should be considered. Must be specified with start_date.
   * The service will use current school year dates if these fields are not supplied.
   *
   * @generated from protobuf field: google.type.Date end_date = 4;
   */
  endDate?: Date;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetActivityReportResponse
 */
export interface GetActivityReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetAssessmentReportRequest
 */
export interface GetAssessmentReportRequest {
  /**
   * School to generate the report for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Whether to include student demographic data.
   *
   * @generated from protobuf field: bool include_demographic_data = 2;
   */
  includeDemographicData: boolean;
  /**
   * Whether to include the SAS and stanines columns.
   *
   * @generated from protobuf field: bool include_sas_stanines = 3;
   */
  includeSasStanines: boolean;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetAssessmentReportResponse
 */
export interface GetAssessmentReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetInsightsReportRequest
 */
export interface GetInsightsReportRequest {
  /**
   * School to generate the report for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reports.reportgen.v1.GetInsightsReportResponse
 */
export interface GetInsightsReportResponse {
  /**
   * Download URL for the generated report.
   *
   * @generated from protobuf field: string download_url = 1;
   */
  downloadUrl: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityReportRequest$Type extends MessageType<GetActivityReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetActivityReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'include_demographic_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 3, name: 'start_date', kind: 'message', T: () => Date },
      { no: 4, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetActivityReportRequest
 */
export const GetActivityReportRequest = new GetActivityReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActivityReportResponse$Type extends MessageType<GetActivityReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetActivityReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetActivityReportResponse
 */
export const GetActivityReportResponse = new GetActivityReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentReportRequest$Type extends MessageType<GetAssessmentReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetAssessmentReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'include_demographic_data',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'include_sas_stanines',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetAssessmentReportRequest
 */
export const GetAssessmentReportRequest = new GetAssessmentReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentReportResponse$Type extends MessageType<GetAssessmentReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetAssessmentReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetAssessmentReportResponse
 */
export const GetAssessmentReportResponse =
  new GetAssessmentReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInsightsReportRequest$Type extends MessageType<GetInsightsReportRequest> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetInsightsReportRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetInsightsReportRequest
 */
export const GetInsightsReportRequest = new GetInsightsReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInsightsReportResponse$Type extends MessageType<GetInsightsReportResponse> {
  constructor() {
    super('sparx.reports.reportgen.v1.GetInsightsReportResponse', [
      {
        no: 1,
        name: 'download_url',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reports.reportgen.v1.GetInsightsReportResponse
 */
export const GetInsightsReportResponse = new GetInsightsReportResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reports.reportgen.v1.ReaderReportGen
 */
export const ReaderReportGen = new ServiceType(
  'sparx.reports.reportgen.v1.ReaderReportGen',
  [
    {
      name: 'GetActivityReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: GetActivityReportRequest,
      O: GetActivityReportResponse,
    },
    {
      name: 'GetAssessmentReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: GetAssessmentReportRequest,
      O: GetAssessmentReportResponse,
    },
    {
      name: 'GetInsightsReport',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: '{school_name}',
        },
      },
      I: GetInsightsReportRequest,
      O: GetInsightsReportResponse,
    },
  ],
);
