import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import styles from 'views/student/library-view/subviews/home-view.module.css';
import { pathForView } from 'views/views';

export const LeagueNotJoined = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.StatName}>League not joined</div>
      <span
        style={{ marginTop: 10 }}
        onClick={() => navigate(pathForView(View.League))}
        className={styles.BookButtonsLink}
      >
        Choose your name
      </span>
    </>
  );
};
