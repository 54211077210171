import {
  faBarcode,
  faCheck,
  faChevronRight,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { IdentifierType } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { useMutation, useQuery } from '@tanstack/react-query';
import { contentClient, tasksClient } from 'api';
import classNames from 'classnames';
import { useAlert, useClearAlert } from 'components/alert/alert';
import { PageCountCheckAlert } from 'components/alert/page-count-check-alert';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import { Warning } from 'components/panel/info';
import goldIcon from 'components/sections/gold_icon_white.png';
import silverIcon from 'components/sections/silver_icon_white.png';
import { ShowMoreLess } from 'components/show-more/show-more';
import { BookIncompleteModal, BookLockedModal } from 'components/tasks/task-complete-modal';
import { Title } from 'components/title/title';
import { decode } from 'html-entities';
import { setBooksStale } from 'queries/books';
import { useListLibraryBooks, useNextScanTime } from 'queries/library-books';
import { useReaderLevel, useUser } from 'queries/session';
import { useStoreGetBookTaskData } from 'queries/tasks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isISBN13 } from 'utils/books';
import { View } from 'views';
import { pathForView } from 'views/views';

import { LibraryTitle } from '../components/library-content';
import styles from './add-gold-reader-book-view.module.css';
import { CustomBookBlacklisted } from './custom-book-page/custom-book-blacklisted';
import { Scanner } from './scanner';

// Error is returned from server when book is blocked manually for this specific
// school. Will be a PERMISSION_DENIED error.
const errorBookIsBlockedInSchool = 'book is blocked in school';

export const AddGoldReaderBookView = () => {
  const user = useUser();
  const readerLevel = useReaderLevel();
  const setAlert = useAlert();
  const clearAlert = useClearAlert();
  const { sendEvent } = useClientEvent();
  const navigate = useNavigate();

  const { data: nextScanTime } = useNextScanTime();
  const canAddGoldBook = nextScanTime === undefined || nextScanTime.isSameOrBefore(new Date());

  const { data: libraryBooks } = useListLibraryBooks();

  const [inputMethod, setInputMethod] = useState('unknown');
  const [isbn, setIsbn] = useState('');
  const { status, isLoading, isError, error, data, isSuccess, isFetching } = useQuery(
    ['isbn', isbn],
    () => contentClient.getCustomBook({ isbn, inputMethod }).response,
    {
      enabled: !!isbn,
      staleTime: Infinity,
      retry: false,
    },
  );

  const startScanner = () => {
    setAlert(
      <Scanner
        setISBN={code => {
          setCodeInput(code);
          setInputMethod('scanner');
          setIsbn(code);
          sendEvent({ category: 'book scanner', action: 'code', labels: { code } });
        }}
      />,
    );
  };

  const book = data?.book;
  const bookId = book?.name.replace('books/', '') || '';
  const storeData = useStoreGetBookTaskData();
  const mutation = useMutation(
    async ({ startPage, endPage }: { startPage: number; endPage: number }) => {
      if (!book) {
        throw new Error('book not set');
      }
      await contentClient.assignCustomBook({
        bookId,
        pageCount: endPage,
        startPage,
        isbn,
      }).response;
      return tasksClient.getBookTask({ bookId, requestReread: false }).response;
    },
    {
      onSuccess: data => {
        storeData(data);
        setBooksStale();
        navigate({
          pathname: pathForView(View.Library),
          search: `?book=${bookId}`,
        });
      },
      onError: (err: Error) => {
        if (err.message === 'incomplete book') {
          setAlert(<BookIncompleteModal bookID={bookId} />);
        } else if (err.message === 'book not available') {
          setAlert(<BookLockedModal />);
        } else {
          console.error('Failed to get book package', err);
        }
      },
    },
  );

  const [codeInput, setCodeInput] = useState('');
  const updateCodeInput = (val: string) => {
    const codeFiltered = val.replace(/[^0-9-]/g, '');
    setCodeInput(codeFiltered);
    setInputMethod('typed');

    const codeNumbers = codeFiltered.replace(/[^0-9]/g, '');
    if (isISBN13(codeNumbers)) {
      setIsbn(codeNumbers);
    } else {
      setIsbn('');
    }
  };

  const isISBN = isISBN13(codeInput.replace(/[^0-9]/g, ''));
  const haveBookAlready =
    book && (libraryBooks?.libraryBooks || []).find(b => b.metadataAbridged?.name === book.name);

  const addBook = () => {
    if (readerLevel === 'silver') {
      // Silver readers just add the book without any prompt
      mutation.mutate({ startPage: 0, endPage: 0 });
    } else {
      setAlert(
        <PageCountCheckAlert
          submit={(startPage, endPage) => {
            mutation.mutate({ startPage, endPage });
            clearAlert();
          }}
          expectedPageCount={book?.pageCount?.expectedPageCount}
        />,
      );
    }
  };

  useEffect(() => {
    if (readerLevel !== 'gold' && readerLevel !== 'silver')
      navigate(
        {
          pathname: pathForView(View.GoldReader),
        },
        { replace: true },
      );
  }, [navigate, readerLevel]);

  if (user?.statistics?.goldReaderDisabled) {
    return (
      <div className={styles.Page}>
        <div className={styles.TitleContainer}>
          <Title>Add a book</Title>
          <LibraryTitle crumb={{ title: 'My Library', to: pathForView(View.Explore) }}>
            Add a book
          </LibraryTitle>
        </div>
        <Warning className={styles.Warning} showIcon>
          You can&apos;t add a new Gold Reader book right now because Gold Reader is disabled.{' '}
          <Link
            to={pathForView(View.Explore)}
            className={styles.WarningLink}
            analyticsEvent={{ category: 'add-gold-reader-book', action: 'warning library link' }}
          >
            Back to My Library.
          </Link>
        </Warning>
      </div>
    );
  }

  return (
    <div className={styles.Page}>
      <div className={styles.PageContent}>
        <div className={styles.MaxWidth}>
          <Title>Add a book</Title>
          <LibraryTitle crumb={{ title: 'My Library', to: pathForView(View.Explore) }}>
            Add a book
          </LibraryTitle>
        </div>
        <div className={styles.MaxWidth}>
          {readerLevel === 'silver' ? <SilverReaderInfo /> : <GoldReaderInfo />}
          {canAddGoldBook ? (
            <div className={styles.InputContainerContainer}>
              <div className={styles.InputContainer}>
                <h3 className={styles.Text}>Scan the barcode</h3>
                <p className={styles.Text}>
                  Use your webcam or camera to scan the barcode on the back of the book. When you
                  are ready to scan, click the button below.
                </p>
                <Button
                  onClick={startScanner}
                  analyticsEvent={{ category: 'book scanner', action: 'open' }}
                >
                  <FontAwesomeIcon icon={faBarcode} style={{ marginRight: 10 }} />
                  Scan barcode
                </Button>
              </div>
              <span className={styles.Or}>
                <strong>or</strong>
              </span>
              <div className={styles.InputContainer}>
                <h3 className={styles.Text}>Enter the ISBN number</h3>
                <p className={styles.Text}>
                  The <code>ISBN</code> code can be found on the back of your book near the barcode.
                  The code should look something like <code>978-1-4472-8152-8</code>.
                </p>
                <div>
                  <input
                    className="text-input"
                    value={codeInput}
                    onChange={e => updateCodeInput(e.target.value)}
                    placeholder="Enter ISBN..."
                  />
                </div>
                {isISBN ? (
                  <div className={styles.ISBNValidate}>
                    <FontAwesomeIcon icon={faCheck} className={styles.ISBNValidateValid} />
                    Valid ISBN
                  </div>
                ) : codeInput.length > 12 ? (
                  <div className={styles.ISBNValidate}>
                    <FontAwesomeIcon icon={faTimes} className={styles.ISBNValidateInvalid} />
                    Not a valid ISBN
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className={styles.InputContainerContainer}>
              <div className={styles.ComeBackLaterContainer}>
                <h3>Come back later</h3>
                <p>
                  You scanned a book too recently. You can scan another book in{' '}
                  <b>{nextScanTime?.fromNow(true)}</b>.
                </p>
              </div>
            </div>
          )}
          {(isFetching || isSuccess || isError) && (
            <div className={styles.BookContainer}>
              <>
                {isLoading && (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin={true} style={{ marginRight: 10 }} />
                    Searching...
                  </>
                )}
                {isError &&
                  error &&
                  (error instanceof RpcError && error.code === 'NOT_FOUND' ? (
                    <>ISBN not found, please try a different book.</>
                  ) : error instanceof RpcError && error.code === 'PERMISSION_DENIED' ? (
                    error.message === errorBookIsBlockedInSchool ? (
                      <>Sorry, you are not allowed to read this book, please try a different one.</>
                    ) : readerLevel === 'silver' ? (
                      <>Sorry, only Gold Readers can scan any book.</>
                    ) : (
                      <>Sorry, you are not allowed to add your own books.</>
                    )
                  ) : (
                    <>Error in searching for book, please try again</>
                  ))}
                {status === 'success' && !book && <>ISBN not found, please try a different book</>}
                {book && (
                  <>
                    <BookImage book={book} className={styles.BookContainerBook} />
                    <div className={styles.BookContainerMeta}>
                      <h3>{book.title || 'Unknown Title'}</h3>
                      <div>{book.authors.join(', ') || 'Unknown Author'}</div>
                      {book.description && (
                        <div className={styles.Description}>
                          <ShowMoreLess content={decode(book.description)} maxLength={300} isHTML />
                        </div>
                      )}
                      <code>
                        ISBN{' '}
                        {book.identifiers.find(i => i.type === IdentifierType.ISBN_10)?.value ||
                          '?'}{' '}
                        /{' '}
                        {book.identifiers.find(i => i.type === IdentifierType.ISBN_13)?.value ||
                          '?'}
                      </code>
                      {book.blacklisted && <CustomBookBlacklisted />}
                    </div>
                  </>
                )}
              </>
            </div>
          )}
        </div>
      </div>
      <div className={styles.BookContainerControls}>
        {haveBookAlready ? (
          <Button
            analyticsEvent={undefined}
            disabled={book.blacklisted}
            onClick={() =>
              navigate({
                pathname: pathForView(View.Library),
                search: `?book=${book?.name.replace('books/', '')}`,
              })
            }
            size="small"
            rightIcon={<FontAwesomeIcon fixedWidth={true} icon={faChevronRight} />}
          >
            Read book
          </Button>
        ) : (
          <Button
            analyticsEvent={undefined}
            disabled={
              status !== 'success' ||
              !book ||
              (mutation.status !== 'idle' && mutation.status !== 'error') ||
              book?.blacklisted
            }
            onClick={addBook}
            size="small"
          >
            Add this book
          </Button>
        )}
      </div>
    </div>
  );
};

const GoldReaderInfo = () => {
  const user = useUser();
  return (
    <div className={classNames(styles.StatusBox, styles.StatusBoxGold)}>
      <h3 className={styles.Text}>
        <img src={goldIcon} alt="" />
        <span className="goldreader-text">You’re a Gold Reader</span>
      </h3>
      <p>
        <strong>Well done {user?.firstName}!</strong> Your hard work has earnt you a Gold Reader
        pass. You can add and read any book from the library, home, shops or friends. You can read
        the books you add to complete tasks.
      </p>
    </div>
  );
};

const SilverReaderInfo = () => (
  <div className={classNames(styles.StatusBox, styles.StatusBoxSilver)}>
    <h3 className={styles.Text}>
      <img src={silverIcon} alt="" />
      <span className="silverreader-text">You&apos;re a Silver Reader</span>
    </h3>
    <p className={styles.Text}>
      You can add Silver Reader books. You can read the books you add to complete tasks.
    </p>
    <div>
      <p className={styles.Text}>To continue to be a Silver Reader:</p>
      <ul style={{ marginBottom: 0, listStylePosition: 'inside' }}>
        <li>Read carefully and record what you read in your reading log</li>
        <li>Answer the questions about the story correctly</li>
      </ul>
    </div>
  </div>
);
