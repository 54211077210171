import classNames from 'classnames';

import styles from './booklog-task-view.module.css';

interface IBooklogSelectProps {
  label: string | React.ReactNode;
  value?: string;
  setValue: (val: string) => void;
  readonly?: boolean;
  options: { value: string; label: string }[];
  short?: boolean;
}

export const BooklogSelect = ({ label, value, setValue, options, short }: IBooklogSelectProps) => {
  return (
    <div className={styles.FormInput}>
      <div className={styles.FormInputLabel}>{label}</div>
      <select
        className={classNames(
          'text-input',
          styles.FormInputField,
          short && styles.FormInputFieldShort,
        )}
        value={value}
        onChange={e => setValue(e.target.value)}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
