import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Button } from 'components/buttons/button';
import { ProgressBar } from 'components/progress/progress-bar';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useNavigate } from 'react-router-dom';
import { makeItemList } from 'utils/names';
import { View } from 'views';
import styles from 'views/student/library-view/components/my-book-shelf.module.css';
import { pathForView } from 'views/views';

import { LibraryBookItem } from './library-book';
import { LibraryContainer } from './library-container';
import { LinkToBookChoice } from './link-to-book-choice';

export const MyBookShelf = ({
  books,
  canStartNewBook,
}: {
  books: LibraryBook[];
  canStartNewBook: boolean;
}) => {
  const navigate = useNavigate();
  const [, openStartBookDialog] = useStartBookDialog();
  // Get the first book of books
  const toBookChoice = () => navigate(pathForView(View.ChooseNewBook));
  const firstBook = books[0];
  const otherBooks = books.slice(1);
  return (
    <LibraryContainer>
      <div className={styles.Shelf}>
        {firstBook && firstBook.metadataAbridged && (
          <div className={styles.FirstBook}>
            <div className={styles.FirstBookCover}>
              <LibraryBookItem
                onClick={() => openStartBookDialog(firstBook.metadataAbridged)}
                book={firstBook}
                size="large"
              />
            </div>
            <div className={styles.FirstBookMeta}>
              <h2 className={styles.Title}>{firstBook.metadataAbridged.title}</h2>
              <div className={styles.Authors}>
                by {makeItemList(firstBook.metadataAbridged.authors)}
              </div>
              <div className={styles.Progress}>
                <ProgressBar
                  percent={(firstBook.studentBook?.progress || 0) * 100}
                  className={styles.BookProgressBar}
                  width="80%"
                />
              </div>
              <Button
                onClick={() => openStartBookDialog(firstBook.metadataAbridged)}
                analyticsEvent={{
                  category: 'library',
                  action: 'continue latest book',
                  labels: { bookName: firstBook.metadataAbridged?.name },
                }}
              >
                Continue reading
              </Button>
            </div>
          </div>
        )}
        {otherBooks.map(
          (book, i) =>
            book.metadataAbridged && (
              <LibraryBookItem
                book={book}
                onClick={() => openStartBookDialog(book.metadataAbridged)}
                key={book.metadataAbridged?.name || i}
              />
            ),
        )}
        <LinkToBookChoice
          disabled={!canStartNewBook}
          handleLinkClick={toBookChoice}
          analyticsAction="my shelf new book link"
        />
        {/* Padding to ensure that there are no full width tasks*/}
        <div className={styles.PaddingBox} />
        <div className={styles.PaddingBox} />
        <div className={styles.PaddingBox} />
      </div>
    </LibraryContainer>
  );
};
