import { faRedo, faSpaceShuttle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRegisterLogoutCleanup } from 'app/cleanup';
import { Button } from 'components/buttons/button';
import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { BookLockedModal } from 'components/tasks/task-complete-modal';
import { useBookContentV2 } from 'queries/books';
import { useLibraryBook } from 'queries/library-books';
import { UserRole, useUserHasRole } from 'queries/session';
import { useTaskLoadActions, useTaskState } from 'queries/tasks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { bookIDForReadingTask, useCommonActions } from 'utils/tasks';
import { View } from 'views';
import { BooklogTaskView } from 'views/student/task-view/booklog-task-view/booklog-task-view';
import { PaperbackReadingTaskView } from 'views/student/task-view/paperback-reading-task-view';
import { pathForView } from 'views/views';

import { AssessmentTaskView } from './assessment-task-view';

export const TaskView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const taskId = searchParams.get('id') || ''; // Set a default TODO: check if not set?

  // On unmount, the task id is momentarily set to undefined,
  // so we need to store the initial task id.
  const [initialTaskId] = useState(taskId);
  const taskActions = useCommonActions(initialTaskId);
  const loadActions = useTaskLoadActions(initialTaskId);
  const [debug, setDebug] = useState<boolean>(false);

  const {
    data: task,
    isLoading: isLoadingTask,
    isError: isErrorTask,
  } = useTaskState(initialTaskId);

  const canAdminRetry = useUserHasRole(UserRole.EQMRead);
  const retryTask = useCallback(() => taskActions.retry(), [taskActions]);

  // Try to load the book for the task
  const bookID = task && bookIDForReadingTask(task.state);
  const needBook = !task || task.state?.state.oneofKind === 'paperback'; // paperback tasks need the book

  const {
    data: book,
    isLoading: isLoadingBook,
    error: bookError,
  } = useBookContentV2({
    bookId: bookID,
    disabled: !needBook,
    taskId: initialTaskId,
  });
  const { data: libraryBook, isLoading: isLoadingLibraryBook } = useLibraryBook(bookID || '');

  // Effect for triggering the automatic start of the task
  useEffect(() => {
    if (task && !task.state) {
      retryTask();
    }
  }, [task, retryTask]);

  useRegisterLogoutCleanup(() =>
    loadActions.mutate({ action: { oneofKind: 'unload', unload: true } }),
  );

  // When the page is loaded dispatch an event
  useEffect(() => {
    if (initialTaskId) {
      // When navigate to
      loadActions.mutate({ action: { oneofKind: 'load', load: true } });
      // When navigate away
      return () => loadActions.mutate({ action: { oneofKind: 'unload', unload: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTaskId]);

  if (((isLoadingBook || isLoadingLibraryBook) && needBook) || isLoadingTask) {
    return <Loading text="Loading..." />;
  }
  if (!task || isErrorTask) {
    return <ErrorMessage message={'Cannot find task'} />;
  }
  if (bookError instanceof Error && bookError.message === 'book not available') {
    return (
      <div style={{ padding: '5rem 0' }}>
        <BookLockedModal onClose={() => navigate(pathForView(View.Library), { replace: true })} />
      </div>
    );
  }

  let component: React.ReactElement;
  switch (task.spec?.spec.oneofKind) {
    case 'paperback':
      if (book && libraryBook?.metadataAbridged) {
        component = (
          <PaperbackReadingTaskView
            task={task}
            book={book}
            bookMeta={libraryBook.metadataAbridged}
          />
        );
      } else {
        component = <Loading text="Loading task..." />;
      }
      break;
    case 'booklog':
      if (libraryBook && libraryBook.metadataAbridged) {
        component = <BooklogTaskView task={task} bookMeta={libraryBook.metadataAbridged} />;
      } else {
        component = <Loading text="Loading task..." />;
      }
      break;
    case 'assessment':
      if (task.state?.state.oneofKind === 'assessment') {
        component = <AssessmentTaskView task={task} state={task.state?.state.assessment} />;
      } else {
        component = <Loading text="Loading task..." />;
      }
      break;
    default:
      return <ErrorMessage message={`Unknown task type: ${task.type}`} />;
  }

  let controlButtons = null;
  if (canAdminRetry) {
    controlButtons = (
      <div>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            gap: 10,
            top: 10,
            right: 25,
            zIndex: 5,
          }}
        >
          <Button
            analyticsEvent={undefined}
            onClick={() => retryTask()}
            size="small"
            color="danger"
          >
            <FontAwesomeIcon icon={faRedo} style={{ marginRight: 5 }} />
            Restart
          </Button>
          <Button analyticsEvent={undefined} onClick={() => setDebug(!debug)} size="small">
            <FontAwesomeIcon icon={faSpaceShuttle} style={{ marginRight: 5 }} />
            Debug
          </Button>
        </div>
        {debug && (
          <pre
            style={{
              textAlign: 'left',
              fontSize: 12,
              lineHeight: '1em',
              maxHeight: '30vh',
              overflowY: 'scroll',
              border: '2px dotted var(--carbon-grey-40)',
              margin: 5,
            }}
          >
            {JSON.stringify(task, undefined, '    ')}
          </pre>
        )}
      </div>
    );
  }

  return (
    <>
      {controlButtons}
      {component}
    </>
  );
};
