import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/table/table.module.css';
import { ITableHeaderSortProps, TableHeaderCell } from 'components/table/table-header-cell';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

export interface IColumn<SortType> {
  name?: string | JSX.Element;
  width: string;
  sort?: SortType;
  tooltip?: ReactNode;
  sticky?: boolean;
  align?: 'left' | 'right' | 'center';
  // Whether to add padding to the left of the column title, e.g. for when the
  // column has a left border. Default is false.
  padLeft?: boolean;
  // Whether to add padding to the right of the column title. Default is true.
  padRight?: boolean;
}

interface ITableContainerProps<SortType> {
  children?: React.ReactNode;
  sortProps?: ITableHeaderSortProps<SortType>;
  columns?: (IColumn<SortType> | null | false)[];
  // A flat table is flat to the page - i.e. does not have a border radius
  flat?: boolean;
  // Sets all rows to a pixel height
  fixedRowHeight?: number;
  // max-height of the table
  maxHeight?: number;
  // Whether to show an overlay spinner over the contents
  loading?: boolean;
}

export const TableContainer = <T,>({
  children,
  sortProps,
  columns,
  flat = false,
  fixedRowHeight,
  maxHeight,
  loading = false,
}: ITableContainerProps<T>) => {
  const containerRef = useRef<HTMLElement>(null);
  const [showScrollClassLeft, setShowScrollClassLeft] = useState(false);
  const [showScrollClassRight, setShowScrollClassRight] = useState(false);
  const onScroll = useCallback(() => {
    const target = containerRef.current;
    if (target) {
      setShowScrollClassLeft(target.scrollLeft !== 0);
      setShowScrollClassRight(target.scrollLeft !== target.scrollWidth - target.clientWidth);
    }
  }, [containerRef, setShowScrollClassLeft, setShowScrollClassRight]);

  useEffect(() => {
    onScroll(); // On initial load
    window.addEventListener('resize', onScroll);
    return () => window.removeEventListener('resize', onScroll);
  }, [onScroll]);

  return (
    <div className={styles.TableWrapper} role="table">
      <div
        className={classNames(
          styles.TableShowMore,
          showScrollClassRight && styles.TableShowMoreActive,
        )}
      />
      <ScrollContainer
        className={classNames(styles.Table, {
          [styles.TableScrolled]: showScrollClassLeft,
          [styles.TableScrollable]: true,
          [styles.TableFlat]: flat,
          [styles.TableNormal]: !flat,
        })}
        style={
          columns && {
            gridTemplateColumns: columns.map(c => (c ? c.width : '')).join(' '),
            gridAutoRows: fixedRowHeight ? `${fixedRowHeight}px` : '',
            maxHeight: maxHeight ? `${maxHeight}px` : '',
          }
        }
        onScroll={onScroll}
        innerRef={containerRef}
        hideScrollbars={false}
      >
        {columns && (
          <>
            {columns.map(
              (col, i) =>
                col && (
                  <TableHeaderCell key={i} {...sortProps} {...col}>
                    {col.name}
                  </TableHeaderCell>
                ),
            )}
          </>
        )}
        {children || (loading && <div className={styles.LoadingPlaceholder} />)}
      </ScrollContainer>
      {loading && (
        <div className={classNames(styles.LoadingOverlay)}>
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
    </div>
  );
};
