import { QueryClient } from '@tanstack/react-query';
import { handleErr } from 'app/handle-err';

// TODO: global error handling
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: err => handleErr(err),
    },
    mutations: {
      onError: err => handleErr(err),
    },
  },
});

export const combineQueryStates = (...queryStates: { isLoading: boolean; isError: boolean }[]) => ({
  isLoading: queryStates.some(s => s.isLoading),
  isError: queryStates.some(s => s.isError),
});
