import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MatureTheme } from '@sparx/api/apis/sparx/reading/books/v1/book';
import classNames from 'classnames';
import { Tooltip } from 'components/tooltip/Tooltip';
import { getReadableStrFromTheme } from 'utils/mature-themes';

import styles from './MatureThemesWarning.module.css';

interface MatureThemesWarningProps {
  // Interface support book and abridged metadata
  book?: {
    matureThemes: MatureTheme[];
  };
  className?: string;
}

export const MatureThemesWarning = ({ book, className }: MatureThemesWarningProps) => {
  const themes = book?.matureThemes || [];
  if (themes.length === 0) {
    return null;
  }

  const tooltipMessage = (
    <>
      <p>This book contains themes of:</p>
      <ul>
        {themes.map(theme => (
          <li key={theme}>{getReadableStrFromTheme(theme)}</li>
        ))}
      </ul>
    </>
  );

  return (
    <Tooltip content={tooltipMessage}>
      <span className={classNames(styles.Warning, className)}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <span>This book contains mature themes</span>
      </span>
    </Tooltip>
  );
};
