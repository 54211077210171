// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/misintegration/wondesync/v1/wondesync.proto" (package "sparx.wondesync.v1.wondesync", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { BoolValue } from '../../../../../google/protobuf/wrappers';
import { Parent } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { DemographicData } from '../../../school/student/demographic/v1/demographic';
import { Student } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { StudentGroup } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { Class } from '../../wondewitch/v1/wondewitch';
import { Subject } from '../../wondewitch/v1/wondewitch';
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListSubjectsRequest
 */
export interface ListSubjectsRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListSubjectsResponse
 */
export interface ListSubjectsResponse {
  /**
   * Unordered list of Subjects.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Subject subjects = 1;
   */
  subjects: Subject[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListClassesRequest
 */
export interface ListClassesRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The Wonde ID for the subject. Optional.
   *
   * @generated from protobuf field: string subject_wonde_id = 2;
   */
  subjectWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListClassesResponse
 */
export interface ListClassesResponse {
  /**
   * Unordered list of Classes.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Class classes = 1;
   */
  classes: Class[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncClass
 */
export interface SyncClass {
  /**
   * Wonde ID of the class. Required.
   *
   * @generated from protobuf field: string wonde_id = 1;
   */
  wondeId: string;
  /**
   * Sparx year group ID that should be associated with this class. Required.
   *
   * @generated from protobuf field: string year_group_id = 2;
   */
  yearGroupId: string;
  /**
   * Sparx student group ID, this will sync an existing group to the Wonde
   * Class. Optional
   *
   * @generated from protobuf field: string student_group_id = 3;
   */
  studentGroupId: string;
  /**
   * Sparx product subject for the group. See the productsubject go package for
   * product examples. An empty string will default to Sparx Maths.
   *
   * @generated from protobuf field: string product_subject = 4;
   */
  productSubject: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.StudentClassResolutionPair
 */
export interface StudentClassResolutionPair {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string class_wonde_id = 2;
   */
  classWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncSchoolRequest
 */
export interface SyncSchoolRequest {
  /**
   * The ID of the school to sync.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * When true, the request should return immediately once the sync has started
   *
   * @generated from protobuf field: bool async = 5;
   */
  async: boolean;
  /**
   * The Wonde ID, year group (Sparx) ID pairs of the classes to sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.SyncClass classes = 3;
   */
  classes: SyncClass[];
  /**
   * Resolved student to class mappings for conflicting students.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.StudentClassResolutionPair student_class_resolution_pairs = 4;
   */
  studentClassResolutionPairs: StudentClassResolutionPair[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncSchoolResponse
 */
export interface SyncSchoolResponse {}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.IsSyncInProgressRequest
 */
export interface IsSyncInProgressRequest {
  /**
   * The ID of the school to request status for.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.IsSyncInProgressResponse
 */
export interface IsSyncInProgressResponse {
  /**
   * @generated from protobuf field: bool in_progress = 1;
   */
  inProgress: boolean;
  /**
   * Whether the last manual sync failed
   *
   * @generated from protobuf field: bool last_sync_failed = 2;
   */
  lastSyncFailed: boolean;
}
/**
 * Response type for the new preview endpoint.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.PreviewSyncSchoolV2Response
 */
export interface PreviewSyncSchoolV2Response {
  /**
   * Classes that will be added in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewClass new_classes = 1;
   */
  newClasses: PreviewClass[];
  /**
   * Classes that will be removed in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewClass removed_classes = 2;
   */
  removedClasses: PreviewClass[];
  /**
   * Existing classes that will be modified in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.ModifiedClass modified_classes = 3;
   */
  modifiedClasses: ModifiedClass[];
  /**
   * Students that will be added in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewStudent new_students = 4;
   */
  newStudents: PreviewStudent[];
  /**
   * Students that will be removed in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewStudent removed_students = 5;
   */
  removedStudents: PreviewStudent[];
  /**
   * Existing students that will be modified in the sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.ModifiedStudent modified_students = 6;
   */
  modifiedStudents: ModifiedStudent[];
  /**
   * Existing students that will be un-expired and possibly modified in the
   * sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.ModifiedStudent unexpired_students = 7;
   */
  unexpiredStudents: ModifiedStudent[];
  /**
   * Students which are missing required information in the MIS.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.InvalidStudent invalid_students = 8;
   */
  invalidStudents: InvalidStudent[];
  /**
   * Students that will be removed in the sync because they are not in any class.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewStudent removed_students_with_no_class = 9;
   */
  removedStudentsWithNoClass: PreviewStudent[];
}
/**
 * A representation of a class for use when previewing sync changes.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.PreviewClass
 */
export interface PreviewClass {
  /**
   * The Sparx ID of the class.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * The Wonde ID of the class.
   *
   * @generated from protobuf field: string wonde_id = 2;
   */
  wondeId: string;
  /**
   * The name of the class.
   *
   * @generated from protobuf field: string display_name = 3;
   */
  displayName: string;
  /**
   * The ID of the year group the class is in.
   *
   * @generated from protobuf field: string year_group_id = 4;
   */
  yearGroupId: string;
}
/**
 * A representation of a student for use when previewing sync changes.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.PreviewStudent
 */
export interface PreviewStudent {
  /**
   * The Sparx ID of the student.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * The Wonde ID of the student.
   *
   * @generated from protobuf field: string wonde_id = 2;
   */
  wondeId: string;
  /**
   * The first name of the student.
   *
   * @generated from protobuf field: string first_name = 3;
   */
  firstName: string;
  /**
   * The last name of the student.
   *
   * @generated from protobuf field: string last_name = 4;
   */
  lastName: string;
  /**
   * The gender of the student.
   *
   * @generated from protobuf field: string gender = 5;
   */
  gender: string;
  /**
   * The date of birth of the student (in ISO-8601 format, i.e. YYYY-MM-DD.)
   *
   * @generated from protobuf field: string date_of_birth = 6;
   */
  dateOfBirth: string;
  /**
   * The name of the class the student is in. This field is deprecated,
   * you should use group_wonde_ids instead.
   *
   * @deprecated
   * @generated from protobuf field: string class_name = 7 [deprecated = true];
   */
  className: string;
  /**
   * The Wonde ID of the class the student is in. This field is deprecated,
   * you should use group_wonde_ids instead.
   *
   * @deprecated
   * @generated from protobuf field: string class_wonde_id = 8 [deprecated = true];
   */
  classWondeId: string;
  /**
   * The parent contacts attached to the student.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewParent parent_contacts = 9;
   */
  parentContacts: PreviewParent[];
  /**
   * Map of subject to wonde_id of group the student is in for that subject
   *
   * @generated from protobuf field: map<string, sparx.wondesync.v1.wondesync.PreviewStudentClass> group_wonde_ids = 10;
   */
  groupWondeIds: {
    [key: string]: PreviewStudentClass;
  };
  /**
   * The contact email address for the student. See parent_contacts for parents.
   *
   * @generated from protobuf field: string email_address = 11;
   */
  emailAddress: string;
}
/**
 * A representation of a student with missing information in the MIS.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.InvalidStudent
 */
export interface InvalidStudent {
  /**
   * The Sparx ID of the student.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * The Wonde ID of the student.
   *
   * @generated from protobuf field: string wonde_id = 2;
   */
  wondeId: string;
  /**
   * The first name of the student, if present.
   *
   * @generated from protobuf field: string first_name = 3;
   */
  firstName: string;
  /**
   * The last name of the student, if present.
   *
   * @generated from protobuf field: string last_name = 4;
   */
  lastName: string;
  /**
   * The date of birth of the student, if present.
   *
   * @generated from protobuf field: string date_of_birth = 5;
   */
  dateOfBirth: string;
  /**
   * Wonde classes the student is in.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.PreviewStudentClass wonde_groups = 6;
   */
  wondeGroups: PreviewStudentClass[];
}
/**
 * A representation of a student's parent contact for use when previewing sync
 * changes.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.PreviewParent
 */
export interface PreviewParent {
  /**
   * The first name of the parent.
   *
   * @generated from protobuf field: string first_name = 1;
   */
  firstName: string;
  /**
   * The last name of the parent.
   *
   * @generated from protobuf field: string last_name = 2;
   */
  lastName: string;
  /**
   * The email address of the parent.
   *
   * @generated from protobuf field: string email = 3;
   */
  email: string;
  /**
   * The wondeID of the parent.
   *
   * @generated from protobuf field: string wonde_id = 4;
   */
  wondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.PreviewStudentClass
 */
export interface PreviewStudentClass {
  /**
   * The name of the class the student is in.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The Wonde ID of the class the student is in.
   *
   * @generated from protobuf field: string wonde_id = 2;
   */
  wondeId: string;
}
/**
 * A representation of a class for use when previewing changes to an existing
 * class.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ModifiedClass
 */
export interface ModifiedClass {
  /**
   * The original state of the class before sync changes are applied.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.PreviewClass before = 1;
   */
  before?: PreviewClass;
  /**
   * The state the class will be in after sync changes are applied.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.PreviewClass after = 2;
   */
  after?: PreviewClass;
}
/**
 * A representation of a student for use when previewing changes to an existing
 * student.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ModifiedStudent
 */
export interface ModifiedStudent {
  /**
   * The original state of the student before sync changes are applied.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.PreviewStudent before = 1;
   */
  before?: PreviewStudent;
  /**
   * The state the student will be in after sync changes are applied.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.PreviewStudent after = 2;
   */
  after?: PreviewStudent;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.AutoSyncSchoolRequest
 */
export interface AutoSyncSchoolRequest {
  /**
   * The ID of the school to sync.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * Skip the check to ensure the school is due to sync.
   *
   * @generated from protobuf field: bool force = 2;
   */
  force: boolean;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.AutoSyncSchoolResponse
 */
export interface AutoSyncSchoolResponse {}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsRequest
 */
export interface AutoSyncAllSchoolsRequest {
  /**
   * Sync schools regardless of time of day and whether the school has already
   * synced today
   *
   * @generated from protobuf field: bool force = 2;
   */
  force: boolean;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsResponse
 */
export interface AutoSyncAllSchoolsResponse {}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ClassReference
 */
export interface ClassReference {
  /**
   * The Wonde ID's for the class. Required.
   *
   * @generated from protobuf field: string class_wonde_id = 1;
   */
  classWondeId: string;
  /**
   * The Class name to search for i.e. '7x/Ma1'. Optional.
   *
   * @generated from protobuf field: string class_name = 2;
   */
  className: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.GetClassesRequest
 */
export interface GetClassesRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * Class Reference i.e. wondeID & name. Required.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.ClassReference class_references = 3;
   */
  classReferences: ClassReference[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.GetClassesResponse
 */
export interface GetClassesResponse {
  /**
   * Unordered list of Classes.
   *
   * @generated from protobuf field: repeated sparx.wondewitch.v1.wondewitch.Class classes = 1;
   */
  classes: Class[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.UpdateStatusRequest
 */
export interface UpdateStatusRequest {
  /**
   * @generated from protobuf field: bool dry_run = 1;
   */
  dryRun: boolean;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.UpdateStatusResponse
 */
export interface UpdateStatusResponse {
  /**
   * Count of schools that have adopted a new status as a result of this call
   * (or in the case of dry_run = true, count of schools that would have
   * changed status). Note that for a school to change status it needs to be
   * persisted in schoolstore.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.StatusCounts changed = 1;
   */
  changed?: StatusCounts;
  /**
   * Count of schools in schoolstore of each status.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.StatusCounts overall = 2;
   */
  overall?: StatusCounts;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.StatusCounts
 */
export interface StatusCounts {
  /**
   * Schools that have no Wonde auth status (request has not been made)
   *
   * @generated from protobuf field: int64 unrequested = 1;
   */
  unrequested: number;
  /**
   * Schools that are pending Wonde authorisation
   *
   * @generated from protobuf field: int64 pending = 2;
   */
  pending: number;
  /**
   * Schools that have approved Wonde authorisation but are yet to complete audit
   *
   * @generated from protobuf field: int64 approved = 7;
   */
  approved: number;
  /**
   * Schools that have approved Wonde authorisation and have completed audit
   *
   * @generated from protobuf field: int64 authorised = 3;
   */
  authorised: number;
  /**
   * Schools that have declined Wonde authorisation
   *
   * @generated from protobuf field: int64 declined = 4;
   */
  declined: number;
  /**
   * Schools that have revoked Wonde authorisation
   *
   * @generated from protobuf field: int64 revoked = 5;
   */
  revoked: number;
  /**
   * Schools for which the most recent Wonde authorisation request was not
   * successfully submitted
   *
   * @generated from protobuf field: int64 failed = 6;
   */
  failed: number;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SchoolWondeSubject
 */
export interface SchoolWondeSubject {
  /**
   * The unique Wonde ID for the subject.
   *
   * @generated from protobuf field: string school_wonde_subject_id = 1;
   */
  schoolWondeSubjectId: string;
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * Display name of the subject.
   *
   * @generated from protobuf field: string wonde_subject_name = 3;
   */
  wondeSubjectName: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsRequest
 */
export interface ListSchoolWondeSubjectsRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsResponse
 */
export interface ListSchoolWondeSubjectsResponse {
  /**
   * The subjects the school has imported classes for.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.SchoolWondeSubject school_wonde_subjects = 1;
   */
  schoolWondeSubjects: SchoolWondeSubject[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsRequest
 */
export interface SetSchoolWondeSubjectsRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The subjects the school has imported classes for.
   *
   * @generated from protobuf field: repeated string school_wonde_subjects = 2;
   */
  schoolWondeSubjects: string[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsResponse
 */
export interface SetSchoolWondeSubjectsResponse {
  /**
   * The subjects the school has imported classes for.
   *
   * @generated from protobuf field: repeated string school_wonde_subjects = 1;
   */
  schoolWondeSubjects: string[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewRequest
 */
export interface ParentContactsSettingsPreviewRequest {
  /**
   * The Sparx ID for the school. Required.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse
 */
export interface ParentContactsSettingsPreviewResponse {
  /**
   * The number of contacts that would be imported using the default logic.
   *
   * @generated from protobuf field: int32 default_setting_contact_count = 1;
   */
  defaultSettingContactCount: number;
  /**
   * The number of contacts that would be imported at each priority level.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse.PriorityCount settings_previews = 2;
   */
  settingsPreviews: ParentContactsSettingsPreviewResponse_PriorityCount[];
}
/**
 * Allows us to return a priority and the number of contacts associated with it.
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse.PriorityCount
 */
export interface ParentContactsSettingsPreviewResponse_PriorityCount {
  /**
   * @generated from protobuf field: int32 priority = 1;
   */
  priority: number;
  /**
   * @generated from protobuf field: int32 contact_count = 2;
   */
  contactCount: number;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.CreateSyncPlanRequest
 */
export interface CreateSyncPlanRequest {
  /**
   * Resource name of the school to prepare a sync for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The Wonde ID, year group (Sparx) ID pairs of the classes to sync.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.SyncClass classes = 2;
   */
  classes: SyncClass[];
  /**
   * Resolved student to class mappings for conflicting students.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.StudentClassResolutionPair student_class_resolution_pairs = 3;
   */
  studentClassResolutionPairs: StudentClassResolutionPair[];
  /**
   * Which products to do the sync for
   *
   * @generated from protobuf field: repeated string product_filter = 4;
   */
  productFilter: string[];
  /**
   * Setting this to true will result in a sync plan that is not stored.
   *
   * @generated from protobuf field: bool dry_run = 5;
   */
  dryRun: boolean;
  /**
   * Setting this to true will cause the loading and processing of student demographic
   * data to be skipped.
   *
   * @generated from protobuf field: bool skip_demographics = 6;
   */
  skipDemographics: boolean;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.CreateSyncPlanResponse
 */
export interface CreateSyncPlanResponse {
  /**
   * The created sync plan
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncPlan sync_plan = 1;
   */
  syncPlan?: SyncPlan;
  /**
   * The invalid Wonde students exlucluded from the sync plan
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.InvalidStudent invalid_students = 2;
   */
  invalidStudents: InvalidStudent[];
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ApplySyncPlanRequest
 */
export interface ApplySyncPlanRequest {
  /**
   * Resource name of the sync plan to apply
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * When true, the request should return immediately once the sync has started.
   *
   * @generated from protobuf field: bool async = 2;
   */
  async: boolean;
}
/**
 * TODO some kind of status
 *
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ApplySyncPlanResponse
 */
export interface ApplySyncPlanResponse {}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction
 */
export interface SyncAction {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'addClass';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddClassAction add_class = 1;
         */
        addClass: SyncAction_AddClassAction;
      }
    | {
        oneofKind: 'updateClass';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateClassAction update_class = 2;
         */
        updateClass: SyncAction_UpdateClassAction;
      }
    | {
        oneofKind: 'removeClass';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveClassAction remove_class = 3;
         */
        removeClass: SyncAction_RemoveClassAction;
      }
    | {
        oneofKind: 'addStudent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddStudentAction add_student = 4;
         */
        addStudent: SyncAction_AddStudentAction;
      }
    | {
        oneofKind: 'updateStudent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentAction update_student = 5;
         */
        updateStudent: SyncAction_UpdateStudentAction;
      }
    | {
        oneofKind: 'removeStudent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentAction remove_student = 6;
         */
        removeStudent: SyncAction_RemoveStudentAction;
      }
    | {
        oneofKind: 'updateStudentUpn';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentUpnAction update_student_upn = 7;
         */
        updateStudentUpn: SyncAction_UpdateStudentUpnAction;
      }
    | {
        oneofKind: 'addRegistrationGroup';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupAction add_registration_group = 8;
         */
        addRegistrationGroup: SyncAction_AddRegistrationGroupAction;
      }
    | {
        oneofKind: 'updateRegistrationGroup';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateRegistrationGroupAction update_registration_group = 9;
         */
        updateRegistrationGroup: SyncAction_UpdateRegistrationGroupAction;
      }
    | {
        oneofKind: 'removeRegistrationGroup';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupAction remove_registration_group = 10;
         */
        removeRegistrationGroup: SyncAction_RemoveRegistrationGroupAction;
      }
    | {
        oneofKind: 'addRegistrationGroupMembership';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupMembershipAction add_registration_group_membership = 11;
         */
        addRegistrationGroupMembership: SyncAction_AddRegistrationGroupMembershipAction;
      }
    | {
        oneofKind: 'removeRegistrationGroupMembership';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupMembershipAction remove_registration_group_membership = 12;
         */
        removeRegistrationGroupMembership: SyncAction_RemoveRegistrationGroupMembershipAction;
      }
    | {
        oneofKind: 'addStudentGroupMembership';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddStudentGroupMembershipAction add_student_group_membership = 13;
         */
        addStudentGroupMembership: SyncAction_AddStudentGroupMembershipAction;
      }
    | {
        oneofKind: 'removeStudentGroupMembership';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentGroupMembershipAction remove_student_group_membership = 14;
         */
        removeStudentGroupMembership: SyncAction_RemoveStudentGroupMembershipAction;
      }
    | {
        oneofKind: 'updateStudentDemographics';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentDemographicsAction update_student_demographics = 15;
         */
        updateStudentDemographics: SyncAction_UpdateStudentDemographicsAction;
      }
    | {
        oneofKind: 'addParent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddParentAction add_parent = 16;
         */
        addParent: SyncAction_AddParentAction;
      }
    | {
        oneofKind: 'updateParent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.UpdateParentAction update_parent = 17;
         */
        updateParent: SyncAction_UpdateParentAction;
      }
    | {
        oneofKind: 'removeParent';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveParentAction remove_parent = 18;
         */
        removeParent: SyncAction_RemoveParentAction;
      }
    | {
        oneofKind: 'addParentLink';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.AddParentLinkAction add_parent_link = 19;
         */
        addParentLink: SyncAction_AddParentLinkAction;
      }
    | {
        oneofKind: 'removeParentLink';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveParentLinkAction remove_parent_link = 20;
         */
        removeParentLink: SyncAction_RemoveParentLinkAction;
      }
    | {
        oneofKind: 'removeStudentWithNoClass';
        /**
         * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentWithNoClassAction remove_student_with_no_class = 21;
         */
        removeStudentWithNoClass: SyncAction_RemoveStudentWithNoClassAction;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddClassAction
 */
export interface SyncAction_AddClassAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup class = 1;
   */
  class?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateClassAction
 */
export interface SyncAction_UpdateClassAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup old_class = 1;
   */
  oldClass?: StudentGroup;
  /**
   * @generated from protobuf field: schoolman.StudentGroup new_class = 2;
   */
  newClass?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveClassAction
 */
export interface SyncAction_RemoveClassAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup class = 1;
   */
  class?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddStudentAction
 */
export interface SyncAction_AddStudentAction {
  /**
   * @generated from protobuf field: schoolman.Student student = 1;
   */
  student?: Student;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentAction
 */
export interface SyncAction_UpdateStudentAction {
  /**
   * @generated from protobuf field: schoolman.Student old_student = 1;
   */
  oldStudent?: Student;
  /**
   * @generated from protobuf field: schoolman.Student new_student = 2;
   */
  newStudent?: Student;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentAction
 */
export interface SyncAction_RemoveStudentAction {
  /**
   * @generated from protobuf field: schoolman.Student student = 1;
   */
  student?: Student;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupAction
 */
export interface SyncAction_AddRegistrationGroupAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup registration_group = 1;
   */
  registrationGroup?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateRegistrationGroupAction
 */
export interface SyncAction_UpdateRegistrationGroupAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup updated_registration_group = 1;
   */
  updatedRegistrationGroup?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupAction
 */
export interface SyncAction_RemoveRegistrationGroupAction {
  /**
   * @generated from protobuf field: schoolman.StudentGroup registration_group = 1;
   */
  registrationGroup?: StudentGroup;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentUpnAction
 */
export interface SyncAction_UpdateStudentUpnAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string old_upn = 2;
   */
  oldUpn: string;
  /**
   * @generated from protobuf field: string new_upn = 3;
   */
  newUpn: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupMembershipAction
 */
export interface SyncAction_AddRegistrationGroupMembershipAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string registration_group_wonde_id = 2;
   */
  registrationGroupWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupMembershipAction
 */
export interface SyncAction_RemoveRegistrationGroupMembershipAction {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string registration_group_id = 2;
   */
  registrationGroupId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddStudentGroupMembershipAction
 */
export interface SyncAction_AddStudentGroupMembershipAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string student_group_wonde_id = 2;
   */
  studentGroupWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentGroupMembershipAction
 */
export interface SyncAction_RemoveStudentGroupMembershipAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string student_group_wonde_id = 2;
   */
  studentGroupWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentDemographicsAction
 */
export interface SyncAction_UpdateStudentDemographicsAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: sparx.school.student.demographic.v1.DemographicData updated_demographics = 2;
   */
  updatedDemographics?: DemographicData;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddParentAction
 */
export interface SyncAction_AddParentAction {
  /**
   * @generated from protobuf field: schoolman.Parent parent = 1;
   */
  parent?: Parent;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateParentAction
 */
export interface SyncAction_UpdateParentAction {
  /**
   * @generated from protobuf field: schoolman.Parent old_parent = 1;
   */
  oldParent?: Parent;
  /**
   * @generated from protobuf field: schoolman.Parent new_parent = 2;
   */
  newParent?: Parent;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveParentAction
 */
export interface SyncAction_RemoveParentAction {
  /**
   * @generated from protobuf field: schoolman.Parent parent = 1;
   */
  parent?: Parent;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddParentLinkAction
 */
export interface SyncAction_AddParentLinkAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string parent_wonde_id = 2;
   */
  parentWondeId: string;
  /**
   * @generated from protobuf field: google.protobuf.BoolValue student_email_enabled = 3;
   */
  studentEmailEnabled?: BoolValue;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveParentLinkAction
 */
export interface SyncAction_RemoveParentLinkAction {
  /**
   * @generated from protobuf field: string student_wonde_id = 1;
   */
  studentWondeId: string;
  /**
   * @generated from protobuf field: string parent_wonde_id = 2;
   */
  parentWondeId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentWithNoClassAction
 */
export interface SyncAction_RemoveStudentWithNoClassAction {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.SyncPlan
 */
export interface SyncPlan {
  /**
   * Resource name of the sync plan
   *   Format: schools/<school_id>/syncplan/<id>
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * List of sync actions required to synchronise a school.
   *
   * @generated from protobuf field: repeated sparx.wondesync.v1.wondesync.SyncAction actions = 2;
   */
  actions: SyncAction[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;
  /**
   * Timestamp after which the plan will be invalid and unable to be applied.
   *
   * @generated from protobuf field: google.protobuf.Timestamp valid_until = 4;
   */
  validUntil?: Timestamp;
  /**
   * Mode in which the plan was created.
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncMode mode = 5;
   */
  mode: SyncMode;
  /**
   * User ID that created the sync plan. This will be blank when mode is set to SYNC_MODE_AUTO.
   *
   * @generated from protobuf field: string user_id = 6;
   */
  userId: string;
  /**
   * The time at which a plan started to be applied
   *
   * @generated from protobuf field: google.protobuf.Timestamp start_apply_time = 7;
   */
  startApplyTime?: Timestamp;
  /**
   * The time at which a plan finished being applied (including failures)
   *
   * @generated from protobuf field: google.protobuf.Timestamp applied_time = 8;
   */
  appliedTime?: Timestamp;
  /**
   * Whether the apply of a plan succeeded if applicable
   *
   * @generated from protobuf field: bool applied_successfully = 9;
   */
  appliedSuccessfully: boolean;
  /**
   * The product the sync plan was created for
   *
   * @generated from protobuf field: string product = 10;
   */
  product: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ExpireSyncPlansRequest
 */
export interface ExpireSyncPlansRequest {}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.ExpireSyncPlansResponse
 */
export interface ExpireSyncPlansResponse {
  /**
   * The number of sync plans that were deleted from the database.
   *
   * @generated from protobuf field: int32 expired_count = 1;
   */
  expiredCount: number;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.GetSyncPlanRequest
 */
export interface GetSyncPlanRequest {
  /**
   * Resource name of the sync plan to get
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.wondesync.v1.wondesync.GetSyncPlanResponse
 */
export interface GetSyncPlanResponse {
  /**
   * The sync plan
   *
   * @generated from protobuf field: sparx.wondesync.v1.wondesync.SyncPlan sync_plan = 1;
   */
  syncPlan?: SyncPlan;
}
/**
 * @generated from protobuf enum sparx.wondesync.v1.wondesync.SyncMode
 */
export enum SyncMode {
  /**
   * @generated from protobuf enum value: SYNC_MODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SYNC_MODE_MANUAL = 1;
   */
  MANUAL = 1,
  /**
   * @generated from protobuf enum value: SYNC_MODE_AUTO = 2;
   */
  AUTO = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class ListSubjectsRequest$Type extends MessageType<ListSubjectsRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListSubjectsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListSubjectsRequest
 */
export const ListSubjectsRequest = new ListSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubjectsResponse$Type extends MessageType<ListSubjectsResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListSubjectsResponse', [
      {
        no: 1,
        name: 'subjects',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Subject,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListSubjectsResponse
 */
export const ListSubjectsResponse = new ListSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassesRequest$Type extends MessageType<ListClassesRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListClassesRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'subject_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListClassesRequest
 */
export const ListClassesRequest = new ListClassesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassesResponse$Type extends MessageType<ListClassesResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListClassesResponse', [
      {
        no: 1,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Class,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListClassesResponse
 */
export const ListClassesResponse = new ListClassesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncClass$Type extends MessageType<SyncClass> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncClass', [
      { no: 1, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'year_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'product_subject',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncClass
 */
export const SyncClass = new SyncClass$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentClassResolutionPair$Type extends MessageType<StudentClassResolutionPair> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.StudentClassResolutionPair', [
      {
        no: 1,
        name: 'student_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'class_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.StudentClassResolutionPair
 */
export const StudentClassResolutionPair = new StudentClassResolutionPair$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncSchoolRequest$Type extends MessageType<SyncSchoolRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncSchoolRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'async', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SyncClass,
      },
      {
        no: 4,
        name: 'student_class_resolution_pairs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentClassResolutionPair,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncSchoolRequest
 */
export const SyncSchoolRequest = new SyncSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncSchoolResponse$Type extends MessageType<SyncSchoolResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncSchoolResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncSchoolResponse
 */
export const SyncSchoolResponse = new SyncSchoolResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsSyncInProgressRequest$Type extends MessageType<IsSyncInProgressRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.IsSyncInProgressRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.IsSyncInProgressRequest
 */
export const IsSyncInProgressRequest = new IsSyncInProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IsSyncInProgressResponse$Type extends MessageType<IsSyncInProgressResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.IsSyncInProgressResponse', [
      { no: 1, name: 'in_progress', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 2,
        name: 'last_sync_failed',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.IsSyncInProgressResponse
 */
export const IsSyncInProgressResponse = new IsSyncInProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewSyncSchoolV2Response$Type extends MessageType<PreviewSyncSchoolV2Response> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.PreviewSyncSchoolV2Response', [
      {
        no: 1,
        name: 'new_classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewClass,
      },
      {
        no: 2,
        name: 'removed_classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewClass,
      },
      {
        no: 3,
        name: 'modified_classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ModifiedClass,
      },
      {
        no: 4,
        name: 'new_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewStudent,
      },
      {
        no: 5,
        name: 'removed_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewStudent,
      },
      {
        no: 6,
        name: 'modified_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ModifiedStudent,
      },
      {
        no: 7,
        name: 'unexpired_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ModifiedStudent,
      },
      {
        no: 8,
        name: 'invalid_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => InvalidStudent,
      },
      {
        no: 9,
        name: 'removed_students_with_no_class',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewStudent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.PreviewSyncSchoolV2Response
 */
export const PreviewSyncSchoolV2Response =
  new PreviewSyncSchoolV2Response$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewClass$Type extends MessageType<PreviewClass> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.PreviewClass', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'year_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.PreviewClass
 */
export const PreviewClass = new PreviewClass$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewStudent$Type extends MessageType<PreviewStudent> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.PreviewStudent', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'gender', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'date_of_birth',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'class_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'class_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'parent_contacts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewParent,
      },
      {
        no: 10,
        name: 'group_wonde_ids',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => PreviewStudentClass },
      },
      {
        no: 11,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.PreviewStudent
 */
export const PreviewStudent = new PreviewStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvalidStudent$Type extends MessageType<InvalidStudent> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.InvalidStudent', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'date_of_birth',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'wonde_groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PreviewStudentClass,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.InvalidStudent
 */
export const InvalidStudent = new InvalidStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewParent$Type extends MessageType<PreviewParent> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.PreviewParent', [
      { no: 1, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.PreviewParent
 */
export const PreviewParent = new PreviewParent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PreviewStudentClass$Type extends MessageType<PreviewStudentClass> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.PreviewStudentClass', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'wonde_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.PreviewStudentClass
 */
export const PreviewStudentClass = new PreviewStudentClass$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifiedClass$Type extends MessageType<ModifiedClass> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ModifiedClass', [
      { no: 1, name: 'before', kind: 'message', T: () => PreviewClass },
      { no: 2, name: 'after', kind: 'message', T: () => PreviewClass },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ModifiedClass
 */
export const ModifiedClass = new ModifiedClass$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ModifiedStudent$Type extends MessageType<ModifiedStudent> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ModifiedStudent', [
      { no: 1, name: 'before', kind: 'message', T: () => PreviewStudent },
      { no: 2, name: 'after', kind: 'message', T: () => PreviewStudent },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ModifiedStudent
 */
export const ModifiedStudent = new ModifiedStudent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AutoSyncSchoolRequest$Type extends MessageType<AutoSyncSchoolRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.AutoSyncSchoolRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'force', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.AutoSyncSchoolRequest
 */
export const AutoSyncSchoolRequest = new AutoSyncSchoolRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AutoSyncSchoolResponse$Type extends MessageType<AutoSyncSchoolResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.AutoSyncSchoolResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.AutoSyncSchoolResponse
 */
export const AutoSyncSchoolResponse = new AutoSyncSchoolResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AutoSyncAllSchoolsRequest$Type extends MessageType<AutoSyncAllSchoolsRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsRequest', [
      { no: 2, name: 'force', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsRequest
 */
export const AutoSyncAllSchoolsRequest = new AutoSyncAllSchoolsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AutoSyncAllSchoolsResponse$Type extends MessageType<AutoSyncAllSchoolsResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.AutoSyncAllSchoolsResponse
 */
export const AutoSyncAllSchoolsResponse = new AutoSyncAllSchoolsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClassReference$Type extends MessageType<ClassReference> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ClassReference', [
      {
        no: 1,
        name: 'class_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'class_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ClassReference
 */
export const ClassReference = new ClassReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassesRequest$Type extends MessageType<GetClassesRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.GetClassesRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'class_references',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ClassReference,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.GetClassesRequest
 */
export const GetClassesRequest = new GetClassesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClassesResponse$Type extends MessageType<GetClassesResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.GetClassesResponse', [
      {
        no: 1,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Class,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.GetClassesResponse
 */
export const GetClassesResponse = new GetClassesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStatusRequest$Type extends MessageType<UpdateStatusRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.UpdateStatusRequest', [
      { no: 1, name: 'dry_run', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.UpdateStatusRequest
 */
export const UpdateStatusRequest = new UpdateStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStatusResponse$Type extends MessageType<UpdateStatusResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.UpdateStatusResponse', [
      { no: 1, name: 'changed', kind: 'message', T: () => StatusCounts },
      { no: 2, name: 'overall', kind: 'message', T: () => StatusCounts },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.UpdateStatusResponse
 */
export const UpdateStatusResponse = new UpdateStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusCounts$Type extends MessageType<StatusCounts> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.StatusCounts', [
      {
        no: 1,
        name: 'unrequested',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 2,
        name: 'pending',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 7,
        name: 'approved',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 3,
        name: 'authorised',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 4,
        name: 'declined',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 5,
        name: 'revoked',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 6,
        name: 'failed',
        kind: 'scalar',
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.StatusCounts
 */
export const StatusCounts = new StatusCounts$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolWondeSubject$Type extends MessageType<SchoolWondeSubject> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SchoolWondeSubject', [
      {
        no: 1,
        name: 'school_wonde_subject_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'wonde_subject_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SchoolWondeSubject
 */
export const SchoolWondeSubject = new SchoolWondeSubject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolWondeSubjectsRequest$Type extends MessageType<ListSchoolWondeSubjectsRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsRequest
 */
export const ListSchoolWondeSubjectsRequest =
  new ListSchoolWondeSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSchoolWondeSubjectsResponse$Type extends MessageType<ListSchoolWondeSubjectsResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsResponse', [
      {
        no: 1,
        name: 'school_wonde_subjects',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolWondeSubject,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ListSchoolWondeSubjectsResponse
 */
export const ListSchoolWondeSubjectsResponse =
  new ListSchoolWondeSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSchoolWondeSubjectsRequest$Type extends MessageType<SetSchoolWondeSubjectsRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_wonde_subjects',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsRequest
 */
export const SetSchoolWondeSubjectsRequest =
  new SetSchoolWondeSubjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetSchoolWondeSubjectsResponse$Type extends MessageType<SetSchoolWondeSubjectsResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsResponse', [
      {
        no: 1,
        name: 'school_wonde_subjects',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SetSchoolWondeSubjectsResponse
 */
export const SetSchoolWondeSubjectsResponse =
  new SetSchoolWondeSubjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentContactsSettingsPreviewRequest$Type extends MessageType<ParentContactsSettingsPreviewRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewRequest
 */
export const ParentContactsSettingsPreviewRequest =
  new ParentContactsSettingsPreviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentContactsSettingsPreviewResponse$Type extends MessageType<ParentContactsSettingsPreviewResponse> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse',
      [
        {
          no: 1,
          name: 'default_setting_contact_count',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
        {
          no: 2,
          name: 'settings_previews',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => ParentContactsSettingsPreviewResponse_PriorityCount,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse
 */
export const ParentContactsSettingsPreviewResponse =
  new ParentContactsSettingsPreviewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParentContactsSettingsPreviewResponse_PriorityCount$Type extends MessageType<ParentContactsSettingsPreviewResponse_PriorityCount> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse.PriorityCount',
      [
        { no: 1, name: 'priority', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
        {
          no: 2,
          name: 'contact_count',
          kind: 'scalar',
          T: 5 /*ScalarType.INT32*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ParentContactsSettingsPreviewResponse.PriorityCount
 */
export const ParentContactsSettingsPreviewResponse_PriorityCount =
  new ParentContactsSettingsPreviewResponse_PriorityCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSyncPlanRequest$Type extends MessageType<CreateSyncPlanRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.CreateSyncPlanRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'classes',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SyncClass,
      },
      {
        no: 3,
        name: 'student_class_resolution_pairs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentClassResolutionPair,
      },
      {
        no: 4,
        name: 'product_filter',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'dry_run', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 6,
        name: 'skip_demographics',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.CreateSyncPlanRequest
 */
export const CreateSyncPlanRequest = new CreateSyncPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSyncPlanResponse$Type extends MessageType<CreateSyncPlanResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.CreateSyncPlanResponse', [
      { no: 1, name: 'sync_plan', kind: 'message', T: () => SyncPlan },
      {
        no: 2,
        name: 'invalid_students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => InvalidStudent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.CreateSyncPlanResponse
 */
export const CreateSyncPlanResponse = new CreateSyncPlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplySyncPlanRequest$Type extends MessageType<ApplySyncPlanRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ApplySyncPlanRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'async', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ApplySyncPlanRequest
 */
export const ApplySyncPlanRequest = new ApplySyncPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplySyncPlanResponse$Type extends MessageType<ApplySyncPlanResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ApplySyncPlanResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ApplySyncPlanResponse
 */
export const ApplySyncPlanResponse = new ApplySyncPlanResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction$Type extends MessageType<SyncAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction', [
      {
        no: 1,
        name: 'add_class',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddClassAction,
      },
      {
        no: 2,
        name: 'update_class',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateClassAction,
      },
      {
        no: 3,
        name: 'remove_class',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveClassAction,
      },
      {
        no: 4,
        name: 'add_student',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddStudentAction,
      },
      {
        no: 5,
        name: 'update_student',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateStudentAction,
      },
      {
        no: 6,
        name: 'remove_student',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveStudentAction,
      },
      {
        no: 7,
        name: 'update_student_upn',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateStudentUpnAction,
      },
      {
        no: 8,
        name: 'add_registration_group',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddRegistrationGroupAction,
      },
      {
        no: 9,
        name: 'update_registration_group',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateRegistrationGroupAction,
      },
      {
        no: 10,
        name: 'remove_registration_group',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveRegistrationGroupAction,
      },
      {
        no: 11,
        name: 'add_registration_group_membership',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddRegistrationGroupMembershipAction,
      },
      {
        no: 12,
        name: 'remove_registration_group_membership',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveRegistrationGroupMembershipAction,
      },
      {
        no: 13,
        name: 'add_student_group_membership',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddStudentGroupMembershipAction,
      },
      {
        no: 14,
        name: 'remove_student_group_membership',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveStudentGroupMembershipAction,
      },
      {
        no: 15,
        name: 'update_student_demographics',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateStudentDemographicsAction,
      },
      {
        no: 16,
        name: 'add_parent',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddParentAction,
      },
      {
        no: 17,
        name: 'update_parent',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_UpdateParentAction,
      },
      {
        no: 18,
        name: 'remove_parent',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveParentAction,
      },
      {
        no: 19,
        name: 'add_parent_link',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_AddParentLinkAction,
      },
      {
        no: 20,
        name: 'remove_parent_link',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveParentLinkAction,
      },
      {
        no: 21,
        name: 'remove_student_with_no_class',
        kind: 'message',
        oneof: 'action',
        T: () => SyncAction_RemoveStudentWithNoClassAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction
 */
export const SyncAction = new SyncAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddClassAction$Type extends MessageType<SyncAction_AddClassAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.AddClassAction', [
      { no: 1, name: 'class', kind: 'message', T: () => StudentGroup },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddClassAction
 */
export const SyncAction_AddClassAction = new SyncAction_AddClassAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateClassAction$Type extends MessageType<SyncAction_UpdateClassAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.UpdateClassAction', [
      { no: 1, name: 'old_class', kind: 'message', T: () => StudentGroup },
      { no: 2, name: 'new_class', kind: 'message', T: () => StudentGroup },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateClassAction
 */
export const SyncAction_UpdateClassAction =
  new SyncAction_UpdateClassAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveClassAction$Type extends MessageType<SyncAction_RemoveClassAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.RemoveClassAction', [
      { no: 1, name: 'class', kind: 'message', T: () => StudentGroup },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveClassAction
 */
export const SyncAction_RemoveClassAction =
  new SyncAction_RemoveClassAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddStudentAction$Type extends MessageType<SyncAction_AddStudentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.AddStudentAction', [
      { no: 1, name: 'student', kind: 'message', T: () => Student },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddStudentAction
 */
export const SyncAction_AddStudentAction =
  new SyncAction_AddStudentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateStudentAction$Type extends MessageType<SyncAction_UpdateStudentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentAction', [
      { no: 1, name: 'old_student', kind: 'message', T: () => Student },
      { no: 2, name: 'new_student', kind: 'message', T: () => Student },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentAction
 */
export const SyncAction_UpdateStudentAction =
  new SyncAction_UpdateStudentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveStudentAction$Type extends MessageType<SyncAction_RemoveStudentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentAction', [
      { no: 1, name: 'student', kind: 'message', T: () => Student },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentAction
 */
export const SyncAction_RemoveStudentAction =
  new SyncAction_RemoveStudentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddRegistrationGroupAction$Type extends MessageType<SyncAction_AddRegistrationGroupAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupAction',
      [
        {
          no: 1,
          name: 'registration_group',
          kind: 'message',
          T: () => StudentGroup,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupAction
 */
export const SyncAction_AddRegistrationGroupAction =
  new SyncAction_AddRegistrationGroupAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateRegistrationGroupAction$Type extends MessageType<SyncAction_UpdateRegistrationGroupAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.UpdateRegistrationGroupAction',
      [
        {
          no: 1,
          name: 'updated_registration_group',
          kind: 'message',
          T: () => StudentGroup,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateRegistrationGroupAction
 */
export const SyncAction_UpdateRegistrationGroupAction =
  new SyncAction_UpdateRegistrationGroupAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveRegistrationGroupAction$Type extends MessageType<SyncAction_RemoveRegistrationGroupAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupAction',
      [
        {
          no: 1,
          name: 'registration_group',
          kind: 'message',
          T: () => StudentGroup,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupAction
 */
export const SyncAction_RemoveRegistrationGroupAction =
  new SyncAction_RemoveRegistrationGroupAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateStudentUpnAction$Type extends MessageType<SyncAction_UpdateStudentUpnAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentUpnAction', [
      {
        no: 1,
        name: 'student_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'old_upn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'new_upn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentUpnAction
 */
export const SyncAction_UpdateStudentUpnAction =
  new SyncAction_UpdateStudentUpnAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddRegistrationGroupMembershipAction$Type extends MessageType<SyncAction_AddRegistrationGroupMembershipAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupMembershipAction',
      [
        {
          no: 1,
          name: 'student_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'registration_group_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddRegistrationGroupMembershipAction
 */
export const SyncAction_AddRegistrationGroupMembershipAction =
  new SyncAction_AddRegistrationGroupMembershipAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveRegistrationGroupMembershipAction$Type extends MessageType<SyncAction_RemoveRegistrationGroupMembershipAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupMembershipAction',
      [
        {
          no: 1,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'registration_group_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveRegistrationGroupMembershipAction
 */
export const SyncAction_RemoveRegistrationGroupMembershipAction =
  new SyncAction_RemoveRegistrationGroupMembershipAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddStudentGroupMembershipAction$Type extends MessageType<SyncAction_AddStudentGroupMembershipAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.AddStudentGroupMembershipAction',
      [
        {
          no: 1,
          name: 'student_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_group_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddStudentGroupMembershipAction
 */
export const SyncAction_AddStudentGroupMembershipAction =
  new SyncAction_AddStudentGroupMembershipAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveStudentGroupMembershipAction$Type extends MessageType<SyncAction_RemoveStudentGroupMembershipAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentGroupMembershipAction',
      [
        {
          no: 1,
          name: 'student_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_group_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentGroupMembershipAction
 */
export const SyncAction_RemoveStudentGroupMembershipAction =
  new SyncAction_RemoveStudentGroupMembershipAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateStudentDemographicsAction$Type extends MessageType<SyncAction_UpdateStudentDemographicsAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentDemographicsAction',
      [
        {
          no: 1,
          name: 'student_wonde_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'updated_demographics',
          kind: 'message',
          T: () => DemographicData,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateStudentDemographicsAction
 */
export const SyncAction_UpdateStudentDemographicsAction =
  new SyncAction_UpdateStudentDemographicsAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddParentAction$Type extends MessageType<SyncAction_AddParentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.AddParentAction', [
      { no: 1, name: 'parent', kind: 'message', T: () => Parent },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddParentAction
 */
export const SyncAction_AddParentAction = new SyncAction_AddParentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_UpdateParentAction$Type extends MessageType<SyncAction_UpdateParentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.UpdateParentAction', [
      { no: 1, name: 'old_parent', kind: 'message', T: () => Parent },
      { no: 2, name: 'new_parent', kind: 'message', T: () => Parent },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.UpdateParentAction
 */
export const SyncAction_UpdateParentAction =
  new SyncAction_UpdateParentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveParentAction$Type extends MessageType<SyncAction_RemoveParentAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.RemoveParentAction', [
      { no: 1, name: 'parent', kind: 'message', T: () => Parent },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveParentAction
 */
export const SyncAction_RemoveParentAction =
  new SyncAction_RemoveParentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_AddParentLinkAction$Type extends MessageType<SyncAction_AddParentLinkAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.AddParentLinkAction', [
      {
        no: 1,
        name: 'student_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'parent_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'student_email_enabled',
        kind: 'message',
        T: () => BoolValue,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.AddParentLinkAction
 */
export const SyncAction_AddParentLinkAction =
  new SyncAction_AddParentLinkAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveParentLinkAction$Type extends MessageType<SyncAction_RemoveParentLinkAction> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncAction.RemoveParentLinkAction', [
      {
        no: 1,
        name: 'student_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'parent_wonde_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveParentLinkAction
 */
export const SyncAction_RemoveParentLinkAction =
  new SyncAction_RemoveParentLinkAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncAction_RemoveStudentWithNoClassAction$Type extends MessageType<SyncAction_RemoveStudentWithNoClassAction> {
  constructor() {
    super(
      'sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentWithNoClassAction',
      [
        {
          no: 1,
          name: 'student_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncAction.RemoveStudentWithNoClassAction
 */
export const SyncAction_RemoveStudentWithNoClassAction =
  new SyncAction_RemoveStudentWithNoClassAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncPlan$Type extends MessageType<SyncPlan> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.SyncPlan', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'actions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SyncAction,
      },
      { no: 3, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'valid_until', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'mode',
        kind: 'enum',
        T: () => [
          'sparx.wondesync.v1.wondesync.SyncMode',
          SyncMode,
          'SYNC_MODE_',
        ],
      },
      { no: 6, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'start_apply_time', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'applied_time', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'applied_successfully',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 10, name: 'product', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.SyncPlan
 */
export const SyncPlan = new SyncPlan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpireSyncPlansRequest$Type extends MessageType<ExpireSyncPlansRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ExpireSyncPlansRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ExpireSyncPlansRequest
 */
export const ExpireSyncPlansRequest = new ExpireSyncPlansRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpireSyncPlansResponse$Type extends MessageType<ExpireSyncPlansResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.ExpireSyncPlansResponse', [
      {
        no: 1,
        name: 'expired_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.ExpireSyncPlansResponse
 */
export const ExpireSyncPlansResponse = new ExpireSyncPlansResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSyncPlanRequest$Type extends MessageType<GetSyncPlanRequest> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.GetSyncPlanRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.GetSyncPlanRequest
 */
export const GetSyncPlanRequest = new GetSyncPlanRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSyncPlanResponse$Type extends MessageType<GetSyncPlanResponse> {
  constructor() {
    super('sparx.wondesync.v1.wondesync.GetSyncPlanResponse', [
      { no: 1, name: 'sync_plan', kind: 'message', T: () => SyncPlan },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.wondesync.v1.wondesync.GetSyncPlanResponse
 */
export const GetSyncPlanResponse = new GetSyncPlanResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.wondesync.v1.wondesync.WondeSync
 */
export const WondeSync = new ServiceType(
  'sparx.wondesync.v1.wondesync.WondeSync',
  [
    {
      name: 'ListSubjects',
      options: {
        'google.api.http': { get: '/v1/schools/{school_id}/subjects' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'mis:subject',
          domain: 'schools/{school_id}',
        },
      },
      I: ListSubjectsRequest,
      O: ListSubjectsResponse,
    },
    {
      name: 'ListClasses',
      options: {
        'google.api.http': {
          get: '/v1/schools/{school_id}/subjects/{subject_wonde_id}/classes',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: ListClassesRequest,
      O: ListClassesResponse,
    },
    {
      name: 'GetClasses',
      options: {
        'google.api.http': {
          post: '/v1/schools/{school_id}/classes',
          body: '*',
        },
        'sparx.api.auth': {
          action: 'read',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: GetClassesRequest,
      O: GetClassesResponse,
    },
    {
      name: 'SyncSchool',
      options: {
        'google.api.http': { post: '/v1/schools/{school_id}:sync', body: '*' },
        'sparx.api.auth': {
          action: 'sync',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: SyncSchoolRequest,
      O: SyncSchoolResponse,
    },
    {
      name: 'IsSyncInProgress',
      options: {
        'google.api.http': { get: '/v1/schools/{school_id}/sync' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: IsSyncInProgressRequest,
      O: IsSyncInProgressResponse,
    },
    {
      name: 'PreviewSyncSchoolV2',
      options: {
        'sparx.api.auth': {
          action: 'sync',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: SyncSchoolRequest,
      O: PreviewSyncSchoolV2Response,
    },
    {
      name: 'ListSchoolWondeSubjects',
      options: {
        'google.api.http': { get: '/v1/schools/{school_id}/wondeSubjects' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:studentgroup',
          domain: 'schools/{school_id}',
        },
      },
      I: ListSchoolWondeSubjectsRequest,
      O: ListSchoolWondeSubjectsResponse,
    },
    {
      name: 'SetSchoolWondeSubjects',
      options: {
        'google.api.http': { post: '/v1/schools/{school_id}/wondeSubjects' },
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:studentgroup',
          domain: 'schools/{school_id}',
        },
      },
      I: SetSchoolWondeSubjectsRequest,
      O: SetSchoolWondeSubjectsResponse,
    },
    {
      name: 'ParentContactsSettingsPreview',
      options: {
        'sparx.api.auth': {
          action: 'sync',
          resource: 'mis:class',
          domain: 'schools/{school_id}',
        },
      },
      I: ParentContactsSettingsPreviewRequest,
      O: ParentContactsSettingsPreviewResponse,
    },
    {
      name: 'CreateSyncPlan',
      options: {
        'sparx.api.auth': {
          action: 'sync',
          resource: 'mis:class',
          domain: '{school_name}',
        },
      },
      I: CreateSyncPlanRequest,
      O: CreateSyncPlanResponse,
    },
    {
      name: 'ApplySyncPlan',
      options: {
        'sparx.api.auth': {
          action: 'sync',
          resource: 'mis:class',
          domain: '{name|parent}',
        },
      },
      I: ApplySyncPlanRequest,
      O: ApplySyncPlanResponse,
    },
  ],
);
/**
 * @generated ServiceType for protobuf service sparx.wondesync.v1.wondesync.WondeSyncInternal
 */
export const WondeSyncInternal = new ServiceType(
  'sparx.wondesync.v1.wondesync.WondeSyncInternal',
  [
    {
      name: 'AutoSyncSchool',
      options: {
        'google.api.http': {
          post: '/v2/schools/{school_id}:autosync',
          body: '*',
        },
      },
      I: AutoSyncSchoolRequest,
      O: AutoSyncSchoolResponse,
    },
    {
      name: 'AutoSyncAllSchools',
      options: { 'google.api.http': { post: '/v1/autosync', body: '*' } },
      I: AutoSyncAllSchoolsRequest,
      O: AutoSyncAllSchoolsResponse,
    },
    {
      name: 'UpdateStatus',
      options: { 'google.api.http': { post: '/v1/schools/updatestatus' } },
      I: UpdateStatusRequest,
      O: UpdateStatusResponse,
    },
    {
      name: 'ExpireSyncPlans',
      options: {
        'google.api.http': { post: '/v1/expiresyncplans', body: '*' },
      },
      I: ExpireSyncPlansRequest,
      O: ExpireSyncPlansResponse,
    },
    {
      name: 'GetSyncPlan',
      options: { 'google.api.http': { get: '/v1/{name}' } },
      I: GetSyncPlanRequest,
      O: GetSyncPlanResponse,
    },
  ],
);
