import { OnboardingStep, OnboardingTask } from '@sparx/api/apis/sparx/reading/users/v1/onboarding';

import { AwaitingSRTResult } from './tasks/awaiting-srt-result/awaiting-srt-result';
import { BaselineAssessmentOnboardingStep } from './tasks/baseline-assessment/baseline-assessment';
import { TrainingBook } from './tasks/training-book/training-book';
import { Unknown } from './tasks/unknown/unknown';

export interface TaskComponentProps {
  onNextStep: () => void;
  task: OnboardingTask;
}

export const Tasks: {
  [key in OnboardingStep]: { title: string; component: React.FC<TaskComponentProps> };
} = {
  [OnboardingStep.BASELINE_ASSESSMENT]: {
    title: 'Questions',
    component: BaselineAssessmentOnboardingStep,
  },
  [OnboardingStep.TRAINING_BOOKS]: { title: 'Training book', component: TrainingBook },
  [OnboardingStep.AWAITING_SRT_RESULT]: { title: 'Reading test', component: AwaitingSRTResult },
  [OnboardingStep.UNKNOWN]: { title: 'Uh oh!', component: Unknown },
};
