import { useClientEvent } from 'components/client-events/client-event-provider';
import { atom, useAtom } from 'jotai';
import { useSchoolCompletedBookCount } from 'queries/school-metrics';
import { useSchool } from 'queries/schools';
import { userHasRole, UserRole, useUser } from 'queries/session';
import { useRef, useState } from 'react';
import { sleepMs } from 'utils/sleep';

import { downloadedAsImageEvent } from './events';
import styles from './praise-stats.module.css';
import { WidgetConfig } from './types';
import { downloadWidgetAsImage, useSchoolReadingHoursThisYear } from './utils';
import bookIcon from './widget/books_read.svg';
import srpIcon from './widget/clock.svg';
import { DownloadableWidget } from './widget/downloadable-widget';
import { Widget } from './widget/widget';

const animatedCountAtom = atom(0);

export const shouldDisplayStats = (
  isAdmin: boolean,
  completedBookCount: number,
  readingHoursThisYear: number,
) => {
  if (isAdmin) return true;

  return completedBookCount > 5 && readingHoursThisYear > 5;
};

export const PraiseStats = () => {
  const user = useUser();
  const { data: school } = useSchool();
  const { sendEvent } = useClientEvent();
  const isAdmin = userHasRole(user, UserRole.EQMRead);
  const [animatedCount, setAnimatedCount] = useAtom(animatedCountAtom);
  const { data: completedBookCount, isLoading: completedBookCountIsLoading } =
    useSchoolCompletedBookCount();
  const { readingHoursThisYear, isLoading: readingHoursThisYearIsLoading } =
    useSchoolReadingHoursThisYear();
  const [downloadConfig, setDownloadConfig] = useState<WidgetConfig>();
  const downloadableWidgetRef = useRef<HTMLDivElement>(null);

  if (
    completedBookCountIsLoading ||
    readingHoursThisYearIsLoading ||
    !school ||
    !shouldDisplayStats(isAdmin, completedBookCount?.completedBookCount || 0, readingHoursThisYear)
  ) {
    return null;
  }

  const onDownloadClick = async (config: WidgetConfig) => {
    setDownloadConfig(config);

    await sleepMs(200);
    if (downloadableWidgetRef.current) {
      await downloadWidgetAsImage(school.displayName, config, downloadableWidgetRef.current);
      sendEvent(downloadedAsImageEvent(config));
    }

    setDownloadConfig(undefined);
  };

  const widgetConfigs: WidgetConfig[] = [
    {
      id: 'Hours read this year',
      topLine: 'Your students have completed',
      bottomLine: 'of careful reading this year in Sparx Reader. Great effort!',
      count: readingHoursThisYear,
      units: 'hours',
      icon: srpIcon,
    },
    {
      id: 'Books read this year',
      topLine: 'Your students have read',
      bottomLine: 'this year in Sparx Reader. Well done!',
      count: completedBookCount?.completedBookCount || 0,
      units: 'books',
      icon: bookIcon,
    },
  ];

  return (
    <div className={styles.Container}>
      <h3 className={styles.Title}>Praise</h3>

      <div className={styles.Grid}>
        {widgetConfigs.map(widget => (
          <Widget
            key={widget.id}
            config={widget}
            animate={animatedCount < widgetConfigs.length}
            isGenerating={downloadConfig?.id === widget.id}
            onDownloadClick={() => onDownloadClick(widget)}
            onAnimateEnd={() => setAnimatedCount(count => count + 1)}
          />
        ))}

        {downloadConfig && (
          <div className={styles.HiddenWidget}>
            <DownloadableWidget config={downloadConfig} ref={downloadableWidgetRef} />
          </div>
        )}
      </div>
    </div>
  );
};
