import {
  Background,
  Font,
  FontScale,
  LetterSpacing,
  LineHeight,
  ReadingGuide,
  Settings,
} from '@sparx/api/apis/sparx/reading/users/v1/settings';
import { CSSProperties } from 'react';

export interface bookSettingsOptions {
  defaultFontSize?: number;
  smallWidth?: boolean;
}

export const bookSettingsToStyles = (
  settings: Settings | undefined,
  options?: bookSettingsOptions,
): CSSProperties => {
  const { defaultFontSize, smallWidth } = options || {};
  let baseFontSize = smallWidth ? 15 : 18;
  if (defaultFontSize) {
    baseFontSize = defaultFontSize;
  }
  let fontScale = settings?.fontScaleContinuous ? settings.fontScaleContinuous / 10 : undefined;
  if (fontScale === undefined) {
    fontScale = fontScaleMapping[settings?.fontScale || FontScale.UNSPECIFIED];
  }

  return {
    fontSize: baseFontSize * fontScale,
    lineHeight: `${1.4 * lineHeight[settings?.lineHeightScale || LineHeight.UNSPECIFIED]}em`,
    fontFamily: bookFont[settings?.font || Font.UNSPECIFIED],
    background: backgroundColor[settings?.background || Background.UNSPECIFIED],
    letterSpacing: `${
      letterSpacing[settings?.letterSpacingScale || LetterSpacing.LETTER_SPACING_UNSPECIFIED]
    }px`,
    '--book-background': backgroundColor[settings?.background || Background.UNSPECIFIED],
  } as CSSProperties;
};

export const bookSettingsToStylesOuter = (settings: Settings | undefined): CSSProperties => {
  return {
    background: backgroundColor[settings?.background || Background.UNSPECIFIED],
  } as CSSProperties;
};

const fontScaleMapping: Record<FontScale, number> = {
  [FontScale.UNSPECIFIED]: 1,
  [FontScale.NORMAL]: 1,
  [FontScale.LARGE]: 1.1,
  [FontScale.LARGER]: 1.2,
  [FontScale.LARGEST]: 1.3,
};

const lineHeight: Record<LineHeight, number> = {
  [LineHeight.UNSPECIFIED]: 1,
  [LineHeight.NORMAL]: 1,
  [LineHeight.LARGE]: 1.2,
  [LineHeight.LARGER]: 1.4,
  [LineHeight.LARGEST]: 1.6,
};

const letterSpacing: Record<LetterSpacing, number> = {
  [LetterSpacing.LETTER_SPACING_UNSPECIFIED]: 0,
  [LetterSpacing.LETTER_SPACING_0]: 0,
  [LetterSpacing.LETTER_SPACING_1]: 1,
  [LetterSpacing.LETTER_SPACING_2]: 2,
  [LetterSpacing.LETTER_SPACING_3]: 3,
};

export const bookFontOptions: { key: Font; font: string; style: string }[] = [
  { key: Font.UNSPECIFIED, font: 'Merriweather', style: "'Merriweather', serif" },
  { key: Font.VERDANA, font: 'Verdana', style: "'Verdana', sans-serif" },
  {
    key: Font.COMIC_SANS,
    font: 'Comic Sans',
    style: "'Comic Sans MS', 'Chalkboard SE', 'Verdana', sans-serif",
  },
  {
    key: Font.READABLE,
    font: 'Readable',
    style: "'opendyslexic', 'Comic Sans', 'Verdana', sans-serif",
  },
];

const bookFont = bookFontOptions.reduce(
  (p, v) => {
    p[v.key] = v.style;
    return p;
  },
  {} as Record<Font, string>,
);

export const backgroundColorOptions: {
  key: Background;
  name: string;
  color: string;
}[] = [
  { key: Background.UNSPECIFIED, name: 'White', color: 'white' },
  { key: Background.BLUE, name: 'Blue', color: '#CBE8EF' },
  { key: Background.CREAM, name: 'Cream', color: '#FEF9E6' },
  { key: Background.YELLOW, name: 'Yellow', color: '#ffeda8' },
  { key: Background.GREEN, name: 'Green', color: '#d0ffc7' },
  { key: Background.PURPLE, name: 'Purple', color: '#ecd0ff' },
  { key: Background.PINK, name: 'Pink', color: '#ffdaff' },
  { key: Background.ORANGE, name: 'Orange', color: '#de812f' },
];

const backgroundColor = backgroundColorOptions.reduce(
  (p, v) => {
    p[v.key] = v.color;
    return p;
  },
  {} as Record<Background, string>,
);

export const readingGuideOptions: {
  key: ReadingGuide;
  name: string;
}[] = [
  { key: ReadingGuide.UNSPECIFIED, name: 'None' },
  { key: ReadingGuide.LINE, name: 'Line' },
  { key: ReadingGuide.MASK, name: 'Guide' },
];
