import 'app/analytics';

import * as Tooltip from '@radix-ui/react-tooltip';
import { QueryClientProvider } from '@tanstack/react-query';
import { StatusNotification } from 'components/sections/status-notification';
import { queryClient } from 'queries/client';
import { CookiesProvider } from 'react-cookie';
import { RouterProvider } from 'react-router-dom';

import { DevTools } from './dev-tools/dev-tools';
import { routes } from './routes/routes';

const App = () => {
  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <Tooltip.Provider delayDuration={0}>
          <StatusNotification />
          <RouterProvider router={routes} />
        </Tooltip.Provider>

        <DevTools />
      </QueryClientProvider>
    </CookiesProvider>
  );
};

export default App;
