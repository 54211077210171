import { getReaderEnvironment, isDevEnv } from 'utils/settings';
import { getAPIURL } from 'api';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// This sets up our Sentry config. We do this in a separate JS module so that it
// can run before the main app code and give us the best chance of catching
// errors.
const tracingAPIURL = getAPIURL().replace(/https?:\/\//, '');
Sentry.init({
  dsn: 'https://ebd7bd116efa410394bad4e2655f6c7d@o1086305.ingest.sentry.io/6098216',
  environment: getReaderEnvironment(),
  release: import.meta.env.VITE_RELEASE_COMMIT,
  // Change 0 to a 1 in the lines below to send errors and traces to Sentry in your dev env.
  // NOTE: We also set the sample rate in readingserver, see reader/server/cmd/readingserver/main.go
  tracesSampleRate: isDevEnv() ? 0 : 0.001,
  sampleRate: isDevEnv() ? 0 : 0.1, // One in 10 errors will be sent to Sentry.
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: isDevEnv() ? 0 : 0.01, // One in 100 sessions will record replays when errors happen.
  tracingOrigins: ['localhost', /^\//, tracingAPIURL],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      beforeNavigate: context => {
        return {
          ...context,
          // You could use your UI's routing library to find the matching
          // route template here. We don't have one right now, so do some basic
          // parameter replacements.
          name: window.location.pathname
            .replace(/\/[a-f0-9]{32}/g, '/<hash>')
            .replace(/\/\d+/g, '/<digits>'),
        };
      },
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      networkDetailAllowUrls: [
        'localhost',
        /^\//,
        tracingAPIURL,
        /https?:\/\/.*\.sparxmaths\.uk/,
        /https?:\/\/.*\.sparxreader\.com/,
      ],
      networkRequestHeaders: ['Content-Type', 'X-Grpc-Web'],
      networkResponseHeaders: [
        'Content-Type',
        'Cf-Cache-Status',
        'Cf-Ray',
        'Grpc-Message',
        'Grpc-Status',
      ],
    }),
  ],
  // ignoreErrors and denyUrls are based off the recommended setting from Sentry:
  // https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',

    // Sparx Specific Ignores:
    'NS_ERROR_ABORT', // Firefox throws this error every now and then. We've ignored it in Sentry but it came back so putting it here.
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});
