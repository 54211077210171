import { Button } from 'components/buttons/button';
import { Tooltip } from 'components/tooltip/Tooltip';
import styles from 'views/student/library-view/components/link-to-book-choice.module.css';

interface LinkToBookChoiceProps {
  disabled?: boolean;
  handleLinkClick?: () => void;
  analyticsAction?: string;
}

export const LinkToBookChoice = ({
  disabled,
  handleLinkClick,
  analyticsAction,
}: LinkToBookChoiceProps) => {
  const content = (
    <div className={styles.LinkToBookChoice}>
      <Button
        onClick={handleLinkClick}
        analyticsEvent={{
          category: 'library',
          action: analyticsAction || 'click link to new book page',
        }}
        disabled={disabled}
        className={styles.ChooseNewBookLink}
      >
        <span className={styles.Plus}>+</span>
        <br />
        choose a new book
      </Button>
    </div>
  );
  if (disabled) {
    return (
      <Tooltip content="You need to swap or finish a book before you can start a new one">
        {content}
      </Tooltip>
    );
  }
  return content;
};
