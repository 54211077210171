// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schoolactions.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { SchoolCalendar } from '../calendar/v4/calendar';
import { Product } from '../../types/product';
import { FieldMask } from '../../../../google/protobuf/field_mask';
import { School } from './schools';
/**
 * @generated from protobuf message sparx.school.v2.UpdateSchoolSSOSettingsRequest
 */
export interface UpdateSchoolSSOSettingsRequest {
  /**
   * The school to update. The name is required to identify the school to update
   * and the only allowed fields to be set are annotations relating to SSO
   * settings. Other fields will be cleared before update
   *
   * @generated from protobuf field: sparx.school.v2.School school = 1;
   */
  school?: School;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.school.v2.UpdateSchoolParentEmailSettingsRequest
 */
export interface UpdateSchoolParentEmailSettingsRequest {
  /**
   * The school to update. The name is required to identify the school to update
   * and the only allowed fields to be set are annotations relating to parent
   * email settings. Other fields will be cleared before update
   *
   * @generated from protobuf field: sparx.school.v2.School school = 1;
   */
  school?: School;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.school.v2.UpdateSchoolPlannedHWStartDateRequest
 */
export interface UpdateSchoolPlannedHWStartDateRequest {
  /**
   * The school to update. The name is required to identify the school to update
   * and the only allowed fields to be set are annotations relating to planned
   * homework start date. Other fields will be cleared before update.
   *
   * @generated from protobuf field: sparx.school.v2.School school = 1;
   */
  school?: School;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.school.v2.CompleteRolloverRequest
 */
export interface CompleteRolloverRequest {
  /**
   * The name of the school to complete the rollover for. `schools/<id>`
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The product to complete rollover for.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
  /**
   * Whether to complete rollover for all products. If set then Product should
   * be unset.
   *
   * @generated from protobuf field: bool all_products = 3;
   */
  allProducts: boolean;
}
/**
 * Empty
 *
 * @generated from protobuf message sparx.school.v2.CompleteRolloverResponse
 */
export interface CompleteRolloverResponse {}
/**
 * @generated from protobuf message sparx.school.v2.UpdateSchoolCalendarRolloverStepAnnotationsRequest
 */
export interface UpdateSchoolCalendarRolloverStepAnnotationsRequest {
  /**
   * The school calendar to update. The name is required to identify the school
   * to update and the only allowed fields to be set are annotations relating to
   * rollover steps within the current or pending years rollover states.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.SchoolCalendar school_calendar = 1;
   */
  schoolCalendar?: SchoolCalendar;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolSSOSettingsRequest$Type extends MessageType<UpdateSchoolSSOSettingsRequest> {
  constructor() {
    super('sparx.school.v2.UpdateSchoolSSOSettingsRequest', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolSSOSettingsRequest
 */
export const UpdateSchoolSSOSettingsRequest =
  new UpdateSchoolSSOSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolParentEmailSettingsRequest$Type extends MessageType<UpdateSchoolParentEmailSettingsRequest> {
  constructor() {
    super('sparx.school.v2.UpdateSchoolParentEmailSettingsRequest', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolParentEmailSettingsRequest
 */
export const UpdateSchoolParentEmailSettingsRequest =
  new UpdateSchoolParentEmailSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolPlannedHWStartDateRequest$Type extends MessageType<UpdateSchoolPlannedHWStartDateRequest> {
  constructor() {
    super('sparx.school.v2.UpdateSchoolPlannedHWStartDateRequest', [
      { no: 1, name: 'school', kind: 'message', T: () => School },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolPlannedHWStartDateRequest
 */
export const UpdateSchoolPlannedHWStartDateRequest =
  new UpdateSchoolPlannedHWStartDateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteRolloverRequest$Type extends MessageType<CompleteRolloverRequest> {
  constructor() {
    super('sparx.school.v2.CompleteRolloverRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
      { no: 3, name: 'all_products', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.CompleteRolloverRequest
 */
export const CompleteRolloverRequest = new CompleteRolloverRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteRolloverResponse$Type extends MessageType<CompleteRolloverResponse> {
  constructor() {
    super('sparx.school.v2.CompleteRolloverResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.CompleteRolloverResponse
 */
export const CompleteRolloverResponse = new CompleteRolloverResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolCalendarRolloverStepAnnotationsRequest$Type extends MessageType<UpdateSchoolCalendarRolloverStepAnnotationsRequest> {
  constructor() {
    super(
      'sparx.school.v2.UpdateSchoolCalendarRolloverStepAnnotationsRequest',
      [
        {
          no: 1,
          name: 'school_calendar',
          kind: 'message',
          T: () => SchoolCalendar,
        },
        { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.v2.UpdateSchoolCalendarRolloverStepAnnotationsRequest
 */
export const UpdateSchoolCalendarRolloverStepAnnotationsRequest =
  new UpdateSchoolCalendarRolloverStepAnnotationsRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.v2.SchoolActionsService
 */
export const SchoolActionsService = new ServiceType(
  'sparx.school.v2.SchoolActionsService',
  [
    {
      name: 'UpdateSchoolSSOSettings',
      options: {
        'sparx.api.auth': {
          action: 'updatesso',
          resource: 'tp:school',
          domain: '{school.name}',
        },
      },
      I: UpdateSchoolSSOSettingsRequest,
      O: School,
    },
    {
      name: 'UpdateSchoolParentEmailSettings',
      options: {
        'sparx.api.auth': {
          action: 'updateparentemail',
          resource: 'tp:school',
          domain: '{school.name}',
        },
      },
      I: UpdateSchoolParentEmailSettingsRequest,
      O: School,
    },
    {
      name: 'UpdateSchoolPlannedHWStartDate',
      options: {
        'sparx.api.auth': {
          action: 'updateplannedhwstartdate',
          resource: 'tp:school',
          domain: '{school.name}',
        },
      },
      I: UpdateSchoolPlannedHWStartDateRequest,
      O: School,
    },
    {
      name: 'CompleteRollover',
      options: {
        'sparx.api.auth': {
          action: 'completerollover',
          resource: 'tp:school',
          domain: '{school_name}',
        },
      },
      I: CompleteRolloverRequest,
      O: CompleteRolloverResponse,
    },
    {
      name: 'UpdateSchoolCalendarRolloverStepAnnotations',
      options: {
        'sparx.api.auth': {
          action: 'updaterolloverstepannotations',
          resource: 'tp:school',
          domain: '{school_calendar.name|singleton}',
        },
      },
      I: UpdateSchoolCalendarRolloverStepAnnotationsRequest,
      O: SchoolCalendar,
    },
  ],
);
