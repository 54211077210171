import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { useAlert, useClearAlert } from 'components/alert/alert';
import { AlertCloseButton } from 'components/alert/alert-close-button';
import { Button } from 'components/buttons';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useTryAnotherBook } from 'queries/library-books';

import styles from './book-swap-button.module.css';

interface IBookSwapButton {
  studentBook: StudentBook;
}

/**
 * BookSwapButton is a button that allows the user to swap out their current book.
 * They are only allowed to swap if they have had "a good go" (see logic below) at their current book.
 * A confirmation alert appears when the button is pressed.
 */
export const BookSwapButton: React.FC<IBookSwapButton> = ({ studentBook }) => {
  const bookID = studentBook.bookId;
  const setAlert = useAlert();
  const clearAlert = useClearAlert();
  const tryAnotherBook = useTryAnotherBook();

  const hadAGoodGo = (studentBook.extra?.tasksAttemptedPassedFirstTime || 0) >= 3;
  const handleSwapBookConfirm = () => {
    tryAnotherBook(studentBook, false, 'STUDENT_SWAP_HAD_A_GOOD_GO');
    clearAlert();
  };
  const handleSwapBookCancel = () => clearAlert();
  const handleSwapBook = () => {
    if (!hadAGoodGo) {
      return;
    }

    setAlert(
      <>
        <AlertCloseButton
          analyticsEvent={{
            category: 'library',
            action: 'swap book cancel',
            labels: { bookID, canSwap: hadAGoodGo },
          }}
        />
        <h1 className={styles.SwapConfirmTitle}>Are you sure you&apos;d like to swap?</h1>
        <p className={styles.SwapConfirmText}>
          If you swap this book you won&apos;t be able to keep reading it.
        </p>
        <div className={styles.SwapButtons}>
          <Button
            variant="secondary"
            className={styles.SwapCancelButton}
            onClick={handleSwapBookCancel}
            analyticsEvent={{
              category: 'library',
              action: 'swap book cancel',
              labels: { bookID, canSwap: hadAGoodGo },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className={styles.SwapConfirmButton}
            onClick={handleSwapBookConfirm}
            analyticsEvent={{
              category: 'library',
              action: 'swap book confirm',
              labels: { bookID, canSwap: hadAGoodGo },
            }}
          >
            Swap this book
          </Button>
        </div>
      </>,
    );
  };
  return (
    <Tooltip
      content={hadAGoodGo ? '' : 'You need to read a bit more before you can swap this book.'}
    >
      <div>
        <Button
          variant="secondary"
          size="small"
          className={styles.SwapButton}
          onClick={handleSwapBook}
          rightIcon={<FontAwesomeIcon icon={faShuffle} fixedWidth={true} />}
          analyticsEvent={{
            category: 'library',
            action: 'swap book start',
            labels: { bookID, canSwap: hadAGoodGo },
          }}
          disabled={!hadAGoodGo}
        >
          Swap this book
        </Button>
      </div>
    </Tooltip>
  );
};
