import {
  ListSchoolStaffMembersResponse,
  SchoolStaffMember,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { schoolStaffClient } from 'api';
import { useUser } from 'queries/session';

export const useListStaff = <T = ListSchoolStaffMembersResponse>(
  schoolID: string,
  options?: UseQueryOptions<ListSchoolStaffMembersResponse, Error, T, string[]>,
) =>
  useQuery(
    ['staffv2.ListSchoolStaffMembers', schoolID],
    () =>
      schoolStaffClient.listSchoolStaffMembers({
        school: `schools/${schoolID}`,
      }).response,
    options,
  );

export const useGetStaffMember = <T = SchoolStaffMember>(
  staffID: string,
  schoolID: string,
  options?: UseQueryOptions<SchoolStaffMember, Error, T, string[]>,
) =>
  useQuery(
    ['staffv2.SchoolStaffMember', schoolID, staffID],
    () =>
      schoolStaffClient.getSchoolStaffMember({
        name: `staff/${staffID}`,
        school: `schools/${schoolID}`,
      }).response,
    options,
  );

export const useCurrentStaffMember = <T = SchoolStaffMember>(
  options?: UseQueryOptions<SchoolStaffMember, Error, T, string[]>,
) => {
  const user = useUser();
  return useGetStaffMember(user?.sparxUserId || '', user?.schoolId || '', {
    ...options,
    enabled: options?.enabled === false ? false : user !== undefined,
  });
};
