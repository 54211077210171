// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/staff/v2/staff.proto" (package "sparx.school.staff.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Product } from '../../../types/product';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.school.staff.v2.StaffMember
 */
export interface StaffMember {
  /**
   * The staff member's resource name.
   * `staff/{staff_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Optional. The staff member's title (e.g. Mx, Mrs, Dr)
   *
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * The staff member's given name.
   *
   * @generated from protobuf field: string given_name = 3;
   */
  givenName: string;
  /**
   * The staff member's family name.
   *
   * @generated from protobuf field: string family_name = 4;
   */
  familyName: string;
  /**
   * The staff member's display name, if set should be shown instead of
   * given/family name.
   *
   * @generated from protobuf field: string display_name = 11;
   */
  displayName: string;
  /**
   * Unique. The staff member's email address. It is invalid to update a staff
   * member's email address one already in use.
   *
   * @generated from protobuf field: string email_address = 5;
   */
  emailAddress: string;
  /**
   * The time the staff member record was first created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 6;
   */
  createTime?: Timestamp;
  /**
   * The time the staff member record was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 7;
   */
  updateTime?: Timestamp;
  /**
   * The time the staff member was deleted. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp delete_time = 8;
   */
  deleteTime?: Timestamp;
  /**
   * The schools (and school specific properties) this staff member is part of.
   * The keys are the school name. When performing updates, the key must be
   * provided in the field mask surrounded by quotes.
   *
   * @generated from protobuf field: map<string, sparx.school.staff.v2.StaffSchoolMembership> schools = 9;
   */
  schools: {
    [key: string]: StaffSchoolMembership;
  };
  /**
   * Annotations for services to store arbitrary data against the staff member.
   * Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 10;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.school.staff.v2.StaffSchoolMembership
 */
export interface StaffSchoolMembership {
  /**
   * School the staff member is a member of. Will match the key in the schools
   * map.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Roles this member of staff has in this school.
   *
   * @generated from protobuf field: repeated sparx.school.staff.v2.StaffRoleAssignment roles = 2;
   */
  roles: StaffRoleAssignment[];
  /**
   * This member of staff has access to Sparx systems.
   *
   * @generated from protobuf field: bool product_access = 5;
   */
  productAccess: boolean;
  /**
   * Annotations for services to store arbitrary data against the staff member
   * in this school. Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 4;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.school.staff.v2.StaffRoleAssignment
 */
export interface StaffRoleAssignment {
  /**
   * The role the staff member is assigned
   *
   * @generated from protobuf field: sparx.school.staff.v2.StaffRole role = 1;
   */
  role: StaffRole;
  /**
   * The product the user has the role in, where appropriate.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.GetStaffMemberRequest
 */
export interface GetStaffMemberRequest {
  /**
   * The name of the staff member to retrieve.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The email address of the staff member to retrieve. Should not be supplied
   * with name. Note that email address can change, and so retrieving by email
   * address should only be generally used in user creation.
   *
   * @generated from protobuf field: string email_address = 2;
   */
  emailAddress: string;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.ListStaffMembersRequest
 */
export interface ListStaffMembersRequest {
  /**
   * Requested page size. Server may return fewer results.
   *
   * @generated from protobuf field: int32 page_size = 1;
   */
  pageSize: number;
  /**
   * A token identifying a page of results to return. Should be the
   * value of [ListStaffMembersResponse.next_page_token] from a previous
   * request.
   *
   * @generated from protobuf field: string page_token = 2;
   */
  pageToken: string;
  /**
   * Include soft deleted staff members in the response
   *
   * @generated from protobuf field: bool show_deleted = 3;
   */
  showDeleted: boolean;
  /**
   * Optional. A school to list staff members for.
   *
   * @generated from protobuf field: string school = 4;
   */
  school: string;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.ListStaffMembersResponse
 */
export interface ListStaffMembersResponse {
  /**
   * The staff members
   *
   * @generated from protobuf field: repeated sparx.school.staff.v2.StaffMember staff_members = 1;
   */
  staffMembers: StaffMember[];
  /**
   * A token to retrieve the next page of results.
   *
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.CreateStaffMemberRequest
 */
export interface CreateStaffMemberRequest {
  /**
   * The staff member to create
   *
   * @generated from protobuf field: sparx.school.staff.v2.StaffMember staff_member = 1;
   */
  staffMember?: StaffMember;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.UpdateStaffMemberRequest
 */
export interface UpdateStaffMemberRequest {
  /**
   * The staff member to update
   *
   * @generated from protobuf field: sparx.school.staff.v2.StaffMember staff_member = 1;
   */
  staffMember?: StaffMember;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
  /**
   * If true, the server will not return an error if the staff member to be
   * updated is deleted.
   *
   * @generated from protobuf field: bool allow_deleted = 3;
   */
  allowDeleted: boolean;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.DeleteStaffMemberRequest
 */
export interface DeleteStaffMemberRequest {
  /**
   * The name of the staff member to delete.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Also remove personally identifying information from the record.
   *
   * @generated from protobuf field: bool pseudonymise = 2;
   */
  pseudonymise: boolean;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.UndeleteStaffMemberRequest
 */
export interface UndeleteStaffMemberRequest {
  /**
   * The name of the deleted staff member.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * If true, the schools for this staff member will be cleared. An error will
   * be returned if set and the staff member is not deleted.
   *
   * @generated from protobuf field: bool clear_schools = 2;
   */
  clearSchools: boolean;
}
/**
 * If the provided school name is the only associated membership
 * the staff member will also be soft deleted.
 *
 * @generated from protobuf message sparx.school.staff.v2.DeleteStaffSchoolMembershipRequest
 */
export interface DeleteStaffSchoolMembershipRequest {
  /**
   * The name of the staff member to delete.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The name of the school to remove from the staff members schoolMembership.
   *
   * @generated from protobuf field: string school_name = 2;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.school.staff.v2.MergeStaffMembersRequest
 */
export interface MergeStaffMembersRequest {
  /**
   * The name of the staff member to keep. Most of the details will be preserved
   * from this staff member.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The staff member to merge from. This staff member will be deleted
   * as part of the process.
   *
   * @generated from protobuf field: string other_staff_member = 2;
   */
  otherStaffMember: string;
}
/**
 * Roles that staff may be assigned in Sparx products
 *
 * @generated from protobuf enum sparx.school.staff.v2.StaffRole
 */
export enum StaffRole {
  /**
   * Default enum value for StaffRole.
   *
   * @generated from protobuf enum value: STAFF_ROLE_UNKNOWN = 0;
   */
  STAFF_ROLE_UNKNOWN = 0,
  /**
   * The user has some other role. May or may not be specified with a product.
   *
   * @generated from protobuf enum value: OTHER = 6;
   */
  OTHER = 6,
  /**
   * The user is the head of the appropriate department. Must be specified with
   * a product.
   *
   * @generated from protobuf enum value: HEAD_OF_DEPARTMENT = 2;
   */
  HEAD_OF_DEPARTMENT = 2,
  /**
   * The user is the deputy head of the appropriate department. Must be
   * specified with a product.
   *
   * @generated from protobuf enum value: DEPUTY_HEAD_OF_DEPARTMENT = 8;
   */
  DEPUTY_HEAD_OF_DEPARTMENT = 8,
  /**
   * The user is the coordinator of the appropriate department. Must be
   * specified with a product.
   *
   * @generated from protobuf enum value: DEPARTMENT_COORDINATOR = 13;
   */
  DEPARTMENT_COORDINATOR = 13,
  /**
   * The user is the key stage lead of the appropriate department. Must be
   * specified with a product.
   *
   * @generated from protobuf enum value: KEY_STAGE_LEAD = 16;
   */
  KEY_STAGE_LEAD = 16,
  /**
   * The user is the lead practitioner of the appropriate department. Must be
   * specified with a product.
   *
   * @generated from protobuf enum value: LEAD_PRACTITIONER = 17;
   */
  LEAD_PRACTITIONER = 17,
  /**
   * The user is the main contact for Sparx. Must be specified with a product.
   *
   * @generated from protobuf enum value: SPARX_LEADER = 3;
   */
  SPARX_LEADER = 3,
  /**
   * The user is a teacher. Must be specified with a product.
   *
   * @generated from protobuf enum value: TEACHER = 1;
   */
  TEACHER = 1,
  /**
   * The user is a librarian. Must be specified with a product.
   *
   * @generated from protobuf enum value: LIBRARIAN = 14;
   */
  LIBRARIAN = 14,
  /**
   * The user is a director at the trust this school is part of. Must be
   * specified with a product.
   *
   * @generated from protobuf enum value: TRUST_DIRECTOR = 5;
   */
  TRUST_DIRECTOR = 5,
  /**
   * The user is the member of the senior leadership team with line management
   * responsibility for the the specified product. Must be specified with a
   * product.
   *
   * @generated from protobuf enum value: SENIOR_LEADER = 9;
   */
  SENIOR_LEADER = 9,
  /**
   * The user a member of the senior leadership team. Should not be specified
   * with a product.
   *
   * @generated from protobuf enum value: SENIOR_LEADER_OTHER = 15;
   */
  SENIOR_LEADER_OTHER = 15,
  /**
   * Deprecated: The user is a special educational needs coordinator. May or may
   * not be specified with a product.
   *
   * @deprecated
   * @generated from protobuf enum value: SPECIAL_EDUCATIONAL_NEEDS_COORDINATOR = 10 [deprecated = true];
   */
  SPECIAL_EDUCATIONAL_NEEDS_COORDINATOR = 10,
  /**
   * The user is the head of a school. Should not be specified with a product
   *
   * @generated from protobuf enum value: HEAD_OF_SCHOOL = 4;
   */
  HEAD_OF_SCHOOL = 4,
  /**
   * This user is the network manager for this school. Should not be
   * specified with a product.
   *
   * @generated from protobuf enum value: NETWORK_MANAGER = 7;
   */
  NETWORK_MANAGER = 7,
  /**
   * The user is the finance officer for the school. Should not be
   * specified with a product.
   *
   * @generated from protobuf enum value: FINANCE_OFFICER = 11;
   */
  FINANCE_OFFICER = 11,
  /**
   * The user is the Business Manager for the school. Should not be
   * specified with a product.
   *
   * @generated from protobuf enum value: BUSINESS_MANAGER = 18;
   */
  BUSINESS_MANAGER = 18,
  /**
   * The user is the data protection officer for the school. Should not be
   * specified with a product.
   *
   * @generated from protobuf enum value: DATA_PROTECTION_OFFICER = 12;
   */
  DATA_PROTECTION_OFFICER = 12,
}
// @generated message type with reflection information, may provide speed optimized methods
class StaffMember$Type extends MessageType<StaffMember> {
  constructor() {
    super(
      'sparx.school.staff.v2.StaffMember',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        {
          no: 2,
          name: 'title',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { restricted: true } },
        },
        {
          no: 3,
          name: 'given_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { restricted: true } },
        },
        {
          no: 4,
          name: 'family_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { restricted: true } },
        },
        {
          no: 11,
          name: 'display_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { restricted: true } },
        },
        {
          no: 5,
          name: 'email_address',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { restricted: true } },
        },
        { no: 6, name: 'create_time', kind: 'message', T: () => Timestamp },
        { no: 7, name: 'update_time', kind: 'message', T: () => Timestamp },
        { no: 8, name: 'delete_time', kind: 'message', T: () => Timestamp },
        {
          no: 9,
          name: 'schools',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'message', T: () => StaffSchoolMembership },
        },
        {
          no: 10,
          name: 'annotations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'staff_v2',
          partitionColumn: 'update_time',
          additionalColumns: [
            {
              name: 'staff_id',
              mode: 'KEY',
              doc: 'The staff id as parsed from the name',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.StaffMember
 */
export const StaffMember = new StaffMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffSchoolMembership$Type extends MessageType<StaffSchoolMembership> {
  constructor() {
    super(
      'sparx.school.staff.v2.StaffSchoolMembership',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'roles',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StaffRoleAssignment,
        },
        {
          no: 5,
          name: 'product_access',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
        {
          no: 4,
          name: 'annotations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          additionalColumns: [
            { name: 'allowed_products', mode: 'REPEATED', doc: 'Removed.' },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.StaffSchoolMembership
 */
export const StaffSchoolMembership = new StaffSchoolMembership$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StaffRoleAssignment$Type extends MessageType<StaffRoleAssignment> {
  constructor() {
    super('sparx.school.staff.v2.StaffRoleAssignment', [
      {
        no: 1,
        name: 'role',
        kind: 'enum',
        T: () => ['sparx.school.staff.v2.StaffRole', StaffRole],
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.StaffRoleAssignment
 */
export const StaffRoleAssignment = new StaffRoleAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStaffMemberRequest$Type extends MessageType<GetStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.v2.GetStaffMemberRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.GetStaffMemberRequest
 */
export const GetStaffMemberRequest = new GetStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffMembersRequest$Type extends MessageType<ListStaffMembersRequest> {
  constructor() {
    super('sparx.school.staff.v2.ListStaffMembersRequest', [
      { no: 1, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'show_deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'school', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.ListStaffMembersRequest
 */
export const ListStaffMembersRequest = new ListStaffMembersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStaffMembersResponse$Type extends MessageType<ListStaffMembersResponse> {
  constructor() {
    super('sparx.school.staff.v2.ListStaffMembersResponse', [
      {
        no: 1,
        name: 'staff_members',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StaffMember,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.ListStaffMembersResponse
 */
export const ListStaffMembersResponse = new ListStaffMembersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStaffMemberRequest$Type extends MessageType<CreateStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.v2.CreateStaffMemberRequest', [
      { no: 1, name: 'staff_member', kind: 'message', T: () => StaffMember },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.CreateStaffMemberRequest
 */
export const CreateStaffMemberRequest = new CreateStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStaffMemberRequest$Type extends MessageType<UpdateStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.v2.UpdateStaffMemberRequest', [
      { no: 1, name: 'staff_member', kind: 'message', T: () => StaffMember },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      {
        no: 3,
        name: 'allow_deleted',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.UpdateStaffMemberRequest
 */
export const UpdateStaffMemberRequest = new UpdateStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteStaffMemberRequest$Type extends MessageType<DeleteStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.v2.DeleteStaffMemberRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'pseudonymise', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.DeleteStaffMemberRequest
 */
export const DeleteStaffMemberRequest = new DeleteStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UndeleteStaffMemberRequest$Type extends MessageType<UndeleteStaffMemberRequest> {
  constructor() {
    super('sparx.school.staff.v2.UndeleteStaffMemberRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'clear_schools',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.UndeleteStaffMemberRequest
 */
export const UndeleteStaffMemberRequest = new UndeleteStaffMemberRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteStaffSchoolMembershipRequest$Type extends MessageType<DeleteStaffSchoolMembershipRequest> {
  constructor() {
    super('sparx.school.staff.v2.DeleteStaffSchoolMembershipRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.DeleteStaffSchoolMembershipRequest
 */
export const DeleteStaffSchoolMembershipRequest =
  new DeleteStaffSchoolMembershipRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MergeStaffMembersRequest$Type extends MessageType<MergeStaffMembersRequest> {
  constructor() {
    super('sparx.school.staff.v2.MergeStaffMembersRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'other_staff_member',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.staff.v2.MergeStaffMembersRequest
 */
export const MergeStaffMembersRequest = new MergeStaffMembersRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.staff.v2.StaffService
 */
export const StaffService = new ServiceType(
  'sparx.school.staff.v2.StaffService',
  [
    {
      name: 'GetStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: GetStaffMemberRequest,
      O: StaffMember,
    },
    {
      name: 'ListStaffMembers',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: ListStaffMembersRequest,
      O: ListStaffMembersResponse,
    },
    {
      name: 'CreateStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: CreateStaffMemberRequest,
      O: StaffMember,
    },
    {
      name: 'UpdateStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: UpdateStaffMemberRequest,
      O: StaffMember,
    },
    {
      name: 'DeleteStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'delete',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: DeleteStaffMemberRequest,
      O: StaffMember,
    },
    {
      name: 'UndeleteStaffMember',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: UndeleteStaffMemberRequest,
      O: StaffMember,
    },
    {
      name: 'DeleteStaffSchoolMembership',
      options: {
        'sparx.api.auth': {
          action: 'delete',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: DeleteStaffSchoolMembershipRequest,
      O: StaffMember,
    },
    {
      name: 'MergeStaffMembers',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'staffstore:staffmember',
          domain: 'schools',
        },
      },
      I: MergeStaffMembersRequest,
      O: StaffMember,
    },
  ],
);
