import classNames from 'classnames';
import { Button } from 'components/buttons/button';
import rating_amber from 'components/buttons/images/rating_amber.svg';
import rating_green from 'components/buttons/images/rating_green.svg';
import rating_red from 'components/buttons/images/rating_red.svg';
import styles from 'components/buttons/rating-button.module.css';
import { FCWithChildren } from 'utils/props';

export type RatingColour = 'red' | 'amber' | 'green';

interface IRatingButtonProps {
  selected?: boolean;
  image: RatingColour;
  onClick: () => void;
}

const ratingImage = {
  red: rating_red,
  amber: rating_amber,
  green: rating_green,
};

export const RatingButton: FCWithChildren<IRatingButtonProps> = ({
  children,
  onClick,
  selected,
  image,
}) => {
  return (
    <Button
      onClick={onClick}
      className={classNames(styles.RatingButton, { [styles.RatingButtonSelected]: selected })}
      // We already get ratings events in a different table
      analyticsEvent={undefined}
    >
      <span className={styles.RatingButtonText}>{children}</span>
      <RatingImage image={image} />
    </Button>
  );
};

export const RatingImage: React.FC<{ image: RatingColour }> = ({ image }) => (
  <img src={ratingImage[image]} alt={`${image}`} />
);
