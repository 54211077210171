import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HomeworkPie } from 'views/student/library-view/components/homework-components';

import { useCurrentTrainingProgress } from '../hooks';
import styles from './training-progress-wheel.module.css';

interface ITrainingProgressWheelProps {
  background?: string;
  size?: 's' | 'm' | 'l';
  iconColor?: string;
}

export const TrainingProgressWheel = ({
  background,
  size = 'm',
  iconColor,
}: ITrainingProgressWheelProps) => {
  const { totalStepsCount, progress } = useCurrentTrainingProgress();

  if (totalStepsCount === 0) {
    return null;
  }
  return (
    <HomeworkPie
      progress={progress}
      labelText={
        <FontAwesomeIcon
          icon={faUser}
          className={classNames({
            [styles.IconSmall]: size === 's',
            [styles.IconMedium]: size === 'm',
          })}
          color={iconColor}
        />
      }
      className={classNames(styles.Pie, {
        [styles.PieSmall]: size === 's',
        [styles.PieMedium]: size === 'm',
      })}
      gradientVariant={'training'}
      background={background}
      rounded={false}
      lineWidth={size === 's' ? 30 : 20}
    />
  );
};
