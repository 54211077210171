import { faCheck, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputQuestion, InputQuestion_Type } from '@sparx/api/apis/sparx/reading/content/v1/books';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import classNames from 'classnames';
import { BookContent } from 'components/book/book-content';
import { Button } from 'components/buttons/button';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Timer } from 'components/progress/timer';
import styles from 'components/tasks/panel.module.css';
import { useServerOffset } from 'queries/session';
import { useTaskActionsLoading } from 'queries/tasks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useInterval } from 'utils/hooks';
import { millisToISOString } from 'utils/time';

// The answer value to send to the server when continuing after a timeout
const timeoutValue = '<timeout>';

// Event name for finishing a question
const finishEventName = 'finish_question';

// Event types for the finish event: timing out and submitting an answer
const timeoutEvent = 'timeout';
const submitEvent = 'submit';

interface IPaperbackAnswerInputProps {
  question: InputQuestion;
  submit: (answer: string, questionID?: string) => void;
  finishViewingResult: () => void;
  questionIndex: number;
  forcedValue?: string;
  inline?: boolean;
  bigOptions?: boolean;
  result?: { correct: boolean };
  questionTimeout?: Timestamp;
}

const getInitialQuestionTime = (question: InputQuestion): number | null => {
  // NB: if you update this function, also update it on the server (getQuestionTime)
  if (question.type === InputQuestion_Type.LAST_WORD) {
    return 120;
  } else if (question.type === InputQuestion_Type.QUESTION) {
    if (question.options.length === 0) {
      return 30;
    }

    // Multiple choice questions
    return 50;
  }
  return null; // No timer
};

const calcSecondsRemain = (timeout: Date): number => {
  const now = new Date();
  return (timeout.getTime() - now.getTime()) / 1000;
};

export const PaperbackAnswerInput: React.FC<IPaperbackAnswerInputProps> = ({
  question,
  submit,
  finishViewingResult,
  questionIndex,
  forcedValue,
  inline,
  bigOptions,
  result,
  questionTimeout,
}) => {
  const loading = useTaskActionsLoading();
  const [inputValue, setInputValue] = useState<string>('');
  const [initialTimeRemaining, setInitialTimeRemaining] = useState<number | undefined>(undefined);
  const useForcedValue = forcedValue !== undefined;
  const visibleValue = useForcedValue ? forcedValue || '' : inputValue;

  const { sendEvent } = useClientEvent();

  const inputField = useRef<HTMLInputElement>(null);
  const submitInputField = () => {
    submit(visibleValue, question.questionId);
    setInputValue('');
    focusInput();
  };
  const isInputField = question.options.length === 0;

  const submitValue = (value: string, questionID: string) => {
    // Send an analytic when they submit the question
    if (value !== timeoutValue) {
      onFinishQuestion(submitEvent);
    }
    submit(value, questionID);
  };

  const focusInput = () => {
    const current = inputField.current;
    if (current && isInputField) {
      current.focus();
    }
  };

  // Ensure that the input field is automatically focussed
  useEffect(focusInput, [inputField, isInputField]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && visibleValue !== '') {
      submitInputField();
    }
  };

  // Timer logic

  // How much time in seconds allowed to answer this question
  const totalTime = getInitialQuestionTime(question);

  // Most recent offset information
  // clientNowMillis - the client time in epoch time, at the point the server time was last sent
  // serverTimeMillis - the server time in epoch time, at the point the server time was last sent
  // offset - difference between server and client in millis
  const { data } = useServerOffset();
  const { serverTimeMillis, clientNowMillis } = data || {};
  let offset = 0;
  if (clientNowMillis && serverTimeMillis) {
    offset = clientNowMillis - serverTimeMillis;
  }

  // timeTimeout - the time the question should timeout in client time
  const timeTimeout = useMemo(() => {
    const timeout =
      questionTimeout && serverTimeMillis && clientNowMillis
        ? serverTimeToLocal(questionTimeout, offset)
        : new Date();
    setInitialTimeRemaining(calcSecondsRemain(timeout));
    return timeout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTimeout]);

  // If questionTimeout is undefined a timer wasn't used for this question so
  // do not send an event.
  const onFinishQuestion = (finishType: string) => {
    if (questionTimeout !== undefined) {
      sendEvent({
        category: 'tasks',
        action: finishEventName,
        labels: {
          questionId: question.questionId,
          mostRecentClientTime: clientNowMillis && millisToISOString(clientNowMillis),
          mostRecentServerTime: serverTimeMillis && millisToISOString(serverTimeMillis),
          mostRecentOffset: offset,
          clientTimeoutTime: timeTimeout.toISOString(),
          questionDuration: totalTime === null ? undefined : totalTime,
          initialTimeRemaining: initialTimeRemaining,
          finalTimeRemaining: timeRemaining,
          finishType,
        },
      });
    }
  };

  const [timeRemaining, setTimeRemaining] = useState<number>(calcSecondsRemain(timeTimeout));
  const canAnswer = !questionTimeout || timeRemaining >= 0 || result;
  const [timeoutSent, setTimeoutSent] = useState(false);

  useEffect(() => {
    setTimeoutSent(false);
  }, [questionTimeout]);

  // Send an event the first time the timer goes below 0
  useEffect(() => {
    if (timeRemaining < 0 && !timeoutSent) {
      setTimeoutSent(true);
      onFinishQuestion(timeoutEvent);
    }
    // eslint-disable-next-line
  }, [timeRemaining]);

  useInterval(() => {
    if (!result && !loading) {
      setTimeRemaining(calcSecondsRemain(timeTimeout));
    }
  }, 1000);

  // Reset the timer to 0 when a new question arrives
  useEffect(() => {
    if (timeTimeout) {
      setTimeRemaining(calcSecondsRemain(timeTimeout));
    }
  }, [setTimeRemaining, timeTimeout]);

  let resultBox = null;
  if (result) {
    resultBox = (
      <div className={`${styles.PaperbackQuestionResult} enter-down-0`}>
        {result?.correct ? (
          <div className={styles.PaperbackQuestionResultStatus}>
            <FontAwesomeIcon icon={faCheck} className={styles.AnswerIconCorrect} />
            <div className={styles.AnswerMessage}>Correct</div>
          </div>
        ) : (
          <div className={styles.PaperbackQuestionResultStatus}>
            <FontAwesomeIcon icon={faTimes} className={styles.AnswerIconWrong} />
            <div className={styles.AnswerMessage}>Incorrect</div>
          </div>
        )}
        <Button
          onClick={finishViewingResult}
          className={styles.PaperbackQuestionResultButton}
          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          loading={loading}
          analyticsEvent={undefined}
        >
          Continue
        </Button>
      </div>
    );
  }

  const questionElement = (
    <>
      {!canAnswer ? (
        <>
          <p>You have run out of time on this question, please continue.</p>
          <div className={styles.Buttons} style={{ marginTop: 10 }}>
            <Button
              onClick={() => submitValue(timeoutValue, question.questionId)}
              loading={loading}
              analyticsEvent={undefined}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <div className={styles.PaperbackQuestion}>
          <h2 className={styles.PanelTitleQuestion}>
            <span className={styles.PanelTitleQuestionNumber}>Q{questionIndex}.</span>
            {question.type === InputQuestion_Type.SELECT_SENTENCE ? (
              <>Select this sentence:</>
            ) : (
              <div
                className={classNames(
                  styles.PanelQuestionContent,
                  question.questionText.includes('<strong>') && styles.PanelQuestionContentNonBold,
                )}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: question.questionText,
                }}
              />
            )}
          </h2>
          {question.type === InputQuestion_Type.SELECT_SENTENCE && (
            <BookContent className={styles.PanelReadContent}>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: question.questionText }} />
            </BookContent>
          )}
          {!isInputField ? (
            <div className={classNames(styles.Buttons, bigOptions && styles.ButtonsBig)}>
              {question.options.map(opt => (
                <Button
                  key={opt}
                  onClick={() => submitValue(opt, question.questionId)}
                  variant={opt.toLowerCase().trim() === 'not in story' ? 'secondary' : 'primary'}
                  analyticsEvent={undefined}
                >
                  {opt}
                </Button>
              ))}
              {resultBox}
            </div>
          ) : (
            <>
              {!useForcedValue && question.type !== InputQuestion_Type.SELECT_SENTENCE && (
                <div className={styles.InputContainer}>
                  {question.type === InputQuestion_Type.LAST_WORD && (
                    <span className={styles.InputContainerLabel}>Last word:</span>
                  )}
                  <input
                    data-hj-whitelist
                    className="text-input"
                    type="text"
                    onChange={e => setInputValue(e.target.value)}
                    value={visibleValue}
                    onKeyDown={handleKeyDown}
                    ref={inputField}
                    disabled={useForcedValue}
                  />
                </div>
              )}
              <div className={styles.Buttons} style={{ marginTop: 10 }}>
                <Button
                  className={styles.SubmitButton}
                  onClick={submitInputField}
                  disabled={visibleValue === ''}
                  loading={loading}
                  analyticsEvent={undefined}
                >
                  Submit
                </Button>
              </div>
              {resultBox}
            </>
          )}
        </div>
      )}
    </>
  );

  return (
    <div className={classNames(styles.PanelWrapper, inline && styles.PanelWrapperInline)}>
      <div className={`${styles.Panel} ${styles.PanelPaperback} ${!inline && styles.PanelBottom}`}>
        {questionTimeout && totalTime !== null && timeRemaining !== null && (
          <Timer
            percent={timeRemaining / totalTime}
            className={styles.Timer}
            warning={timeRemaining < 10}
            danger={timeRemaining < 5}
          />
        )}
        <TransitionGroup component="div" className="PanelPaperbackQuestionContainer">
          <CSSTransition
            key={question.questionId}
            timeout={500}
            classNames={'PanelPaperbackQuestion'}
          >
            {questionElement}
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

const serverTimeToLocal = (date: Date | Timestamp, serverOffset: number) => {
  const d = date instanceof Date ? date : new Date(date.seconds * 1000);
  return new Date(d.getTime() + serverOffset);
};
