import { createContext, useContext } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { FCWithChildren } from 'utils/props';

type Context = {
  // Whether Sparx staff features are enabled
  sparxStaffFeaturesEnabled: boolean;
  // Toggle the Sparx staff features on or off (inverts the current state)
  toggleSparxStaffFeaturesEnabled: () => void;
};

const SparxStaffFeaturesContext = createContext<Context>({
  sparxStaffFeaturesEnabled: false,
  toggleSparxStaffFeaturesEnabled: () => undefined,
});

export const useSparxStaffFeatures = () => {
  const ctx = useContext(SparxStaffFeaturesContext);
  if (!ctx) {
    throw new Error('useSparxStaffFeatures can only be used inside a SparxStaffFeaturesProvider');
  }
  return ctx;
};

const STAFF_FEATURES_KEY = 'sparx-staff-features';

/**
 * SparxStaffFeaturesProvider provides the Sparx staff features context to all child elements (they
 * can use the useSparxStaffFeatures hook)
 * @param children
 * @constructor
 */
export const SparxStaffFeaturesProvider: FCWithChildren = ({ children }) => {
  const [sparxStaffFeaturesEnabled, setSparxStaffFeaturesEnabled] = useLocalStorageState(
    STAFF_FEATURES_KEY,
    {
      defaultValue: false,
    },
  );

  const toggleSparxStaffFeaturesEnabled = () =>
    setSparxStaffFeaturesEnabled(!sparxStaffFeaturesEnabled);
  return (
    <SparxStaffFeaturesContext.Provider
      value={{
        sparxStaffFeaturesEnabled,
        toggleSparxStaffFeaturesEnabled,
      }}
    >
      {children}
    </SparxStaffFeaturesContext.Provider>
  );
};
