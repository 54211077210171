// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/calendar/v4/calendar.proto" (package "sparx.school.calendar.v4", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Product } from '../../../types/product';
import { Date } from '../../../../../google/type/date';
import { DayOfWeek } from '../../../../../google/type/dayofweek';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.school.calendar.v4.SchoolCalendar
 */
export interface SchoolCalendar {
  /**
   * The SchoolCalendar's resource name
   * `{school_name}/calendar`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The time the calendar was first created. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;
  /**
   * The time the calendar was last updated. Read only.
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;
  /**
   * Read Only. Record of previous years.
   *
   * @generated from protobuf field: repeated sparx.school.calendar.v4.SchoolYear previous_years = 4;
   */
  previousYears: SchoolYear[];
  /**
   * Data relating to the current academic year.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.SchoolYear current_year = 5;
   */
  currentYear?: SchoolYear;
  /**
   * Data relating to the next academic year. The weeks of the pending year
   * should be treated as a preview.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.SchoolYear pending_year = 6;
   */
  pendingYear?: SchoolYear;
  /**
   * The day of the week considered to be the first day of the week.
   *
   * @generated from protobuf field: google.type.DayOfWeek week_start_day = 7;
   */
  weekStartDay: DayOfWeek;
  /**
   * Annotations for the whole calendar.
   *
   * @generated from protobuf field: map<string, string> annotations = 8;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.SchoolYear
 */
export interface SchoolYear {
  /**
   * The school year's resource name
   * `{calendar_name}/years/{year_id}`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * Required. The display name for this school year. Max length 31.
   *
   * @generated from protobuf field: string display_name = 2;
   */
  displayName: string;
  /**
   * Read Only. The academic year start date. Will match the end date of the
   * previous academic year.
   *
   * @generated from protobuf field: google.type.Date start_date = 3;
   */
  startDate?: Date;
  /**
   * Read Only. The academic year end date. Will match the start date of the
   * next academic year.
   *
   * @generated from protobuf field: google.type.Date end_date = 4;
   */
  endDate?: Date;
  /**
   * The weeks for this academic year.
   *
   * @generated from protobuf field: repeated sparx.school.calendar.v4.SchoolWeek weeks = 5;
   */
  weeks: SchoolWeek[];
  /**
   * State relating to the rollover at the start of this academic year.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.RolloverState rollover_state = 6;
   */
  rolloverState?: RolloverState;
  /**
   * Annotations for this year.
   *
   * @generated from protobuf field: map<string, string> annotations = 7;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.SchoolWeek
 */
export interface SchoolWeek {
  /**
   * The school week's resource name
   * `{year_name}/weeks/{week_id}`
   * Weeks for the pending year do not have names.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The index of the week in the weeks array.
   *
   * @generated from protobuf field: int32 index = 2;
   */
  index: number;
  /**
   * The named index for this week for display.
   * Defaults to index + 1
   *
   * @generated from protobuf field: int32 named_index = 3;
   */
  namedIndex: number;
  /**
   * Read Only. The week start date. Will match the end date of the previous
   * week.
   *
   * @generated from protobuf field: google.type.Date start_date = 4;
   */
  startDate?: Date;
  /**
   * Read Only. The week end date. Will match the start date of the next week.
   *
   * @generated from protobuf field: google.type.Date end_date = 5;
   */
  endDate?: Date;
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.RolloverState
 */
export interface RolloverState {
  /**
   * Time the school rolled over into the new academic year
   *
   * @generated from protobuf field: google.protobuf.Timestamp school_rollover_time = 1;
   */
  schoolRolloverTime?: Timestamp;
  /**
   * The time the school completely finished its rollover period.
   *
   * @generated from protobuf field: google.protobuf.Timestamp school_rollover_complete_time = 2;
   */
  schoolRolloverCompleteTime?: Timestamp;
  /**
   * The Sparx Maths specific rollover state. Will not be set if the school did
   * not have Sparx Maths at the point of rolling over.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.RolloverState.ProductRolloverState maths_rollover_state = 3;
   */
  mathsRolloverState?: RolloverState_ProductRolloverState;
  /**
   * The Sparx Reader specific rollover state. Will not be set if the school did
   * not have Sparx Reader at the point of rolling over.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.RolloverState.ProductRolloverState reader_rollover_state = 4;
   */
  readerRolloverState?: RolloverState_ProductRolloverState;
  /**
   * The Sparx Science specific rollover state. Will not be set if the school
   * did not have Sparx Science at the point of rolling over.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.RolloverState.ProductRolloverState science_rollover_state = 5;
   */
  scienceRolloverState?: RolloverState_ProductRolloverState;
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.RolloverState.ProductRolloverState
 */
export interface RolloverState_ProductRolloverState {
  /**
   * Read Only. Product this state relates to.
   *
   * @generated from protobuf field: sparx.types.Product product = 1;
   */
  product: Product;
  /**
   * The type of rollover this product went through.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.RolloverState.ProductRolloverState.RolloverType rollover_type = 4;
   */
  rolloverType: RolloverState_ProductRolloverState_RolloverType;
  /**
   * The time this product completed rollover.
   *
   * @generated from protobuf field: google.protobuf.Timestamp rollover_complete_time = 2;
   */
  rolloverCompleteTime?: Timestamp;
  /**
   * Annotations against this product for rollover.
   *
   * @generated from protobuf field: map<string, string> annotations = 3;
   */
  annotations: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf enum sparx.school.calendar.v4.RolloverState.ProductRolloverState.RolloverType
 */
export enum RolloverState_ProductRolloverState_RolloverType {
  /**
   * Product did not roll over or school did not have product at rollover.
   *
   * @generated from protobuf enum value: ROLLOVER_TYPE_NONE = 0;
   */
  ROLLOVER_TYPE_NONE = 0,
  /**
   * Product went through partial (2 step) rollover.
   *
   * @generated from protobuf enum value: PARTIAL = 1;
   */
  PARTIAL = 1,
  /**
   * Product went through full (instant) rollover.
   *
   * @generated from protobuf enum value: FULL = 2;
   */
  FULL = 2,
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.GetSchoolCalendarRequest
 */
export interface GetSchoolCalendarRequest {
  /**
   * The name of the school calendar
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
// message ListSchoolCalendarsRequest {
//   // Requested page size. Server may return fewer results.
//   int32 page_size = 1;
//   // A token identifying a page of results to return. Should be the
//   // value of [ListSchoolCalendarsResponse.next_page_token] from a previous
//   // request.
//   string page_token = 2;
// }

// message ListSchoolCalendarsResponse {
//   // The schools
//   repeated SchoolCalendar school_calendars = 1;
//   // A token to retrieve the next page of results.
//   string next_page_token = 2;
// }

/**
 * @generated from protobuf message sparx.school.calendar.v4.UpdateSchoolCalendarRequest
 */
export interface UpdateSchoolCalendarRequest {
  /**
   * The calendar to update. Most fields on a calendar cannot be updated using
   * this method.
   *
   * @generated from protobuf field: sparx.school.calendar.v4.SchoolCalendar school_calendar = 1;
   */
  schoolCalendar?: SchoolCalendar;
  /**
   * Required. The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.school.calendar.v4.SetCalendarWeekNamedIndexRequest
 */
export interface SetCalendarWeekNamedIndexRequest {
  /**
   * The name of the individual week to set the index of
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The index to set this week to. All other weeks will be relabelled around
   * this.
   *
   * @generated from protobuf field: int32 index = 2;
   */
  index: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class SchoolCalendar$Type extends MessageType<SchoolCalendar> {
  constructor() {
    super(
      'sparx.school.calendar.v4.SchoolCalendar',
      [
        {
          no: 1,
          name: 'name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
          options: { 'sparx.api.bigquery.v1.column': { key: true } },
        },
        { no: 2, name: 'create_time', kind: 'message', T: () => Timestamp },
        { no: 3, name: 'update_time', kind: 'message', T: () => Timestamp },
        {
          no: 4,
          name: 'previous_years',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SchoolYear,
        },
        { no: 5, name: 'current_year', kind: 'message', T: () => SchoolYear },
        { no: 6, name: 'pending_year', kind: 'message', T: () => SchoolYear },
        {
          no: 7,
          name: 'week_start_day',
          kind: 'enum',
          T: () => ['google.type.DayOfWeek', DayOfWeek],
        },
        {
          no: 8,
          name: 'annotations',
          kind: 'map',
          K: 9 /*ScalarType.STRING*/,
          V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'school_calendars',
          partitionColumn: 'update_time',
          additionalColumns: [
            {
              name: 'school',
              mode: 'KEY',
              doc: 'The school this calendar is for.',
            },
            {
              name: 'school_id',
              mode: 'KEY',
              doc: 'The school id as parsed from the name',
            },
          ],
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.SchoolCalendar
 */
export const SchoolCalendar = new SchoolCalendar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolYear$Type extends MessageType<SchoolYear> {
  constructor() {
    super('sparx.school.calendar.v4.SchoolYear', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'display_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'start_date', kind: 'message', T: () => Date },
      { no: 4, name: 'end_date', kind: 'message', T: () => Date },
      {
        no: 5,
        name: 'weeks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SchoolWeek,
      },
      {
        no: 6,
        name: 'rollover_state',
        kind: 'message',
        T: () => RolloverState,
      },
      {
        no: 7,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.SchoolYear
 */
export const SchoolYear = new SchoolYear$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SchoolWeek$Type extends MessageType<SchoolWeek> {
  constructor() {
    super('sparx.school.calendar.v4.SchoolWeek', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'named_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'start_date', kind: 'message', T: () => Date },
      { no: 5, name: 'end_date', kind: 'message', T: () => Date },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.SchoolWeek
 */
export const SchoolWeek = new SchoolWeek$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RolloverState$Type extends MessageType<RolloverState> {
  constructor() {
    super('sparx.school.calendar.v4.RolloverState', [
      {
        no: 1,
        name: 'school_rollover_time',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 2,
        name: 'school_rollover_complete_time',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 3,
        name: 'maths_rollover_state',
        kind: 'message',
        T: () => RolloverState_ProductRolloverState,
      },
      {
        no: 4,
        name: 'reader_rollover_state',
        kind: 'message',
        T: () => RolloverState_ProductRolloverState,
      },
      {
        no: 5,
        name: 'science_rollover_state',
        kind: 'message',
        T: () => RolloverState_ProductRolloverState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.RolloverState
 */
export const RolloverState = new RolloverState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RolloverState_ProductRolloverState$Type extends MessageType<RolloverState_ProductRolloverState> {
  constructor() {
    super('sparx.school.calendar.v4.RolloverState.ProductRolloverState', [
      {
        no: 1,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
      {
        no: 4,
        name: 'rollover_type',
        kind: 'enum',
        T: () => [
          'sparx.school.calendar.v4.RolloverState.ProductRolloverState.RolloverType',
          RolloverState_ProductRolloverState_RolloverType,
        ],
      },
      {
        no: 2,
        name: 'rollover_complete_time',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 3,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.RolloverState.ProductRolloverState
 */
export const RolloverState_ProductRolloverState =
  new RolloverState_ProductRolloverState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCalendarRequest$Type extends MessageType<GetSchoolCalendarRequest> {
  constructor() {
    super('sparx.school.calendar.v4.GetSchoolCalendarRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.GetSchoolCalendarRequest
 */
export const GetSchoolCalendarRequest = new GetSchoolCalendarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateSchoolCalendarRequest$Type extends MessageType<UpdateSchoolCalendarRequest> {
  constructor() {
    super('sparx.school.calendar.v4.UpdateSchoolCalendarRequest', [
      {
        no: 1,
        name: 'school_calendar',
        kind: 'message',
        T: () => SchoolCalendar,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.UpdateSchoolCalendarRequest
 */
export const UpdateSchoolCalendarRequest =
  new UpdateSchoolCalendarRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCalendarWeekNamedIndexRequest$Type extends MessageType<SetCalendarWeekNamedIndexRequest> {
  constructor() {
    super('sparx.school.calendar.v4.SetCalendarWeekNamedIndexRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.calendar.v4.SetCalendarWeekNamedIndexRequest
 */
export const SetCalendarWeekNamedIndexRequest =
  new SetCalendarWeekNamedIndexRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.calendar.v4.SchoolCalendarService
 */
export const SchoolCalendarService = new ServiceType(
  'sparx.school.calendar.v4.SchoolCalendarService',
  [
    {
      name: 'GetSchoolCalendar',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:school',
          domain: '{name|singleton}',
        },
      },
      I: GetSchoolCalendarRequest,
      O: SchoolCalendar,
    },
    {
      name: 'UpdateSchoolCalendar',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: '{school_calendar.name|singleton}',
        },
      },
      I: UpdateSchoolCalendarRequest,
      O: SchoolCalendar,
    },
    {
      name: 'SetCalendarWeekNamedIndex',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:school',
          domain: '{name|parent|parent|singleton}',
        },
      },
      I: SetCalendarWeekNamedIndexRequest,
      O: SchoolCalendar,
    },
  ],
);
