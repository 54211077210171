// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/teacherportal/studentapi/v1/studentapi.proto" (package "sparx.teacherportal.studentapi.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { Empty } from '../../../../../google/protobuf/empty';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { SingleStreamSelection } from '../../../personalisation/bespokepackager/v1/bespokepackager';
import { Student_HomeworkLength } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { InviteCode } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { Parent } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Gender } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
import { Student_Type } from '../../../../../teacherportal/schoolman/smmsg/schoolman';
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.Student
 */
export interface Student {
  /**
   * ID of the student.
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * External ID of the student.
   *
   * @generated from protobuf field: string external_id = 2;
   */
  externalId: string;
  /**
   * The student's sparx code. Only visible to sparx staff members.
   *
   * @generated from protobuf field: string code = 3;
   */
  code: string;
  /**
   * student's given name. Was previously called first name.
   *
   * @generated from protobuf field: string given_name = 4;
   */
  givenName: string;
  /**
   * student's family name. Was previously called last name.
   *
   * @generated from protobuf field: string family_name = 5;
   */
  familyName: string;
  /**
   * The type of the student.
   *
   * @generated from protobuf field: schoolman.Student.Type type = 6;
   */
  type: Student_Type;
  /**
   * The student's gender.
   *
   * @generated from protobuf field: schoolman.Gender gender = 7;
   */
  gender: Gender;
  /**
   * The student's date of birth.
   *
   * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 8;
   */
  dateOfBirth?: Timestamp;
  /**
   * List of the student groups this student is a member of.
   *
   * @generated from protobuf field: repeated string student_group_ids = 9;
   */
  studentGroupIds: string[];
  /**
   * List of parents of the student.
   *
   * @generated from protobuf field: repeated schoolman.Parent parents = 10;
   */
  parents: Parent[];
  /**
   * Data about the student's login to sparx.
   *
   * @generated from protobuf field: sparx.teacherportal.studentapi.v1.StudentLogin login = 11;
   */
  login?: StudentLogin;
  /**
   * Data about the student's settings for Sparx Maths homework.
   *
   * @generated from protobuf field: sparx.teacherportal.studentapi.v1.StudentMathsSettings maths_settings = 12;
   */
  mathsSettings?: StudentMathsSettings;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.StudentLogin
 */
export interface StudentLogin {
  /**
   * The student's username they use to login.
   *
   * @generated from protobuf field: string username = 1;
   */
  username: string;
  /**
   * The student's email address.
   *
   * @generated from protobuf field: string email_address = 2;
   */
  emailAddress: string;
  /**
   * The student's password. Only populated for primary students.
   *
   * @generated from protobuf field: string password = 3;
   */
  password: string;
  /**
   * The student's current invite code.
   *
   * @generated from protobuf field: schoolman.InviteCode invite_code = 4;
   */
  inviteCode?: InviteCode;
  /**
   * Time the student last set their password (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_set_at = 5;
   */
  passwordSetAt?: Timestamp;
  /**
   * Time the student last requested a password reset (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_reset_requested_at = 6;
   */
  passwordResetRequestedAt?: Timestamp;
  /**
   * Time a request to reset a password was last granted by a teacher (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp password_reset_granted_at = 7;
   */
  passwordResetGrantedAt?: Timestamp;
  /**
   * Time the student last set their password (null if they have not)
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login_at = 8;
   */
  lastLoginAt?: Timestamp;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.StudentMathsSettings
 */
export interface StudentMathsSettings {
  /**
   * The student's current homework length setting.
   *
   * @generated from protobuf field: schoolman.Student.HomeworkLength homework_length = 1;
   */
  homeworkLength: Student_HomeworkLength;
  /**
   * A learning path spec name, in the format `curriculums/{cu_id}/learningpathspecs/{lus_id}` or the string 'none'.
   *
   * @generated from protobuf field: string learning_path_spec_override = 2;
   */
  learningPathSpecOverride: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.StudentCurriculum
 */
export interface StudentCurriculum {
  /**
   * The ID of the student to fetch the stream selection for.
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The curriculum to return the stream selection for.
   *
   * @generated from protobuf field: string curriculum_name = 2;
   */
  curriculumName: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsRequest
 */
export interface GetSingleStreamSelectionsRequest {
  /**
   * The ID of the school to look up stream selections within.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The student/curriculum pairs to fetch the single stream selections for.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.studentapi.v1.StudentCurriculum student_curriculums = 2;
   */
  studentCurriculums: StudentCurriculum[];
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsResponse
 */
export interface GetSingleStreamSelectionsResponse {
  /**
   * The single stream selections, one per student requested.
   *
   * @generated from protobuf field: repeated sparx.personalisation.bespokepackager.SingleStreamSelection single_stream_selections = 1;
   */
  singleStreamSelections: SingleStreamSelection[];
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.ListStudentsRequest
 */
export interface ListStudentsRequest {
  /**
   * ID of the school to select the students for.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.ListStudentsResponse
 */
export interface ListStudentsResponse {
  /**
   * List of active students in the school.
   *
   * @generated from protobuf field: repeated sparx.teacherportal.studentapi.v1.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.SearchStudentsRequest
 */
export interface SearchStudentsRequest {
  /**
   * ID of the school to select the students for.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * A string to trigram match with the students' first and last names. If the
   * string is <= 2 characters long then no students will be returned. The query
   * is case insensitive.
   *
   * @generated from protobuf field: string query = 2;
   */
  query: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.SearchStudentsResponse
 */
export interface SearchStudentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.teacherportal.studentapi.v1.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.GrantPasswordResetRequest
 */
export interface GrantPasswordResetRequest {
  /**
   * ID of the school the students are in.
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * List of student ids to grant password resets for.
   *
   * @generated from protobuf field: repeated string student_ids = 2;
   */
  studentIds: string[];
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.GrantPasswordResetResponse
 */
export interface GrantPasswordResetResponse {
  /**
   * Map of student ids to student logins
   *
   * @generated from protobuf field: map<string, sparx.teacherportal.studentapi.v1.StudentLogin> student_logins = 1;
   */
  studentLogins: {
    [key: string]: StudentLogin;
  };
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.UnlinkStudentSSOIdentityRequest
 */
export interface UnlinkStudentSSOIdentityRequest {
  /**
   * The school ID for the student
   *
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * The ID of the student to be unlinked
   *
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * The identity provider to unlink
   *
   * @generated from protobuf field: string provider = 3;
   */
  provider: string;
}
/**
 * @generated from protobuf message sparx.teacherportal.studentapi.v1.BlockStudentRequest
 */
export interface BlockStudentRequest {
  /**
   * The ID of the student to be blocked
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The school ID for the student
   *
   * @generated from protobuf field: string school_id = 2;
   */
  schoolId: string;
  /**
   * Boolean to indicate if the block should be active or not.
   * Passing in false will remove the block currently on the student
   *
   * @generated from protobuf field: bool blocked = 3;
   */
  blocked: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class Student$Type extends MessageType<Student> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.Student', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'external_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'code', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'type',
        kind: 'enum',
        T: () => ['schoolman.Student.Type', Student_Type],
      },
      {
        no: 7,
        name: 'gender',
        kind: 'enum',
        T: () => ['schoolman.Gender', Gender],
      },
      { no: 8, name: 'date_of_birth', kind: 'message', T: () => Timestamp },
      {
        no: 9,
        name: 'student_group_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'parents',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Parent,
      },
      { no: 11, name: 'login', kind: 'message', T: () => StudentLogin },
      {
        no: 12,
        name: 'maths_settings',
        kind: 'message',
        T: () => StudentMathsSettings,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.Student
 */
export const Student = new Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentLogin$Type extends MessageType<StudentLogin> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.StudentLogin', [
      { no: 1, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'password', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'invite_code', kind: 'message', T: () => InviteCode },
      { no: 5, name: 'password_set_at', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'password_reset_requested_at',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 7,
        name: 'password_reset_granted_at',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 8, name: 'last_login_at', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.StudentLogin
 */
export const StudentLogin = new StudentLogin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentMathsSettings$Type extends MessageType<StudentMathsSettings> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.StudentMathsSettings', [
      {
        no: 1,
        name: 'homework_length',
        kind: 'enum',
        T: () => ['schoolman.Student.HomeworkLength', Student_HomeworkLength],
      },
      {
        no: 2,
        name: 'learning_path_spec_override',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.StudentMathsSettings
 */
export const StudentMathsSettings = new StudentMathsSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentCurriculum$Type extends MessageType<StudentCurriculum> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.StudentCurriculum', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'curriculum_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.StudentCurriculum
 */
export const StudentCurriculum = new StudentCurriculum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSingleStreamSelectionsRequest$Type extends MessageType<GetSingleStreamSelectionsRequest> {
  constructor() {
    super(
      'sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsRequest',
      [
        {
          no: 1,
          name: 'school_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'student_curriculums',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => StudentCurriculum,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsRequest
 */
export const GetSingleStreamSelectionsRequest =
  new GetSingleStreamSelectionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSingleStreamSelectionsResponse$Type extends MessageType<GetSingleStreamSelectionsResponse> {
  constructor() {
    super(
      'sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsResponse',
      [
        {
          no: 1,
          name: 'single_stream_selections',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SingleStreamSelection,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.GetSingleStreamSelectionsResponse
 */
export const GetSingleStreamSelectionsResponse =
  new GetSingleStreamSelectionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsRequest$Type extends MessageType<ListStudentsRequest> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.ListStudentsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.ListStudentsRequest
 */
export const ListStudentsRequest = new ListStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListStudentsResponse$Type extends MessageType<ListStudentsResponse> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.ListStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.ListStudentsResponse
 */
export const ListStudentsResponse = new ListStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsRequest$Type extends MessageType<SearchStudentsRequest> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.SearchStudentsRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'query', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.SearchStudentsRequest
 */
export const SearchStudentsRequest = new SearchStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchStudentsResponse$Type extends MessageType<SearchStudentsResponse> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.SearchStudentsResponse', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.SearchStudentsResponse
 */
export const SearchStudentsResponse = new SearchStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantPasswordResetRequest$Type extends MessageType<GrantPasswordResetRequest> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.GrantPasswordResetRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'student_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.GrantPasswordResetRequest
 */
export const GrantPasswordResetRequest = new GrantPasswordResetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GrantPasswordResetResponse$Type extends MessageType<GrantPasswordResetResponse> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.GrantPasswordResetResponse', [
      {
        no: 1,
        name: 'student_logins',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => StudentLogin },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.GrantPasswordResetResponse
 */
export const GrantPasswordResetResponse = new GrantPasswordResetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnlinkStudentSSOIdentityRequest$Type extends MessageType<UnlinkStudentSSOIdentityRequest> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.UnlinkStudentSSOIdentityRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'provider', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.UnlinkStudentSSOIdentityRequest
 */
export const UnlinkStudentSSOIdentityRequest =
  new UnlinkStudentSSOIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlockStudentRequest$Type extends MessageType<BlockStudentRequest> {
  constructor() {
    super('sparx.teacherportal.studentapi.v1.BlockStudentRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'blocked', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.teacherportal.studentapi.v1.BlockStudentRequest
 */
export const BlockStudentRequest = new BlockStudentRequest$Type();
/**
 * @generated ServiceType for protobuf service sparx.teacherportal.studentapi.v1.StudentAPI
 */
export const StudentAPI = new ServiceType(
  'sparx.teacherportal.studentapi.v1.StudentAPI',
  [
    {
      name: 'GetSingleStreamSelections',
      options: {
        'google.api.http': { get: '/v1/singlestreamselections' },
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: GetSingleStreamSelectionsRequest,
      O: GetSingleStreamSelectionsResponse,
    },
    {
      name: 'ListStudents',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: ListStudentsRequest,
      O: ListStudentsResponse,
    },
    {
      name: 'SearchStudents',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: SearchStudentsRequest,
      O: SearchStudentsResponse,
    },
    {
      name: 'GrantPasswordReset',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: GrantPasswordResetRequest,
      O: GrantPasswordResetResponse,
    },
    {
      name: 'UnlinkStudentSSOIdentity',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:student',
          domain: 'schools/{school_id}',
        },
      },
      I: UnlinkStudentSSOIdentityRequest,
      O: Empty,
    },
    {
      name: 'BlockStudent',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'tp:admin:blockstudent',
          domain: 'tpcommon',
        },
      },
      I: BlockStudentRequest,
      O: Empty,
    },
  ],
);
