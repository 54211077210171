import { Button } from 'components/buttons/button';
import styles from 'components/errors/error-message.module.css';
import { useNavigate, useRouteError } from 'react-router-dom';
import { FCWithOptionalChildren } from 'utils/props';
import { useNavigateHomeForUser } from 'views/views';

import { attemptReload } from './attempt-reload';

interface IErrorMessageProps {
  message: string;
  title?: React.ReactNode;
  backButtonText?: string;
  backButtonLink?: string;
  backButtonOnClick?: () => void;
}

/**
 * ErrorMessage displays an error message. Use it when you are showing an error within a page.
 */
export const ErrorMessage: FCWithOptionalChildren<IErrorMessageProps> = ({
  message,
  title,
  children,
  backButtonLink,
  backButtonText,
  backButtonOnClick,
}) => {
  const error = useRouteError();
  const navigate = useNavigate();

  if (
    error instanceof Error &&
    (error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed.'))
  ) {
    attemptReload();
  }

  const onClick = () => {
    if (backButtonLink) {
      navigate(backButtonLink);
    } else if (backButtonOnClick) {
      backButtonOnClick();
    }
  };

  return (
    <div className={styles.ErrorContainer}>
      <div className={styles.ErrorBox}>
        <h1>{title || 'Oops!'}</h1>
        <h2>{message}</h2>
        <div>{children}</div>
        {(backButtonLink || backButtonOnClick) && backButtonText && (
          <Button style={{ marginTop: 30 }} onClick={onClick} analyticsEvent={undefined}>
            {backButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * NotFoundErrorMessage wraps ErrorMessage with a message for handling 404s.
 */
export const NotFoundErrorMessage = () => {
  const navigateHome = useNavigateHomeForUser();

  return (
    <ErrorMessage
      message="Page not found"
      backButtonText="Return to home"
      backButtonOnClick={() => navigateHome(true)}
    >
      <p>We&apos;re sorry, this page cannot be found</p>
    </ErrorMessage>
  );
};

/**
 * ErrorPage displays an error as a full page. It is used when the whole app breaks.
 * For showing an error within a page, use ErrorMessage instead.
 */
export const ErrorPage: FCWithOptionalChildren<{ message: string }> = ({ message, children }) => {
  return (
    <div className={styles.ErrorPage}>
      <div className={styles.ErrorContainer}>
        <div className={styles.ErrorBox}>
          <h1>Oops!</h1>
          <h2>{message}</h2>
          {children && <div>{children}</div>}
        </div>
      </div>
    </div>
  );
};
