// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/calendar/v4/calendar.proto" (package "sparx.school.calendar.v4", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolCalendarService } from './calendar';
import type { SetCalendarWeekNamedIndexRequest } from './calendar';
import type { UpdateSchoolCalendarRequest } from './calendar';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SchoolCalendar } from './calendar';
import type { GetSchoolCalendarRequest } from './calendar';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.school.calendar.v4.SchoolCalendarService
 */
export interface ISchoolCalendarServiceClient {
  /**
   * @generated from protobuf rpc: GetSchoolCalendar(sparx.school.calendar.v4.GetSchoolCalendarRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  getSchoolCalendar(
    input: GetSchoolCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolCalendarRequest, SchoolCalendar>;
  // rpc ListSchoolCalendars(ListSchoolCalendarsRequest)
  //     returns (ListSchoolCalendarsResponse) {
  //   option (sparx.api.auth) = {
  //     action : "read"
  //     resource : "tp:school"
  //     domain : "schools"
  //   };
  // }

  /**
   * @generated from protobuf rpc: UpdateSchoolCalendar(sparx.school.calendar.v4.UpdateSchoolCalendarRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  updateSchoolCalendar(
    input: UpdateSchoolCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolCalendarRequest, SchoolCalendar>;
  /**
   * Relabels the weeks in a calendar year based on the index and week provided.
   * Only the current year may be relabelled this way.
   *
   * @generated from protobuf rpc: SetCalendarWeekNamedIndex(sparx.school.calendar.v4.SetCalendarWeekNamedIndexRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  setCalendarWeekNamedIndex(
    input: SetCalendarWeekNamedIndexRequest,
    options?: RpcOptions,
  ): UnaryCall<SetCalendarWeekNamedIndexRequest, SchoolCalendar>;
}
/**
 * @generated from protobuf service sparx.school.calendar.v4.SchoolCalendarService
 */
export class SchoolCalendarServiceClient
  implements ISchoolCalendarServiceClient, ServiceInfo
{
  typeName = SchoolCalendarService.typeName;
  methods = SchoolCalendarService.methods;
  options = SchoolCalendarService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSchoolCalendar(sparx.school.calendar.v4.GetSchoolCalendarRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  getSchoolCalendar(
    input: GetSchoolCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolCalendarRequest, SchoolCalendar> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolCalendarRequest, SchoolCalendar>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  // rpc ListSchoolCalendars(ListSchoolCalendarsRequest)
  //     returns (ListSchoolCalendarsResponse) {
  //   option (sparx.api.auth) = {
  //     action : "read"
  //     resource : "tp:school"
  //     domain : "schools"
  //   };
  // }

  /**
   * @generated from protobuf rpc: UpdateSchoolCalendar(sparx.school.calendar.v4.UpdateSchoolCalendarRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  updateSchoolCalendar(
    input: UpdateSchoolCalendarRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolCalendarRequest, SchoolCalendar> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolCalendarRequest, SchoolCalendar>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Relabels the weeks in a calendar year based on the index and week provided.
   * Only the current year may be relabelled this way.
   *
   * @generated from protobuf rpc: SetCalendarWeekNamedIndex(sparx.school.calendar.v4.SetCalendarWeekNamedIndexRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  setCalendarWeekNamedIndex(
    input: SetCalendarWeekNamedIndexRequest,
    options?: RpcOptions,
  ): UnaryCall<SetCalendarWeekNamedIndexRequest, SchoolCalendar> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SetCalendarWeekNamedIndexRequest, SchoolCalendar>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
