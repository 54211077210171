// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/statistics.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.users.v1.Statistics
 */
export interface Statistics {
  /**
   * @generated from protobuf field: int32 srp = 2;
   */
  srp: number;
  /**
   * @generated from protobuf field: int32 words_read = 3;
   */
  wordsRead: number;
  /**
   * @generated from protobuf field: float level = 5;
   */
  level: number;
  /**
   * @generated from protobuf field: float level_override = 6;
   */
  levelOverride: number;
  /**
   * @generated from protobuf field: bool gold_reader_disabled = 13;
   */
  goldReaderDisabled: boolean;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.GoldReaderState gold_reader_state = 14;
   */
  goldReaderState?: GoldReaderState;
  /**
   * Students reading age in months.
   *
   * @generated from protobuf field: int32 reading_age_months = 9;
   */
  readingAgeMonths: number;
  /**
   * Students reading speed in wpm.
   *
   * @generated from protobuf field: int32 reading_speed_wpm = 10;
   */
  readingSpeedWpm: number;
  /**
   * Students reading accuracy as a fraction between [0,1]
   *
   * @generated from protobuf field: float reading_accuracy = 11;
   */
  readingAccuracy: number;
  /**
   * Students detailed level to show to the teacher.
   *
   * @generated from protobuf field: float level_detailed = 12;
   */
  levelDetailed: number;
  /**
   * A decimal level derived from the SRT reading age.bool
   *
   * @generated from protobuf field: float level_srt = 15;
   */
  levelSrt: number;
  /**
   * Current vocab is no longer used.
   *
   * @deprecated
   * @generated from protobuf field: int32 current_vocab = 4 [deprecated = true];
   */
  currentVocab: number;
  /**
   * Gold override is deprecated. Use bool gold_reader_disabled instead.
   *
   * @deprecated
   * @generated from protobuf field: sparx.reading.users.v1.GoldStatus gold_override = 7 [deprecated = true];
   */
  goldOverride: GoldStatus;
  /**
   * sparx_gold is deprecated. Use gold_reader_state instead.
   *
   * @deprecated
   * @generated from protobuf field: bool sparx_gold = 8 [deprecated = true];
   */
  sparxGold: boolean;
}
/**
 * GoldReaderState describes a user's gold reader state. It always describes the
 * pass the user is currently working towards. In the case where they don't have
 * gold reader, the state describes the first pass prgress. In the case they are
 *  already gold reader - is_gold_reader is true - this describes their progress
 * against their next pass.
 *
 * @generated from protobuf message sparx.reading.users.v1.GoldReaderState
 */
export interface GoldReaderState {
  /**
   * Number of stars the student currently has. This should always
   * be positive and less or equal to the maximum number of stars (currently 5).
   *
   * @generated from protobuf field: int32 stars = 1;
   */
  stars: number;
  /**
   * Current SRP towards their next star.
   *
   * @generated from protobuf field: int32 srp = 2;
   */
  srp: number;
  /**
   * Function of the current SRP towards their next star and the srp required
   * per star. Will be updated alongside the SRP count.
   *
   * @generated from protobuf field: float progress = 3;
   */
  progress: number;
  /**
   * Flag if the student has been notified about their new gold reader status.
   * When this moved from false -> true it means that the student was just
   * notified that they had been awarded gold reader.
   *
   * @generated from protobuf field: bool notified = 4;
   */
  notified: boolean;
  /**
   * If the user has a gold reader pass, the time it expires.
   *
   * @generated from protobuf field: google.protobuf.Timestamp pass_expiry = 7;
   */
  passExpiry?: Timestamp;
  /**
   * @deprecated
   * @generated from protobuf field: google.protobuf.Timestamp start_timestamp = 5 [deprecated = true];
   */
  startTimestamp?: Timestamp;
  /**
   * @deprecated
   * @generated from protobuf field: sparx.reading.users.v1.GoldReaderStrikes strikes = 6 [deprecated = true];
   */
  strikes?: GoldReaderStrikes;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.users.v1.GoldReaderStrikes
 */
export interface GoldReaderStrikes {
  /**
   * List of homework ids that have been checked to see if a strike
   * should be issued on it.
   *
   * Homeworks will only be checked if they ended after the
   * `start_timestamp` on the GoldReaderState.
   *
   * @generated from protobuf field: repeated string checked_homeworks = 1;
   */
  checkedHomeworks: string[];
  /**
   * List of striked homework ids where the student has not done
   * enough additional work to keep gold reader.
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.Strike striked_homeworks = 2;
   */
  strikedHomeworks: Strike[];
  /**
   * List of striked task ids where the student has logged a reading
   * session of 30 minutes or over.
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.Strike striked_tasks = 3;
   */
  strikedTasks: Strike[];
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.users.v1.Strike
 */
export interface Strike {
  /**
   * Identifier of the strike. For example this could be the homework_id
   * or the task_id depending on the type of strike.
   *
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * Timestamp of which the strike should expire.
   *
   * @generated from protobuf field: google.protobuf.Timestamp expiry = 2;
   */
  expiry?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.StudentHistoryState
 */
export interface StudentHistoryState {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.HistoryStateTask tasks = 1;
   */
  tasks: HistoryStateTask[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.HistoryStateTask
 */
export interface HistoryStateTask {
  /**
   * @generated from protobuf field: string task_id = 1;
   */
  taskId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp attempt_timestamp = 2;
   */
  attemptTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: int32 truncated_reading_time_seconds = 3;
   */
  truncatedReadingTimeSeconds: number;
  /**
   * @generated from protobuf field: int32 truncated_question_time_seconds = 4;
   */
  truncatedQuestionTimeSeconds: number;
  /**
   * @generated from protobuf field: int32 base_srp = 5;
   */
  baseSrp: number;
  /**
   * @generated from protobuf field: int32 attempts = 6;
   */
  attempts: number;
  /**
   * @generated from protobuf field: bool has_failed = 7;
   */
  hasFailed: boolean;
}
/**
 * @deprecated
 * @generated from protobuf enum sparx.reading.users.v1.GoldStatus
 */
export enum GoldStatus {
  /**
   * @generated from protobuf enum value: GOLD_STATUS_UNDEFINED = 0;
   */
  UNDEFINED = 0,
  /**
   * @generated from protobuf enum value: GOLD_STATUS_DISABLED = 1;
   */
  DISABLED = 1,
  /**
   * @generated from protobuf enum value: GOLD_STATUS_ENABLED = 2;
   */
  ENABLED = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class Statistics$Type extends MessageType<Statistics> {
  constructor() {
    super('sparx.reading.users.v1.Statistics', [
      { no: 2, name: 'srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'level', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 6,
        name: 'level_override',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 13,
        name: 'gold_reader_disabled',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 14,
        name: 'gold_reader_state',
        kind: 'message',
        T: () => GoldReaderState,
      },
      {
        no: 9,
        name: 'reading_age_months',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 10,
        name: 'reading_speed_wpm',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'reading_accuracy',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 12,
        name: 'level_detailed',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      { no: 15, name: 'level_srt', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 4,
        name: 'current_vocab',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'gold_override',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.GoldStatus',
          GoldStatus,
          'GOLD_STATUS_',
        ],
      },
      { no: 8, name: 'sparx_gold', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.Statistics
 */
export const Statistics = new Statistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoldReaderState$Type extends MessageType<GoldReaderState> {
  constructor() {
    super('sparx.reading.users.v1.GoldReaderState', [
      { no: 1, name: 'stars', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'progress', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 4, name: 'notified', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 7, name: 'pass_expiry', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'start_timestamp', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'strikes', kind: 'message', T: () => GoldReaderStrikes },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GoldReaderState
 */
export const GoldReaderState = new GoldReaderState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoldReaderStrikes$Type extends MessageType<GoldReaderStrikes> {
  constructor() {
    super('sparx.reading.users.v1.GoldReaderStrikes', [
      {
        no: 1,
        name: 'checked_homeworks',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'striked_homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Strike,
      },
      {
        no: 3,
        name: 'striked_tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Strike,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.users.v1.GoldReaderStrikes
 */
export const GoldReaderStrikes = new GoldReaderStrikes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Strike$Type extends MessageType<Strike> {
  constructor() {
    super('sparx.reading.users.v1.Strike', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'expiry', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.users.v1.Strike
 */
export const Strike = new Strike$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHistoryState$Type extends MessageType<StudentHistoryState> {
  constructor() {
    super('sparx.reading.users.v1.StudentHistoryState', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HistoryStateTask,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.StudentHistoryState
 */
export const StudentHistoryState = new StudentHistoryState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryStateTask$Type extends MessageType<HistoryStateTask> {
  constructor() {
    super('sparx.reading.users.v1.HistoryStateTask', [
      { no: 1, name: 'task_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'attempt_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'truncated_reading_time_seconds',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'truncated_question_time_seconds',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'base_srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 6, name: 'attempts', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 7, name: 'has_failed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.HistoryStateTask
 */
export const HistoryStateTask = new HistoryStateTask$Type();
