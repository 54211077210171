import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, TooltipProps } from 'components/tooltip/Tooltip';

import styles from './Tooltip.module.css';

export const InfoTooltip = (props: Omit<TooltipProps, 'children'>) => (
  <Tooltip {...props}>
    <FontAwesomeIcon icon={faInfoCircle} className={styles.InfoTooltip} />
  </Tooltip>
);
