import { faChevronDown, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/table/table.module.css';
import { Tooltip } from 'components/tooltip/Tooltip';

export interface ITableHeaderSortProps<T> {
  setSort?: (sort: T, flip: boolean) => void;
  currentSort?: T;
  flipped?: boolean;
}

interface ITableHeaderProps<T> extends ITableHeaderSortProps<T> {
  sticky?: boolean;
  sort?: T;
  children?: React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
  align?: 'left' | 'right' | 'center';
  padLeft?: boolean;
  padRight?: boolean;
}

export const TableHeaderCell = <T,>({
  sort,
  sticky,
  flipped,
  setSort,
  tooltip,
  children,
  currentSort,
  className,
  align,
  padLeft,
  padRight,
}: ITableHeaderProps<T>) => (
  <div
    className={classNames(
      styles.Header,
      {
        [styles.HeaderSort]: sort,
        [styles.ColumnSticky]: sticky,
        [styles.CellAlignRight]: align === 'right',
        [styles.CellAlignCenter]: align === 'center',
        [styles.CellPadLeft]: padLeft ?? false,
        [styles.CellPadRight]: padRight ?? true,
      },
      className,
    )}
    onClick={() => {
      if (sort && setSort) {
        setSort(sort, sort === currentSort && !flipped);
      }
    }}
  >
    {children}
    {tooltip && (
      <Tooltip content={tooltip}>
        <FontAwesomeIcon className={styles.TooltipIcon} icon={faInfoCircle} />
      </Tooltip>
    )}
    {sort && (
      <span className={currentSort === sort ? styles.SortActive : styles.SortInactive}>
        <FontAwesomeIcon icon={currentSort === sort && flipped ? faChevronUp : faChevronDown} />
      </span>
    )}
  </div>
);
