import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { StaffContextProvider } from '@sparx/staff-manager';
import { schoolStaffClient } from 'api';
import { Chakra } from 'chakra/chakra-provider';
import { InfoTooltip } from 'components/tooltip/InfoTooltip';
import { queryClient } from 'queries/client';
import { useSchool } from 'queries/schools';
import { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { articleKeyContacts } from 'utils/knowledge-base-articles';

import { useSession } from '../../../queries/session';

export const SuspenseStaffContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { data: school } = useSchool({ suspense: true });
  const { data: session } = useSession({ suspense: true });

  // Above suspense on useSchool ensures that this will be set
  const schoolID = school?.name?.split('/')[1] || '';

  return (
    <Chakra>
      <StaffContextProvider
        value={{
          school,
          currentUserID: session?.user?.sparxUserId,
          queryClient,
          staffClient: schoolStaffClient,
          defaultProduct: Product.SPARX_READER,
          showTrainingProgress: true,

          knowledgeBaseArticleURL: articleKeyContacts,

          getSchoolID: async () => schoolID,
          onSelectStaff: (name, args) => navigate(`/teacher/${name}`, args),
          onStaffDeleted: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
          onStaffCreated: toastMessage => navigate(`/teacher/staff`, { state: { toastMessage } }),
          getInfoTooltip: text => <InfoTooltip content={text} />,
          onSwitchTab: idx => navigate(`/teacher/staff${idx === 1 ? '/keycontacts' : ''}`),
          useNavigationMessage: () => {
            const location = useLocation();
            return location.state?.toastMessage;
          },
          styles: {
            linkColor: 'var(--midnight-indigo-100)',
            editIconColor: 'var(--jaffa-orange-100)',
            headerBackgroundColor: 'var(--royal-blue-100)',
            tableSortOptions: {
              upIcon: faChevronUp,
              downIcon: faChevronDown,
              hoverStyle: { textDecoration: 'underline', cursor: 'pointer' },
            },
          },
        }}
      >
        {children}
      </StaffContextProvider>
    </Chakra>
  );
};
