import * as Sentry from '@sentry/react';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import {
  GetSessionResponse,
  UpdateBookSettingsRequest,
  UpdateBookSettingsResponse,
  UpdateOnboardingStatusRequest,
  UpdateTeacherRolesUpdateTimeRequest,
  User,
  UserEvent,
  UserType,
} from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Settings } from '@sparx/api/apis/sparx/reading/users/v1/settings';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { sessionsClient } from 'api';
import { setAnalyticsUserProperties } from 'app/analytics';
import { callLogoutCleanup } from 'app/cleanup';
import { getSchoolIDFromUrl, redirectToLogin } from 'app/handle-err';
import { clearAvailableBooks } from 'queries/books';
import { queryClient } from 'queries/client';
import { useIsGoldReader } from 'queries/gold';
import { setExperience } from 'queries/rewards';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAnonymousMode } from 'utils/anonymous';
import { checkHotjarRecording } from 'utils/hotjar';
import { View } from 'views';
import { pathForView } from 'views/views';

import { useSchoolID } from './schools';

export const useUserSettings = () => {
  const user = useUser();
  return useQuery<Settings | undefined>(
    ['user', 'settings'],
    async () => {
      // Load the settings from the get session. Note that this does not trigger
      // side effects (such as those in setSessionData function). As we only
      // enable this query when we have a user (see below) it should be safe
      // to do this.
      const session = await sessionsClient.getSession({});
      return session.response.user?.settings;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      // This prevents this query function from being called unless we have an
      // active user. In general this query value should be populated from the
      // initial getSession request. We would prefer this as it means that side
      // effects such as first login or gold reader just added would be triggered.
      //
      // If for some reason this value is missing then the query function can
      // be called after the initial request to get the settings, however the
      // side effects will not be triggered.
      enabled: Boolean(user),
    },
  );
};

export const setUserSettings = (settings: Settings | undefined) =>
  queryClient.setQueryData(['user', 'settings'], settings);

export const useUpdateUserSettings = () =>
  useMutation<UpdateBookSettingsResponse, Error, UpdateBookSettingsRequest>(
    ['user', 'settings', 'update'],
    async req => {
      // https://github.com/timostamm/protobuf-ts/blob/master/MANUAL.md#:~:text=But%20there%20is,await%20the%20call%3A
      // Could perhaps write middleware to avoid doing this everywhere?
      const { response } = await sessionsClient.updateBookSettings(req);
      return response;
    },
    {
      onMutate: data => {
        // Optimistically update the queryCache, assuming the update will succeed.
        // This makes the change quicker for users on slower networks.
        setUserSettings(data.settings);
      },
    },
  );

export const useLogout = () => {
  const schoolID = useSchoolID();

  return useMutation(() => sessionsClient.clearSession({}).response, {
    onMutate: () => {
      // Call any cleanup functions that need to be called before logging out.
      callLogoutCleanup();
    },
    onSuccess: () => redirectToLogin(schoolID),
  });
};

export const userTypeName: Record<UserType, string> = {
  [UserType.TEACHER]: 'teacher',
  [UserType.STUDENT]: 'student',
  [UserType.UNSPECIFIED]: 'unspecified',
};

export const onLoginSuccess = (resp: GetSessionResponse) => {
  const user = resp.user;
  if (user) {
    // Set the GA dimension for this user
    setAnalyticsUserProperties({
      user_id: user.userId,
      user_type: userTypeName[user.type],
      gold_reader: user.goldReader ? 'true' : 'false',
      is_admin: user.roles.includes(UserRole.EQMRead),
      school_id: user.schoolId,
    });
    Sentry.setUser({ id: user.userId });

    // Store the school ID on the window, so we can access it easily
    // for scripts.
    window.READER_SCHOOL_ID = user.schoolId;

    setSessionData(resp);
  }
};

const useSessionBase = <T = GetSessionResponse>(
  options?: UseQueryOptions<GetSessionResponse, Error, T, string[]>,
) =>
  useQuery(
    ['session'],
    async () => {
      const data = await sessionsClient.getSession({}).response;
      // Ensure that the school that is in the URL is the one that we currently have a session for.
      // If there is a mismatch then we redirect to the login page for the correct school.
      //
      // We do it here so that the session query never actually resolves, preventing the page from
      // loading and then redirecting straight away.
      const schoolID = getSchoolIDFromUrl();
      if (schoolID && schoolID !== data.user?.schoolId) {
        await redirectToLogin(schoolID);
      }

      return data;
    },
    options,
  );

export const useSession = (
  options?: UseQueryOptions<GetSessionResponse, Error, GetSessionResponse, string[]>,
) =>
  useSessionBase({
    retry: false,
    onSuccess: onLoginSuccess,
    staleTime: 60000,
    ...options,
  });

type SetUserFn = (u: User) => User;
export const setUser = (user: User | SetUserFn) =>
  queryClient.setQueryData(['session'], (data: GetSessionResponse | undefined) => {
    if (!data) return undefined;
    return data.user
      ? { ...data, user: typeof user === 'function' ? user(data.user) : user }
      : undefined;
  });

export const useServerOffset = () =>
  useQuery(
    ['serveroffset'],
    async () => {
      const response = await sessionsClient.getServerTime({});
      return {
        clientNowMillis: new Date().getTime(),
        serverTimeMillis: (response.response.serverTime?.seconds || 0) * 1000,
      };
    },
    {
      staleTime: 1000 * 60 * 2,
    },
  );

export enum UserRole {
  EQMRead = 'eqm:read',
  EQMWrite = 'eqm:write',
  EQMAdmin = 'eqm:admin',
  Admin = 'admin',
}

export const userHasRole = (user: User | undefined, role: UserRole) =>
  // If we are in anonymous mode, pretend that the user has no admin roles.
  !isAnonymousMode() && Boolean(user?.roles?.includes(role));

export const useUser = () => useSession().data?.user;

export const useUserHasRole = (role: UserRole) => userHasRole(useUser(), role);

export const useCanVocab = () => {
  const user = useUser();
  return Boolean(user && !user.features.includes('novocab'));
};

export const useIsContentAdmin = () => useUserHasRole(UserRole.EQMAdmin);

export const useIsSilverReader = () => Boolean(useUser()?.features.includes('silver'));

export const useReaderLevel = (): 'gold' | 'silver' | 'default' => {
  const user = useUser();
  const isGoldReader = useIsGoldReader();
  const isSilverReader = useIsSilverReader();
  if (isGoldReader && !user?.statistics?.goldReaderDisabled) return 'gold';
  if (isSilverReader) return 'silver';
  return 'default';
};

export const useUserCanReadBookFunc = () => {
  const readerLevel = useReaderLevel();
  return (book: MetadataAbridged | undefined) => {
    // TODO: This says a book is a silver reader book if it has at least one silver
    //  reader config. It does not check that the ISBN that was scanned by the user
    //  matches the ISBN of the silver reader config. This would be a good improvement.
    const isSilverReaderBook = book?.silverReader && book?.silverReader.length > 0;
    const isGoldReaderBook = book && !book.ebookActive;

    return {
      allowedToRead:
        !isGoldReaderBook ||
        (readerLevel === 'silver' && isSilverReaderBook) ||
        (readerLevel === 'gold' && isGoldReaderBook),
      isSilverReaderBook,
      isGoldReaderBook,
    };
  };
};

export const useUserCanReadBook = (book: MetadataAbridged | undefined) =>
  useUserCanReadBookFunc()(book);

export function setSessionData(response: GetSessionResponse) {
  const { user, experience } = response;
  if (user) {
    setUser(user);
  }
  if (experience) {
    setExperience(experience);
  }
  if (user?.settings) {
    setUserSettings(user?.settings);
  }
  checkHotjarRecording(user);
}

/**
 * Hook for the mutation when the student has finished onboarding. Also applies
 * to fixed tasks.
 */
export const useFinishOnboarding = () => {
  const navigate = useNavigate();

  return useMutation(
    () => sessionsClient.updateOnboardingStatus(UpdateOnboardingStatusRequest.create()).response,
    {
      onSuccess: () => {
        setUser(user => ({ ...user, features: user.features.concat('onboarded') }));
        // Clear the available books cache to be reloaded on the library page.
        clearAvailableBooks();
        navigate(pathForView(View.Explore), { replace: true });
      },
    },
  );
};

// This checks whether they have the onboarded feature, which is set when they
// complete onboarding.
export const useIsUserOnboarded = () => Boolean(useUser()?.features.includes('onboarded'));

export const useUserEventStream = () => {
  useQuery(
    ['user', 'eventstream'],
    async () => {
      console.info('[event stream] starting...');
      const call = sessionsClient.streamUserEvents({});

      // Could use the for-await syntax if our build target was es2018.
      //       for await (const message of call.responses) {
      call.responses.onMessage(message => {
        console.info('[event stream] message:', message);
        handleUserEvent(message);
      });

      call.responses.onError(err => {
        console.log('[event stream] errored:', err);
      });

      const { status, trailers } = await call;
      console.info('[event stream] ended:', { status, trailers });
      throw new Error('Stream ended'); // Ensures that react-query will retry
    },
    {
      retry: true,
      retryDelay: retryCount => Math.min(retryCount, 100) * 250,
    },
  );
};

const handleUserEvent = (event: UserEvent) => {
  switch (event.type) {
    case 'pkg': {
      console.log('[event stream] refetching homework...');
      queryClient.refetchQueries(['homeworks', 'mine']);
    }
  }
};

export const useUserHasBaseline = () =>
  useQuery(
    ['user', 'baselineResponse'],
    () => sessionsClient.listBaselineAssessmentAnswers({}).response,
    {
      select: ({ answers }) => answers.length !== 0,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

export const useUserActive = () => {
  const location = useLocation();

  return useQuery(
    ['user', 'active'],
    () =>
      sessionsClient.userActive({
        currentPath: location.pathname,
      }).response,
    {
      refetchInterval: 15000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: 'always',
      refetchOnReconnect: 'always',
      retry: false,
    },
  );
};

/**
 * Get the timestamp stored against a user which specifies the last time they
 * reviewed and saved their user roles following the in-product prompt.
 */
export const useUserRolesLastUpdated = () =>
  useSessionBase({
    select: data => data.user?.teacherRolesUpdateTime,
  });

/**
 * Update the timestamp stored against a user which specifies the last time they
 * reviewed and saved their user roles following the in-product prompt.
 */
export const useUpdateUserRolesDate = () =>
  useMutation(
    (req: UpdateTeacherRolesUpdateTimeRequest) =>
      sessionsClient.updateTeacherRolesUpdateTime(req).response,
    { onSuccess: () => queryClient.invalidateQueries(['session']) },
  );
