// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/v2/schoolactions.proto" (package "sparx.school.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolActionsService } from './schoolactions';
import type { SchoolCalendar } from '../calendar/v4/calendar';
import type { UpdateSchoolCalendarRolloverStepAnnotationsRequest } from './schoolactions';
import type { CompleteRolloverResponse } from './schoolactions';
import type { CompleteRolloverRequest } from './schoolactions';
import type { UpdateSchoolPlannedHWStartDateRequest } from './schoolactions';
import type { UpdateSchoolParentEmailSettingsRequest } from './schoolactions';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { School } from './schools';
import type { UpdateSchoolSSOSettingsRequest } from './schoolactions';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * SchoolActionsService allows limited operations against schools to be
 * performed with fewer permissions than normal
 *
 * @generated from protobuf service sparx.school.v2.SchoolActionsService
 */
export interface ISchoolActionsServiceClient {
  /**
   * UpdateSchoolSSOSettings lets a teacher update their school's SSO settings
   *
   * @generated from protobuf rpc: UpdateSchoolSSOSettings(sparx.school.v2.UpdateSchoolSSOSettingsRequest) returns (sparx.school.v2.School);
   */
  updateSchoolSSOSettings(
    input: UpdateSchoolSSOSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolSSOSettingsRequest, School>;
  /**
   * UpdateSchoolParentEmailSettings lets a teacher update their school's
   * ParentEmail settings
   *
   * @generated from protobuf rpc: UpdateSchoolParentEmailSettings(sparx.school.v2.UpdateSchoolParentEmailSettingsRequest) returns (sparx.school.v2.School);
   */
  updateSchoolParentEmailSettings(
    input: UpdateSchoolParentEmailSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolParentEmailSettingsRequest, School>;
  /**
   * UpdateSchoolPlannedHWStartDate lets a teacher update their school's
   * planned homework start date
   *
   * @generated from protobuf rpc: UpdateSchoolPlannedHWStartDate(sparx.school.v2.UpdateSchoolPlannedHWStartDateRequest) returns (sparx.school.v2.School);
   */
  updateSchoolPlannedHWStartDate(
    input: UpdateSchoolPlannedHWStartDateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolPlannedHWStartDateRequest, School>;
  /**
   * @generated from protobuf rpc: CompleteRollover(sparx.school.v2.CompleteRolloverRequest) returns (sparx.school.v2.CompleteRolloverResponse);
   */
  completeRollover(
    input: CompleteRolloverRequest,
    options?: RpcOptions,
  ): UnaryCall<CompleteRolloverRequest, CompleteRolloverResponse>;
  /**
   * @generated from protobuf rpc: UpdateSchoolCalendarRolloverStepAnnotations(sparx.school.v2.UpdateSchoolCalendarRolloverStepAnnotationsRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  updateSchoolCalendarRolloverStepAnnotations(
    input: UpdateSchoolCalendarRolloverStepAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSchoolCalendarRolloverStepAnnotationsRequest,
    SchoolCalendar
  >;
}
/**
 * SchoolActionsService allows limited operations against schools to be
 * performed with fewer permissions than normal
 *
 * @generated from protobuf service sparx.school.v2.SchoolActionsService
 */
export class SchoolActionsServiceClient
  implements ISchoolActionsServiceClient, ServiceInfo
{
  typeName = SchoolActionsService.typeName;
  methods = SchoolActionsService.methods;
  options = SchoolActionsService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * UpdateSchoolSSOSettings lets a teacher update their school's SSO settings
   *
   * @generated from protobuf rpc: UpdateSchoolSSOSettings(sparx.school.v2.UpdateSchoolSSOSettingsRequest) returns (sparx.school.v2.School);
   */
  updateSchoolSSOSettings(
    input: UpdateSchoolSSOSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolSSOSettingsRequest, School> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolSSOSettingsRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateSchoolParentEmailSettings lets a teacher update their school's
   * ParentEmail settings
   *
   * @generated from protobuf rpc: UpdateSchoolParentEmailSettings(sparx.school.v2.UpdateSchoolParentEmailSettingsRequest) returns (sparx.school.v2.School);
   */
  updateSchoolParentEmailSettings(
    input: UpdateSchoolParentEmailSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolParentEmailSettingsRequest, School> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolParentEmailSettingsRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateSchoolPlannedHWStartDate lets a teacher update their school's
   * planned homework start date
   *
   * @generated from protobuf rpc: UpdateSchoolPlannedHWStartDate(sparx.school.v2.UpdateSchoolPlannedHWStartDateRequest) returns (sparx.school.v2.School);
   */
  updateSchoolPlannedHWStartDate(
    input: UpdateSchoolPlannedHWStartDateRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateSchoolPlannedHWStartDateRequest, School> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateSchoolPlannedHWStartDateRequest, School>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CompleteRollover(sparx.school.v2.CompleteRolloverRequest) returns (sparx.school.v2.CompleteRolloverResponse);
   */
  completeRollover(
    input: CompleteRolloverRequest,
    options?: RpcOptions,
  ): UnaryCall<CompleteRolloverRequest, CompleteRolloverResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CompleteRolloverRequest, CompleteRolloverResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateSchoolCalendarRolloverStepAnnotations(sparx.school.v2.UpdateSchoolCalendarRolloverStepAnnotationsRequest) returns (sparx.school.calendar.v4.SchoolCalendar);
   */
  updateSchoolCalendarRolloverStepAnnotations(
    input: UpdateSchoolCalendarRolloverStepAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateSchoolCalendarRolloverStepAnnotationsRequest,
    SchoolCalendar
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateSchoolCalendarRolloverStepAnnotationsRequest,
      SchoolCalendar
    >('unary', this._transport, method, opt, input);
  }
}
