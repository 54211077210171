import classNames from 'classnames';
import { ErrorMessage } from 'components/errors/error-message';
import { useTeacherYearGroupLeaderboard } from 'queries/leagueTables';
import { useEnglishYearGroups, useTeacherStudentsWithExperience } from 'queries/management';
import { useEffect } from 'react';
import styles from 'views/student/league-view/league-view.module.css';

import { LeagueTableRow } from '../components/row/league-table-row';
import { LoadingRow } from '../components/row/loading-row';
import { AllUsersOptedOut } from './all-users-opted-out';
import { findStudent } from './helpers';
import { LeaderboardProps } from './leaderboards';

export const TeacherYearGroupLeaderboard = ({
  dateRange,
  yearGroupId,
  setYearGroupId,
}: LeaderboardProps) => {
  const { data: yearGroups, isLoading: isLoadingYearGroups } = useEnglishYearGroups();
  const {
    data,
    isLoading: isLoadingLeaderboard,
    isFetched,
    isError,
  } = useTeacherYearGroupLeaderboard(yearGroupId, dateRange);
  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();

  useEffect(() => {
    if (yearGroups && yearGroups.length > 0 && yearGroupId === '') {
      setYearGroupId(yearGroups?.[0].yearGroupID ?? '');
    }
  }, [setYearGroupId, yearGroupId, yearGroups]);

  if (isError) {
    return <ErrorMessage message="Failed to load leaderboard" />;
  }

  // If the query is disabled, there are no students in the year group
  if (yearGroupId && !isFetched && !isLoadingLeaderboard) {
    return <>There are no students in this class</>;
  }

  const isLoading = Boolean(isLoadingLeaderboard || isLoadingStudents || isLoadingYearGroups);

  return (
    <>
      <div className={classNames(styles.Block, styles.TimeSelect)} style={{ textAlign: 'right' }}>
        <label className={styles.Label} htmlFor="class">
          Year group:
        </label>
        <select
          className="select-dropdown text-input"
          value={yearGroupId}
          onChange={e => setYearGroupId(e.target.value)}
          id="class"
        >
          {yearGroups?.map(yearGroup => (
            <option key={yearGroup.yearGroupID} value={yearGroup.yearGroupID}>
              {yearGroup.name}
            </option>
          ))}
        </select>
      </div>

      <AllUsersOptedOut users={data?.users}>
        {students &&
          data?.users.map((leaderboardUser, i) => (
            <LeagueTableRow
              key={i}
              leaderboardUser={leaderboardUser}
              student={findStudent(students, leaderboardUser)}
            />
          ))}
      </AllUsersOptedOut>

      {isLoading && [...Array(20).keys()].map(i => <LoadingRow key={i} />)}
    </>
  );
};
