import classNames from 'classnames';
import { Property } from 'csstype';
import { ComponentProps, CSSProperties, forwardRef } from 'react';

import { SparxUnit, sparxUnitToValue } from '../../utils/units';
import styles from './Stack.module.css';

type StackDirection = 'row' | 'column'; // TODO: | 'row-reverse' | 'column-reverse';

interface StackOptions {
  align?: Property.AlignItems;
  justify?: Property.JustifyContent;
  // TODO: we polyfill flex-gap for browser support, but this doesn't handle wrapping well
  // wrap?: Property.FlexWrap;
  direction?: StackDirection;
  spacing?: SparxUnit;
}

interface StackProps extends ComponentProps<'div'>, StackOptions {}

/**
 * Stacks help you easily create flexible and automatically distributed layouts.
 *
 * You can stack elements in the horizontal or vertical direction. The default
 * direction is horizontal with a spacing of 2.
 *
 * Note that the children should be in individual elements that can be styled for
 * this to work.
 *
 * @todo
 * This element would ideally use flex-gap functionality but is not currently
 * supported in all browsers. We polyfill this with a custom property `--gap` that
 * is used in the CSS to add spacing between elements.
 *
 * @example
 * <Stack direction="column" spacing={4}>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Stack>
 *
 * @example
 * <Stack>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 * </Stack>
 */
export const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(props, ref) {
  const { direction = 'row', align, justify, spacing = 2, children, className, ...rest } = props;

  return (
    <div
      ref={ref}
      style={
        {
          alignItems: align,
          justifyContent: justify,
          ['--gap']: sparxUnitToValue(spacing),
        } as CSSProperties
      }
      className={classNames(
        styles.Stack,
        direction === 'column' ? styles.StackColumn : styles.StackRow,
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
});
