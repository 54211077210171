import { isJsonObject, JsonObject, JsonValue } from '@protobuf-ts/runtime';
import { Struct } from '@sparx/api/google/protobuf/struct';
import { parse, startOfDay } from 'date-fns';
import { useSession } from 'queries/session';
import { IMaintenanceModeSettings } from 'views/common/maintenance-view';

/**
 * List of flags that are available from LaunchDarkly.
 */
export enum Flags {
  MaintenanceMode = 'maintenance-mode',
  StatusNotification = 'status-notification',
  BannerNotification = 'banner-notification',
  Classwork = 'classwork-enabled',
  ContextAwareDefinitions = 'context-aware-definitions',
  ContextAwareDefinitionsAudio = 'context-aware-definitions-audio',
  TeacherLibrary = 'teacher-library',
  SubscriptionManager = 'subscription-manager',
  SilverReaderBookmarks = 'silver-reader-bookmarks',
  SubscriptionWarningPeriod = 'subs-warning-period',
  SubscriptionWarningPriorityPeriod = 'subs-warning-priority-period',
  HomeworkHistory = 'homework-history',
  TeacherSwapBooks = 'teacher-swap-books',
  BookCompletionParentEmail = 'book-completion-parent-email',
  RolloverEnabled = 'rollover-2024-enabled',
  CannyEnabled = 'reader-canny-feedback',
  SuppressRolePrompt = 'suppress-role-prompt',
  RolesDialogOverrideDate = 'roles-dialog-override-date',
}

/**
 * Use all of the LaunchDarkly flags.
 */
export const useFlags = (): Record<string, JsonValue | undefined> => {
  const session = useSession();
  if (session.data?.featureFlags === undefined) {
    console.info('No feature flags loaded.');
    return {};
  }
  const jsonValue = Struct.toJson(session.data.featureFlags);
  if (jsonValue == null || !isJsonObject(jsonValue)) {
    console.error('Feature flags must be an object.');
    return {};
  }
  return jsonValue;
};

/**
 * Use a LaunchDarkly flag. The code will watch for live changes
 * to the flag and update the value accordingly.
 */
export const useFlag = (key: string, defaultValue?: JsonValue): JsonValue | undefined =>
  useFlags()[key] ?? defaultValue;

export const useObjectFlag = (key: string): JsonObject | undefined => {
  const value = useFlags()[key];
  return value && isJsonObject(value) ? value : undefined;
};

/**
 * Returns the maintenance mode settings or undefined if the maintenance
 * mode is not enabled.
 */
export const useMaintenanceFlag = (): IMaintenanceModeSettings | undefined => {
  const maintenance = useObjectFlag(Flags.MaintenanceMode);
  return maintenance?.enabled ? { enabled: true, ...maintenance } : undefined;
};

export interface IFlaggedNotification {
  message: string;
  type: string;
}

export const useStatusNotification = (): IFlaggedNotification | undefined => {
  const scheduled = useObjectFlag(Flags.StatusNotification);
  if (!scheduled || typeof scheduled.type !== 'string' || typeof scheduled.message !== 'string') {
    return undefined;
  }
  return { message: scheduled.message, type: scheduled.type };
};

export const useBannerNotification = (): IFlaggedNotification | undefined => {
  const banner = useObjectFlag(Flags.BannerNotification);
  if (!banner || typeof banner.type !== 'string' || typeof banner.message !== 'string') {
    return undefined;
  }
  return { message: banner.message, type: banner.type };
};

export const useBooleanFlag = (key: string) => {
  const value = useFlag(key);
  return Boolean(value);
};

export const useNumberFlag = (key: Flags) => {
  const value = useFlag(key);
  return Number(value);
};

export const useDateFlag = (key: Flags) => {
  const value = useFlag(key);
  if (typeof value === 'string' && value.length > 0) {
    return parse(value, 'yyyy-MM-dd', startOfDay(new Date()));
  }
  return null;
};
