import { ModuleState, ModuleStepState } from '@sparx/teacher-training';
import slugify from 'slugify';

export const makeModuleSlug = (module: ModuleState) => {
  return slugify(module.spec.title, { lower: true });
};

export const makeModulePath = (module: ModuleState) => {
  return `/teacher/training/${makeModuleSlug(module)}`;
};

export const makeStepSlug = (step: ModuleStepState) => {
  return slugify(step.spec.title, { lower: true });
};

export const makeStepPath = (step: ModuleStepState) => {
  return `/teacher/training/${makeModuleSlug(step.module)}/${makeStepSlug(step)}`;
};
