import { StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';

import { getRoleName, listString, makeCountDescriptor } from './utils';

// SPXEMAILREGEXP - If updating this regex please update all other uses
// If copying for use in another language, please copy this comment
//
// See lib/spxtools/email.go for explanation/tests
//
// eslint-disable-next-line no-control-regex
export const emailValidationRegex = /^[^@\s,]+@(?:[-a-z0-9]+\.)+[a-z]{2,}$/i;

export const getRoleWarningMessage = (
  roleAssignments: StaffRoleAssignment[],
  variant: 'edit-staff' | 'role-prompt' = 'edit-staff',
): string | undefined => {
  if (!roleAssignments.length) {
    return undefined;
  }

  const roleNames = [...new Set(roleAssignments.map(r => getRoleName(r.role, r.product)))];

  const role = makeCountDescriptor('role')(roleNames, false);
  const roleList = listString(roleNames);

  if (variant === 'edit-staff') {
    return `The following ${role} can only be applied to one staff member in each Sparx system: ${roleList}. If you save these changes, any existing staff with the same ${role} will have the ${role} removed.`;
  }

  const thisRole = makeCountDescriptor('this role', 'these roles')(roleNames, false);

  return `The ${roleList} ${role} can only be applied to one staff member. Somebody else already holds ${thisRole}. If you save, the ${role} will be removed and applied to you.`;
};
