// isProdEnv returns true if we are running in production.
// It uses the settings file to determine this.
export const isProdEnv = (): boolean => {
  return window.settings?.readerEnv === 'prod';
};

// isTestEnv returns true if we are running in test 1 or 2.
// It uses the settings file to determine this.
export const isTestEnv = (): boolean => {
  return window.settings?.readerEnv?.startsWith('test') || false;
};

// isDevEnv returns true if we are running in a dev environment.
// It uses the settings file to determine this.
export const isDevEnv = (): boolean => {
  return window.settings?.readerEnv === 'dev';
};

// getReaderEnvironment returns the environment that we are running in, one of:
// - prod
// - test1
// - test2
// - dev
export const getReaderEnvironment = (): string => {
  return window.settings?.readerEnv || '';
};
