import { format } from 'date-fns';
import * as htmlToImage from 'html-to-image';
import { useTeacherStudentsWithExperience } from 'queries/management';
import { useMemo } from 'react';

import { WidgetConfig } from './types';

export const fileName = (schoolName: string, type: string) =>
  `${schoolName} - ${type} (${format(new Date(), 'yyyy-MM-dd')})`;

export const downloadWidgetAsImage = async (
  schoolName: string,
  config: WidgetConfig,
  element: HTMLDivElement,
) => {
  // account for weird Safari bug
  // https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
  await htmlToImage.toPng(element);
  await htmlToImage.toPng(element);
  const dataUrl = await htmlToImage.toPng(element);
  const link = document.createElement('a');

  link.download = `${fileName(schoolName, config.id)}.png`;
  link.href = dataUrl;
  link.click();
};

export const useSchoolReadingHoursThisYear = () => {
  const { data, isLoading } = useTeacherStudentsWithExperience();

  const readingHoursThisYear = useMemo(() => {
    const srpThisYear = Object.values(data || {}).reduce(
      (acc, student) => acc + student.experienceThisYear,
      0,
    );
    return srpThisYear / 10 / 60;
  }, [data]);

  return { readingHoursThisYear, isLoading };
};
