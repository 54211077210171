import { PartialMessage } from '@protobuf-ts/runtime';
import {
  TrainingModuleState,
  UpdateTrainingProgressForCurrentUserRequest,
} from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { ModuleSpec, ModuleStepState, useCurrentTrainingState } from '@sparx/teacher-training';
import { useMutation } from '@tanstack/react-query';
import { trainingProgressClient } from 'api';
import { useMemo } from 'react';

import { TRAINING_MODULE_ESSENTIAL, TRAINING_MODULE_USING_SPARX_READER } from './module-specs';

export enum CurrentTrainingLevel {
  ESSENTIAL,
  USING_SPARX_READER,
  COMPLETE,
}

export interface levelStats extends ModuleStepState {
  firstIncompleteIx: number;
}
export interface TrainingProgressStats {
  totalStepsCount: number;
  completeStepsCount: number;
  progress: number;
  allComplete: boolean;
  essential: levelStats;
  usingSparxReader: levelStats;
  currentLevel: CurrentTrainingLevel;
}

export const useCurrentTrainingProgress = () => {
  const { data: moduleStates } = useCurrentTrainingState({});
  return useMemo(() => {
    const completeStepsCount =
      moduleStates?.reduce((sum, moduleState) => {
        return sum + moduleState.steps.filter(step => step.complete).length;
      }, 0) ?? 0;
    const totalStepsCount =
      moduleStates?.reduce((sum, moduleState) => {
        return sum + moduleState.steps.length;
      }, 0) ?? 0;
    const progress = totalStepsCount === 0 ? 0 : completeStepsCount / totalStepsCount;
    const essentialModule = moduleStates?.find(ms => ms.spec.name === TRAINING_MODULE_ESSENTIAL);
    const usingSparxModule = moduleStates?.find(
      ms => ms.spec.name === TRAINING_MODULE_USING_SPARX_READER,
    );
    const currentLevel =
      essentialModule?.complete && usingSparxModule?.complete
        ? CurrentTrainingLevel.COMPLETE
        : essentialModule?.complete
          ? CurrentTrainingLevel.USING_SPARX_READER
          : CurrentTrainingLevel.ESSENTIAL;

    return {
      completeStepsCount,
      totalStepsCount,
      progress,
      currentLevel,
      allComplete: essentialModule?.complete && usingSparxModule?.complete,
      essential: {
        ...essentialModule,
        firstIncompleteIx: essentialModule?.steps.findIndex(s => !s.complete) ?? -1,
      },
      usingSparxReader: {
        ...usingSparxModule,
        firstIncompleteIx: usingSparxModule?.steps.findIndex(s => !s.complete) ?? -1,
      },
    };
  }, [moduleStates]);
};

export const useResetTrainingProgressForSpec = (specs: ModuleSpec[]) => {
  return useMutation({
    mutationFn: async (modules: string[]) => {
      const moduleData: PartialMessage<{
        [key: string]: TrainingModuleState;
      }> = {};

      if (modules.length > 0) {
        for (const m of modules) {
          moduleData[m] = {};
        }
      } else {
        for (const m of specs) {
          moduleData[m.name] = {};
        }
      }

      const req = UpdateTrainingProgressForCurrentUserRequest.create({
        trainingProgress: {
          moduleData,
        },
        updateMask: FieldMask.create({
          paths: [
            'module_data.completed_steps',
            'module_data.completed_time',
            'module_data.dismiss_time',
          ],
        }),
      });

      return trainingProgressClient.updateTrainingProgressForCurrentUser(req).response;
    },
    onSuccess: () => {
      // Cause a reload, this is mainly because some components keep state in
      // refs which wouldn't otherwise be reset
      location.reload();
    },
    onError: e => {
      console.error('Error resetting training progress:', e);
    },
  });
};
