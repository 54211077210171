import { Book } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import {
  AssessmentPackageState,
  GetTeacherExperienceResponse,
  GrantStudentPasswordRequest,
  ListAssessmentPackagesResponse,
  SwapStudentBookRequest,
  TeacherUserDetail,
  UpdateStudentRequest,
} from '@sparx/api/apis/sparx/reading/management/v1/management';
import { LatestStudentEbook } from '@sparx/api/apis/sparx/reading/reports/v2/reporting';
import { Experience } from '@sparx/api/apis/sparx/reading/users/v1/experience';
import { HomeworkLength, User, UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import {
  Group,
  ListGroupsRequest,
  ListGroupsResponse,
  ListYearGroupsRequest,
  ListYearGroupsResponse,
} from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { StudentGroupType, YearGroup } from '@sparx/api/teacherportal/schoolman/smmsg/schoolman';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { groupsClient, managementClient, monitoringClient, reportingClient } from 'api';
import { combineQueryStates, queryClient } from 'queries/client';
import { useListStaff } from 'queries/staff';
import { isAnonymousMode } from 'utils/anonymous';
import { getStudentGroupId } from 'utils/groups';
import { timestampToMoment } from 'utils/time';

import { useBatchBookMetadata } from './books';
import { useSchoolID } from './schools';
import { useUser } from './session';

export const useSearchStudents = (query: string) =>
  useQuery(['searchStudents', query], () => managementClient.searchStudents({ query }).response, {
    enabled: query !== '',
    staleTime: 60000,
  });

export const useTeacherExperience = <T = GetTeacherExperienceResponse>(
  options: UseQueryOptions<GetTeacherExperienceResponse, Error, T, string[]>,
) =>
  useQuery({
    queryKey: ['teacher', 'experience'],
    queryFn: async () => {
      let mapUser = (u: User) => u;

      // If we are in anonymous mode we load the fake names module dynamically
      // and then replace the user first and last names with fake names.
      if (isAnonymousMode()) {
        const { randomName } = await import('queries/fakenames/names');
        mapUser = (s: User): User => ({ ...s, ...randomName(s.userId), username: 'username' });
      }

      const response = await managementClient.getTeacherExperience({}).response;
      response.users = response.users.map(mapUser);
      return response;
    },
    ...options,
  });

export type IStudentWithExperience = User &
  Experience & {
    detail?: TeacherUserDetail;
    experienceThisYear: number;
  };

const defaultExperience: Experience = { experience: 0 };

export const useTeacherStudentsWithExperience = () =>
  useTeacherExperience({
    staleTime: 15000,
    refetchInterval: 60000,
    select: data => {
      const students: Record<string, IStudentWithExperience> = {};
      for (const student of data?.users || []) {
        students[student.userId] = {
          ...student,
          ...(data?.studentExperience[student.userId] || defaultExperience),
          detail: data.userDetail[student.userId],
          experienceThisYear: (data.studentExperienceThisYear[student.userId] || defaultExperience)
            .experience,
        };
      }
      return students;
    },
  });

export const useStudentByID = (userId?: string) =>
  useTeacherExperience({
    staleTime: 15_000,
    refetchInterval: 60_000,
    select: data => data.users.find(student => student.userId === userId),
    enabled: Boolean(userId),
  });

export const markTeacherStudentsWithExperienceStale = () =>
  queryClient.invalidateQueries(['teacher', 'experience']);

export const useStudentGroupsQueryKey = ['teacher', 'groups'];

export const useStudentGroups = <T>(
  options: UseQueryOptions<ListGroupsResponse, Error, T, string[]>,
) => {
  const schoolId = useSchoolID();
  return useQuery(
    useStudentGroupsQueryKey,
    () =>
      groupsClient.listGroups(
        ListGroupsRequest.create({
          parent: `schools/${schoolId}`,
        }),
      ).response,
    {
      enabled: Boolean(schoolId),
      cacheTime: 900000, // 15 minutes
      staleTime: 300000, // 5 minutes
      ...options,
    },
  );
};

export const useAllStudentGroups = () =>
  useStudentGroups({
    select: data =>
      data.groups.sort((a, b) =>
        a.displayName.localeCompare(b.displayName, undefined, { numeric: true }),
      ),
    staleTime: 60000,
  });

export const useStudentGroup = (groupID: string) =>
  useStudentGroups({
    select: data => data.groups.find(g => getStudentGroupId(g) === groupID),
    staleTime: 60000,
  });

export const useEnglishStudentGroups = (
  options?: UseQueryOptions<ListGroupsResponse, Error, Group[], string[]>,
) => {
  const user = useUser();
  const isTeacher = user?.type === UserType.TEACHER;
  return useStudentGroups({
    select: data =>
      data.groups
        .filter(g => g.type === StudentGroupType.CLASS_ENGLISH)
        .sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { numeric: true })),
    enabled: isTeacher,
    ...options,
  });
};

export const YearGroupsQueryKey = ['teacher', 'yearGroups'];
export const useYearGroups = (
  options?: UseQueryOptions<ListYearGroupsResponse, Error, YearGroup[], string[]>,
) => {
  const schoolId = useSchoolID();
  return useQuery(
    YearGroupsQueryKey,
    () =>
      groupsClient.listYearGroups(
        ListYearGroupsRequest.create({
          schoolName: `schools/${schoolId}`,
        }),
      ).response,
    {
      select: data =>
        data.yearGroups.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
      enabled: Boolean(schoolId),
      cacheTime: 900000, // 15 minutes
      staleTime: 300000, // 5 minutes
      ...options,
    },
  );
};
export const useEnglishYearGroups = () => {
  const { data: studentGroups } = useEnglishStudentGroups();

  const englishYearGroupIds = studentGroups?.map(sg => sg.yearGroupId);

  return useYearGroups({
    select: data =>
      data.yearGroups.filter(yearGroup => englishYearGroupIds?.includes(yearGroup.yearGroupID)),
  });
};

const liveRefreshInterval = 5000;

export const useWatchStudentGroups = (groupIDs: string[]) =>
  useQuery(
    ['teacher', 'live', groupIDs.sort((a, b) => a.localeCompare(b))],
    () =>
      monitoringClient.watchStudents({
        studentGroupId: groupIDs,
      }).response,
    {
      refetchInterval: liveRefreshInterval,
    },
  );

export const useUpdateStudent = () =>
  useMutation((req: UpdateStudentRequest) => managementClient.updateStudent(req).response, {
    // TODO: make this an optimistic update instead
    onSuccess: () => queryClient.invalidateQueries(['teacher', 'experience']),
  });

export const homeworkLengthNames: Record<HomeworkLength, string> = {
  [HomeworkLength.UNSPECIFIED]: 'Compulsory', // Unspecified implies compulsory
  [HomeworkLength.COMPULSORY]: 'Compulsory',
  [HomeworkLength.HALF]: 'Half length',
  [HomeworkLength.OPTIONAL]: 'Optional only',
};

export const useGrantStudentPassword = () =>
  useMutation(
    (req: GrantStudentPasswordRequest) => managementClient.grantStudentPassword(req).response,
    {
      // TODO: make this an optimistic update instead
      onSuccess: () => queryClient.invalidateQueries(['teacher', 'experience']),
    },
  );

export const useHomeworkStudentFlags = (homeworkId: string | undefined) =>
  useQuery(
    ['teacher', 'homework', homeworkId, 'studentflags'],
    () => managementClient.listHomeworkStudentFlags({ homeworkId: homeworkId || '' }).response,
    { select: d => d.studentFlags, enabled: !!homeworkId },
  );

export const useAvailableAssessments = (settableOnly?: boolean) =>
  useQuery(
    ['assessment', 'available'],
    () => managementClient.listAvailableAssessments({}).response,
    {
      select: data => data.forms.filter(f => !settableOnly || f.canSet),
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

export const useAssessmentPackages = (
  students: string[],
  studentGroupId: string,
  options?: UseQueryOptions<
    ListAssessmentPackagesResponse,
    Error,
    AssessmentPackageState[],
    string[]
  >,
) =>
  useQuery(
    ['assessmentpackages', studentGroupId],
    () =>
      managementClient.listAssessmentPackages({
        studentIds: students,
      }).response,
    {
      enabled: students.length > 0 && studentGroupId !== '',
      select: data => data.packages,
      refetchInterval: liveRefreshInterval,
      ...options,
    },
  );

export const invalidateAssessmentPackages = (studentGroupId: string) =>
  queryClient.invalidateQueries(['assessmentpackages', studentGroupId]);

export interface IStudentBookHistory {
  studentBook: StudentBook;
  book?: Book;
}

export const useGetStudentBookHistory = (studentId: string) =>
  useQuery(
    ['teacher', 'studentBooks', studentId],
    () => managementClient.getStudentBooks({ studentId }).response,
    {
      enabled: Boolean(studentId),
      select: data => data.books,
      staleTime: 180000,
    },
  );

const useLatestStudentEbooks = () => {
  const schoolID = useSchoolID();
  return useQuery(
    ['teacher', 'listLatestStudentEbooks'],
    async () => {
      let nextPageToken = '';
      const studentEbooks: LatestStudentEbook[] = [];
      do {
        const response = await reportingClient.listLatestStudentEbooks({
          schoolName: 'schools/' + schoolID,
          pageSize: 0,
          pageToken: nextPageToken,
        }).response;
        studentEbooks.push(...response.latestStudentEbooks);
        nextPageToken = response.nextPageToken;
      } while (nextPageToken !== '');
      return studentEbooks;
    },
    {
      enabled: Boolean(schoolID),
      select: data =>
        data.reduce((studentToEbookID, { studentId, latestEbookId }) => {
          studentToEbookID.set(studentId, latestEbookId);
          return studentToEbookID;
        }, new Map<string, string>()),
      staleTime: 30_000,
    },
  );
};

export const useLatestEbooksForStudents = (studentIds: string[]) => {
  const { data: latestEbooks, ...queryState } = useLatestStudentEbooks();

  const booksToFetch = new Set<string>();
  for (const studentId of studentIds) {
    const latestEbook = latestEbooks?.get(studentId);
    if (latestEbook) {
      booksToFetch.add(latestEbook);
    }
  }
  const booksMetadata = useBatchBookMetadata([...booksToFetch.values()]);
  const bookMap = new Map<string, Book>();
  for (const { data } of booksMetadata) {
    if (data) {
      bookMap.set(data.name.split('/')[1], data);
    }
  }

  const studentIdToBookMap = new Map<string, Book>();
  if (latestEbooks) {
    for (const studentId of studentIds) {
      const bookId = latestEbooks.get(studentId);
      if (bookId) {
        const book = bookMap.get(bookId);
        if (book) {
          studentIdToBookMap.set(studentId, book);
        }
      }
    }
  }
  return { studentIdToBookMap, ...combineQueryStates(queryState, ...booksMetadata) };
};

export const useListStudentPackageTasks = (studentId: string, packageId: string) =>
  useQuery(
    ['teacher', studentId, packageId, 'packagetasks'],
    () => managementClient.listStudentPackageTasks({ studentId, packageId }).response,
    {
      enabled: Boolean(packageId) && Boolean(studentId),
    },
  );

export const useListAccountStatuses = (studentGroupId: string) =>
  useQuery(
    ['accountStatuses', studentGroupId],
    () => managementClient.listAccountStatuses({ studentGroupId }).response,
    { enabled: Boolean(studentGroupId) },
  );

export const useGetSparxMathsLeader = (schoolID: string) =>
  useListStaff(schoolID, {
    select: data =>
      data.staffMembers.find(s =>
        s.roles.find(
          role => role.product === Product.SPARX_MATHS && role.role === StaffRole.SPARX_LEADER,
        ),
      ),
    enabled: !!schoolID,
  });

export const useGetStudentHomeworkTaskAttempts = (studentId: string, homeworkId: string) =>
  useQuery(
    ['teacher', studentId, homeworkId, 'taskattempts'],
    () => managementClient.getStudentHomeworkTaskAttempts({ studentId, homeworkId }).response,
    {
      enabled: Boolean(homeworkId) && Boolean(studentId),
      select: data => {
        data.attempts.sort((a, b) =>
          timestampToMoment(a.attemptedAt)?.isBefore(timestampToMoment(b.attemptedAt)) ? 1 : -1,
        );
        return data;
      },
    },
  );

export const useGetStudentHomework = (studentId: string, homeworkId: string) =>
  useQuery(
    ['studentHomework', studentId, homeworkId],
    () => managementClient.getStudentHomework({ studentId, homeworkId }).response,
    {
      enabled: Boolean(homeworkId) && Boolean(studentId),
    },
  );

export const useGetTaskBreakpointQuestion = (
  taskId: string,
  questionId: string,
  enabled: boolean,
) =>
  useQuery(
    ['teacher', taskId, 'breakpointquestions'],
    () =>
      managementClient.getTaskBreakpointQuestions({
        taskId,
      }).response,
    {
      select: data =>
        data.questions.find(q => {
          const quId = q.name.split('/')[5];
          return quId !== undefined && quId === questionId;
        }),
      enabled: Boolean(taskId) && Boolean(questionId) && enabled,
      staleTime: 60000,
      cacheTime: 60000,
    },
  );

export const useTeacherSwapStudentBook = () =>
  useMutation((req: SwapStudentBookRequest) => managementClient.swapStudentBook(req).response, {});
