import { TeacherContainer } from 'app/containers/teacher-container/teacher-container';
import { Loading } from 'components/loading/loading';
import { Suspense } from 'react';
import { Outlet, redirect, RouteObject } from 'react-router-dom';
import { Flags } from 'utils/featureflags';
import { View } from 'views';
import { HomeView } from 'views/teacher/home-view/home-view';
import { SuspenseStaffContextProvider } from 'views/teacher/staff-view/staff-manager-context';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import { pathForView, subPathForView } from 'views/views';

import { RequiresFlag } from './requires-flag';
import { RequiresTeacher } from './requires-teacher';

export const teacherRoutes: RouteObject = {
  path: pathForView(View.Teacher) + '/*',
  element: (
    <RequiresTeacher>
      <TeacherContainer>
        <Outlet />
      </TeacherContainer>
    </RequiresTeacher>
  ),
  children: [
    {
      path: '',
      element: <HomeView />,
    },
    {
      path: 'library/:bookId?',
      lazy: () => import('views/teacher/teacher-library-view/teacher-library-view'),
    },
    {
      path: 'whats-new',
      lazy: () => import('views/teacher/whats-new-view/whats-new-view'),
    },
    {
      path: 'live',
      lazy: () => import('views/teacher/live-view/live-view'),
    },
    {
      path: 'reports',
      lazy: () => import('views/teacher/reports-view/reports-view'),
    },
    {
      path: 'training/:moduleSlug?/:stepSlug?',
      lazy: () => import('views/teacher/teacher-training-view/teacher-training-view'),
    },
    {
      path: 'classes/homework',
      lazy: () => import('views/teacher/homework-view/homework-view'),
    },
    {
      path: 'classes/classwork',
      lazy: () => import('views/teacher/homework-view/homework-view'),
    },
    {
      path: 'sparx-reader-trial',
      lazy: () => import('views/teacher/sparx-reader-trial-view/sparx-reader-trial-view'),
    },
    {
      path: 'reading-matters',
      element: <Outlet />,
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/survey/teacher-cpd-view/teacher-cpd-view'),
        },
        {
          path: 'survey',
          lazy: () => import('views/teacher/survey/teacher-survey-view/teacher-survey-view'),
        },
      ],
    },
    {
      path: 'classes/homework/:studentGroupId/new',
      lazy: () => import('views/teacher/create-homework-view/create-homework-view'),
    },
    {
      path: 'classes/homework/:studentGroupId/edit/:homeworkGroupId',
      lazy: () => import('views/teacher/edit-homework-view/edit-homework-view'),
    },
    {
      path: 'classes/readingtest',
      lazy: () => import('views/teacher/readingtest-view/readingtest-view'),
    },
    {
      path: 'classes/students',
      lazy: () => import('views/teacher/students-view/students-view'),
    },
    {
      path: 'leaderboard',
      lazy: () => import('views/student/league-view/league-view'),
    },
    {
      path: 'classes/bookmarks',
      element: (
        <RequiresFlag flag={Flags.SilverReaderBookmarks}>
          <Outlet />
        </RequiresFlag>
      ),
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/silver-bookmark-view/silver-bookmark-view'),
        },
      ],
    },
    {
      path: 'student/:studentId',
      children: [
        {
          path: 'details',
          lazy: () => import('views/teacher/students-view/student-view'),
        },
        {
          path: 'summary',
          lazy: () => import('views/teacher/students-view/student-view'),
        },
        {
          path: 'history',

          children: [
            {
              path: '',
              lazy: () => import('views/teacher/students-view/student-view'),
            },
            {
              path: ':bookId',
              lazy: () => import('views/teacher/students-view/tabs/reading-history-details'),
            },
          ],
        },
      ],
    },
    {
      path: 'classes/insights',
      lazy: () => import('views/teacher/insights-view/insights-view'),
    },
    {
      path: 'mis-sync-import',
      element: (
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/mis-sync-import-view/mis-sync-import-view'),
        },
      ],
    },
    // Staff manager routes
    {
      path: 'staff',
      element: (
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/staff-view/staff-view-v2'),
        },
        {
          path: ':userId',
          lazy: () => import('views/teacher/staff-view/edit-staff-view-v2'),
        },
        {
          path: 'keycontacts',
          lazy: () => import('views/teacher/staff-view/key-contacts-view'),
        },
      ],
    },
    {
      path: 'school-settings',
      element: (
        <RequiresFlag flag={Flags.SubscriptionManager}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </RequiresFlag>
      ),
      children: [
        {
          path: '',
          loader: () => redirect('subscription'),
        },
        {
          path: 'subscription',
          lazy: () => import('views/teacher/settings-view/settings-view'),
        },
      ],
    },
    {
      path: subPathForView(View.NewYearSetup, View.Teacher),
      element: (
        <Suspense fallback={<Loading />}>
          <SuspenseStaffContextProvider>
            <TrainingContext>
              <Outlet />
            </TrainingContext>
          </SuspenseStaffContextProvider>
        </Suspense>
      ),
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/new-year-setup-view/new-year-setup-view'),
          children: [
            {
              path: '',
              loader: () => redirect('overview'),
            },
            {
              path: 'overview',
              lazy: () =>
                import('views/teacher/new-year-setup-view/tabs/overview-tab/overview-tab'),
            },
            {
              path: 'manage-mis',
              lazy: () => import('views/teacher/new-year-setup-view/tabs/mis-tab/mis-tab'),
            },
            {
              path: 'manage-staff',
              lazy: () => import('views/teacher/new-year-setup-view/tabs/staff-tab/staff-tab'),
            },
            {
              path: 'staff-training',
              lazy: () =>
                import('views/teacher/new-year-setup-view/tabs/training-tab/training-tab'),
            },
            {
              path: 'reset',
              lazy: () => import('views/teacher/new-year-setup-view/tabs/reset-tab/reset-tab'),
            },
            {
              path: 'extra-settings',
              lazy: () =>
                import('views/teacher/new-year-setup-view/tabs/settings-tab/settings-tab'),
            },
            {
              path: 'import-classes-and-students',
              lazy: () => import('views/teacher/new-year-setup-view/tabs/import-tab/import-tab'),
            },
          ],
        },
      ],
    },
    {
      path: 'feedback',
      element: (
        <RequiresFlag flag={Flags.CannyEnabled}>
          <Outlet />
        </RequiresFlag>
      ),
      children: [
        {
          path: '',
          lazy: () => import('views/teacher/feedback-view/feedback-view'),
        },
      ],
    },
  ],
};
