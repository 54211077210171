import { fromUnixTime, getUnixTime, isBefore, sub } from 'date-fns';

const LOCAL_STORAGE_KEY = 'last-reload-attempt';

export const attemptReload = () => {
  const lastAttemptAt = getLastAttemptAt();
  if (!lastAttemptAt || isLastAttemptBeforeThreshold(lastAttemptAt)) {
    setLastAttemptNow();
    window.location.reload();
  }
};

const getLastAttemptAt = (): Date | null => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY);

  return value ? fromUnixTime(parseInt(value, 10)) : null;
};

const setLastAttemptNow = () =>
  localStorage.setItem(LOCAL_STORAGE_KEY, getUnixTime(new Date()).toString());

const isLastAttemptBeforeThreshold = (lastAttemptAt: Date) =>
  isBefore(lastAttemptAt, sub(new Date(), { seconds: 10 }));
