import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { TrainingProgressWheel } from 'views/teacher/teacher-training-view/components/training-progress-wheel';
import { pathForView } from 'views/views';

import styles from './teacher-training-list-item.module.css';

export const TeacherTrainingListItem = () => {
  const navigate = useNavigate();

  return (
    <li>
      <Button
        onClick={() => navigate(pathForView(View.TeacherTraining))}
        className={styles.MenuButton}
        data-test-id="header-menu-teacher-training"
        leftIcon={<FontAwesomeIcon fixedWidth={true} icon={faChalkboardTeacher} />}
        analyticsEvent={{ category: 'Header dropdown', action: 'Clicked teacher training' }}
      >
        <div className={styles.ButtonContent}>
          Sparx Reader Training
          <div className={styles.Wheel}>
            <TrainingProgressWheel size="s" background="var(--slate-grey-20)" />
          </div>
        </div>
      </Button>
    </li>
  );
};
