// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/settings.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.users.v1.Settings
 */
export interface Settings {
  /**
   * DEPRECATED: Scale of font size when reading book text.
   *
   * @deprecated
   * @generated from protobuf field: sparx.reading.users.v1.FontScale font_scale = 1 [deprecated = true];
   */
  fontScale: FontScale;
  /**
   * Scale of font size when reading book text.
   * Unlike the deprecated font_scale, this value is continuously variable which allows us to be more flexible.
   * To avoid floating point rounding complications, this is stored as 10x the multiplier, e.g. 10 represents 1x scale.
   *
   * @generated from protobuf field: int32 font_scale_continuous = 7;
   */
  fontScaleContinuous: number;
  /**
   * Line height scale when reading book text.
   *
   * @generated from protobuf field: sparx.reading.users.v1.LineHeight line_height_scale = 2;
   */
  lineHeightScale: LineHeight;
  /**
   * Scale of letter spacing when reading book text.
   *
   * @generated from protobuf field: sparx.reading.users.v1.LetterSpacing letter_spacing_scale = 3;
   */
  letterSpacingScale: LetterSpacing;
  /**
   * Font to use when reading book text.
   *
   * @generated from protobuf field: sparx.reading.users.v1.Font font = 4;
   */
  font: Font;
  /**
   * Background colour behind book text. Used for accessibility.
   *
   * @generated from protobuf field: sparx.reading.users.v1.Background background = 5;
   */
  background: Background;
  /**
   * Reading guide to use when reading book text.
   *
   * @generated from protobuf field: sparx.reading.users.v1.ReadingGuide guide = 6;
   */
  guide: ReadingGuide;
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.FontScale
 */
export enum FontScale {
  /**
   * @generated from protobuf enum value: FONT_SCALE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: FONT_SCALE_NORMAL = 1;
   */
  NORMAL = 1,
  /**
   * @generated from protobuf enum value: FONT_SCALE_LARGE = 2;
   */
  LARGE = 2,
  /**
   * @generated from protobuf enum value: FONT_SCALE_LARGER = 3;
   */
  LARGER = 3,
  /**
   * @generated from protobuf enum value: FONT_SCALE_LARGEST = 4;
   */
  LARGEST = 4,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.LineHeight
 */
export enum LineHeight {
  /**
   * @generated from protobuf enum value: LINE_HEIGHT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: LINE_HEIGHT_NORMAL = 1;
   */
  NORMAL = 1,
  /**
   * @generated from protobuf enum value: LINE_HEIGHT_LARGE = 2;
   */
  LARGE = 2,
  /**
   * @generated from protobuf enum value: LINE_HEIGHT_LARGER = 3;
   */
  LARGER = 3,
  /**
   * @generated from protobuf enum value: LINE_HEIGHT_LARGEST = 4;
   */
  LARGEST = 4,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.LetterSpacing
 */
export enum LetterSpacing {
  /**
   * @generated from protobuf enum value: LETTER_SPACING_UNSPECIFIED = 0;
   */
  LETTER_SPACING_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: LETTER_SPACING_0 = 1;
   */
  LETTER_SPACING_0 = 1,
  /**
   * @generated from protobuf enum value: LETTER_SPACING_1 = 2;
   */
  LETTER_SPACING_1 = 2,
  /**
   * @generated from protobuf enum value: LETTER_SPACING_2 = 3;
   */
  LETTER_SPACING_2 = 3,
  /**
   * @generated from protobuf enum value: LETTER_SPACING_3 = 4;
   */
  LETTER_SPACING_3 = 4,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.Font
 */
export enum Font {
  /**
   * @generated from protobuf enum value: FONT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: FONT_VERDANA = 1;
   */
  VERDANA = 1,
  /**
   * @generated from protobuf enum value: FONT_COMIC_SANS = 2;
   */
  COMIC_SANS = 2,
  /**
   * @generated from protobuf enum value: FONT_READABLE = 3;
   */
  READABLE = 3,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.Background
 */
export enum Background {
  /**
   * @generated from protobuf enum value: BACKGROUND_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: BACKGROUND_WHITE = 1;
   */
  WHITE = 1,
  /**
   * @generated from protobuf enum value: BACKGROUND_BLUE = 2;
   */
  BLUE = 2,
  /**
   * @generated from protobuf enum value: BACKGROUND_CREAM = 3;
   */
  CREAM = 3,
  /**
   * @generated from protobuf enum value: BACKGROUND_YELLOW = 4;
   */
  YELLOW = 4,
  /**
   * @generated from protobuf enum value: BACKGROUND_GREEN = 5;
   */
  GREEN = 5,
  /**
   * @generated from protobuf enum value: BACKGROUND_PURPLE = 6;
   */
  PURPLE = 6,
  /**
   * @generated from protobuf enum value: BACKGROUND_PINK = 7;
   */
  PINK = 7,
  /**
   * @generated from protobuf enum value: BACKGROUND_ORANGE = 8;
   */
  ORANGE = 8,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.ReadingGuide
 */
export enum ReadingGuide {
  /**
   * @generated from protobuf enum value: READING_GUIDE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: READING_GUIDE_LINE = 1;
   */
  LINE = 1,
  /**
   * @generated from protobuf enum value: READING_GUIDE_MASK = 2;
   */
  MASK = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class Settings$Type extends MessageType<Settings> {
  constructor() {
    super('sparx.reading.users.v1.Settings', [
      {
        no: 1,
        name: 'font_scale',
        kind: 'enum',
        T: () => ['sparx.reading.users.v1.FontScale', FontScale, 'FONT_SCALE_'],
      },
      {
        no: 7,
        name: 'font_scale_continuous',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'line_height_scale',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.LineHeight',
          LineHeight,
          'LINE_HEIGHT_',
        ],
      },
      {
        no: 3,
        name: 'letter_spacing_scale',
        kind: 'enum',
        T: () => ['sparx.reading.users.v1.LetterSpacing', LetterSpacing],
      },
      {
        no: 4,
        name: 'font',
        kind: 'enum',
        T: () => ['sparx.reading.users.v1.Font', Font, 'FONT_'],
      },
      {
        no: 5,
        name: 'background',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.Background',
          Background,
          'BACKGROUND_',
        ],
      },
      {
        no: 6,
        name: 'guide',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.ReadingGuide',
          ReadingGuide,
          'READING_GUIDE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.Settings
 */
export const Settings = new Settings$Type();
