import * as Sentry from '@sentry/react';
import { AppContainer } from 'app/containers/app-container/app-container';
import { ViewTransitionRoutes } from 'app/containers/app-container/view-transition-group';
import { AlertProvider } from 'components/alert/alert';
import { ClientEventProvider } from 'components/client-events/client-event-provider';
import { ErrorPage } from 'components/errors/error-message';
import { Header } from 'components/sections/header';
import { useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, useRouteError } from 'react-router-dom';
import { View } from 'views';
import { LandingView } from 'views/common/landing-view/landing-view';
import { SparxStaffFeaturesProvider } from 'views/teacher/components/sparx-staff-features-provider';
import { pathForView } from 'views/views';

import { adminRoutes } from './admin-routes';
import { contentAdminRoutes } from './content-admin-routes';
import { studentRoutes } from './student-routes';
import { teacherRoutes } from './teacher-routes';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <SentryRouteErrorFallback />,
    children: [
      {
        path: '/login',
        element: <Navigate to="/" replace={true} />,
      },
      {
        path: pathForView(View.Landing),
        element: <LandingView />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ClientEventProvider pushURL={window?.settings?.interactionGatewayURL}>
        <AlertProvider>
          <SparxStaffFeaturesProvider>
            <AppContainer>
              <Header />
              <ViewTransitionRoutes />
            </AppContainer>
          </SparxStaffFeaturesProvider>
        </AlertProvider>
      </ClientEventProvider>
    ),
    errorElement: <SentryRouteErrorFallback />,
    children: [teacherRoutes, ...studentRoutes, contentAdminRoutes, adminRoutes],
  },
]);

function SentryRouteErrorFallback() {
  const routeError = useRouteError() as Error;

  useEffect(() => {
    if (!routeError) {
      return;
    }
    Sentry.captureException(routeError, { level: 'fatal' });
  }, [routeError]);

  return (
    <AppContainer>
      <Header />
      <ErrorPage message="An error has occurred">
        <p>We&apos;re really sorry but an unexpected error has occurred.</p>
        <p>
          Please <strong>refresh the page</strong> and try again.
        </p>
      </ErrorPage>
    </AppContainer>
  );
}
