import { ReaderDevTools } from 'components/reader-dev-tools/reader-dev-tools';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import React from 'react';

export const showDevToolsAtom = atomWithStorage('reader-dev-tools', false);

const ReactQueryDevtoolsTest = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);

export const DevTools = () => {
  const [showDevTools] = useAtom(showDevToolsAtom);

  if (!showDevTools) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <ReactQueryDevtoolsTest />

      <ReaderDevTools />
    </React.Suspense>
  );
};
