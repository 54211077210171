import { User, UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import {
  UserInteractionClientMessage,
  UserInteractionEvent,
} from '@sparx/api/genproto/apis/uievents/uievents';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import { atom, useAtom } from 'jotai';
import { UserRole, useUser, useUserHasRole } from 'queries/session';
import React, { createContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

type DefaultFields =
  | 'application'
  | 'schoolId'
  | 'userId'
  | 'sessionId'
  | 'connectionId'
  | 'version'
  | 'serverTimestamp';

type AutomaticFields = 'timestamp' | 'eventIndex' | 'page';

type UserInteractionEventDefaults = Pick<UserInteractionEvent, DefaultFields>;
type UserInteractionEventFields = Omit<UserInteractionEvent, DefaultFields | AutomaticFields>;

export interface AnalyticEventFields {
  category: string;
  action: string;
  labels?: EventLabels;
}

export type EventLabels = {
  [key: string]: string | undefined | boolean | null | number;
};

interface SendEventOptions {
  immediate?: boolean;
}

interface EventContextValues {
  sendEvent: (event: AnalyticEventFields, opts?: SendEventOptions) => void;
  ready: boolean;
}

// Get the application from the user's type and whether they can use EQM.
// application can be: 'reader-student' | 'reader-teacher' | 'reader-admin'
export const getApplication = (user: User | undefined, canSeeEQM: boolean) => {
  if (canSeeEQM) {
    return 'reader-admin';
  }
  if (user?.type === UserType.TEACHER) {
    return 'reader-teacher';
  }
  if (user?.type === UserType.STUDENT) {
    return 'reader-student';
  }
  return 'reader-unknown';
};

export const EventContext = createContext<EventContextValues>({
  sendEvent: () => {
    return;
  },
  ready: false,
});

export const clientEventsAtom = atom<UserInteractionEvent[]>([]);

interface ClientEventProviderProps {
  children: React.ReactNode;
  pushURL: string | undefined;
}

export const ClientEventProvider = ({ children, pushURL }: ClientEventProviderProps) => {
  const user = useUser();
  const location = useLocation();
  const canSeeEQM = useUserHasRole(UserRole.EQMRead);
  const [hasNewEvents, setHasNewEvents] = useState<boolean>(false);
  const [sentEvents, setSentEvents] = useAtom(clientEventsAtom);
  const isInterim = useSchoolInInterimState(Boolean(user && user.type === UserType.TEACHER));

  useQuery(
    ['eventpump'],
    async () => {
      const request: UserInteractionClientMessage = {
        metrics: loadClientEvents(),
        timestamp: Timestamp.now(),
      };
      if (request.metrics.length > 0) {
        const response = await fetch(pushURL || '', {
          body: UserInteractionClientMessage.toJsonString(request),
          method: 'POST',
        });
        if (response.status !== 500) {
          setClientEvents([]);
        }
        if (!response.ok) {
          throw response;
        }
      }
      return true;
    },
    {
      onSuccess: () => setHasNewEvents(false),
      refetchInterval: 5000,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: Boolean(pushURL) && hasNewEvents,
    },
  );

  const buildUserInteractionEvent = (event: UserInteractionEventFields): UserInteractionEvent => {
    // Increment the event index
    const index = eventIndex;
    setEventIndex(i => i + 1);

    let page = location.pathname + location.search;
    if (isInterim && user?.type === UserType.TEACHER) {
      page += location.search ? '&interim=true' : '?interim=true';
    }

    return {
      ...defaultFields,
      timestamp: Timestamp.now(),
      eventIndex: index,
      page,
      ...event,
    };
  };

  const sendImmediateEvent = (event: UserInteractionEventFields) => {
    fetch(pushURL || '', {
      body: UserInteractionClientMessage.toJsonString({
        metrics: [buildUserInteractionEvent(event)],
        timestamp: Timestamp.now(),
      }),
      method: 'POST',
      keepalive: true, // ensure send as page navigate away
    }).catch(e => {
      console.error('Failed to send page event', e);
    });
  };

  const connectionId = useMemo(() => uuid(), []);
  const [eventIndex, setEventIndex] = useState(0);

  const defaultFields: UserInteractionEventDefaults = useMemo(
    () => ({
      application: getApplication(user, canSeeEQM),
      schoolId: user?.schoolId || '',
      userId: user?.sparxUserId || user?.userId || '', // Fall-back to user ID for sparx accounts.
      sessionId: '', // unused
      serverTimestamp: undefined, // unused
      connectionId,
      version: import.meta.env.VITE_RELEASE_COMMIT || 'dev',
    }),
    [user, connectionId, canSeeEQM],
  );

  const sendEvent = (event: UserInteractionEventFields, opts?: SendEventOptions) => {
    if (opts?.immediate) {
      sendImmediateEvent(event);
      return;
    }

    // TODO: can we store the event until the user is logged in?
    setHasNewEvents(true);
    if (!defaultFields.userId) return; // not initialised

    // Store the event
    const clientEvents = loadClientEvents();

    const userInteractionEvent = buildUserInteractionEvent(event);

    if (pushURL) {
      setClientEvents(clientEvents.concat([userInteractionEvent]));
    }
    setSentEvents([userInteractionEvent, ...sentEvents]);
  };

  return (
    <EventContext.Provider
      value={{
        sendEvent: (event, opts) =>
          sendEvent({ ...event, labels: castLabelsToStringMap(event.labels ?? {}) }, opts),
        ready: true,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

// Converts the EventLabels into a map of string to string
const castLabelsToStringMap = (labels: EventLabels): Record<string, string> => {
  const stringLabels: Record<string, string> = {};
  for (const [k, v] of Object.entries(labels)) {
    if (v === undefined || v === null) continue; // ignore
    switch (typeof v) {
      case 'number':
        stringLabels[k] = v.toString();
        break;
      case 'boolean':
        stringLabels[k] = v ? 'true' : 'false';
        break;
      default:
        stringLabels[k] = v;
        break;
    }
  }
  return stringLabels;
};

export const useClientEvent = () => React.useContext(EventContext);

const clientEventsLocalStorageKey = 'rdr/clientevents';
const clientEventsMaxLocal = 50;

const loadClientEvents = (): UserInteractionEvent[] => {
  const store = localStorage.getItem(clientEventsLocalStorageKey);
  let events = [];
  try {
    const parsed = JSON.parse(store || '[]');
    events = parsed || [];
    if (events.length > clientEventsMaxLocal) {
      throw new Error('local storage events exceeded maximum, discarding');
    }
  } catch (e) {
    console.error('Failed to parse client events, clearing', e);
    localStorage.removeItem(clientEventsLocalStorageKey);
  }
  return events;
};

const setClientEvents = (events: UserInteractionEvent[]) => {
  try {
    localStorage.setItem(clientEventsLocalStorageKey, JSON.stringify(events));
  } catch (e) {
    console.error('Failed to store client events', e);
  }
};
