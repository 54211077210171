import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { useClearAlert } from 'components/alert/alert';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons/button';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Loading } from 'components/loading/loading';
import panelStyles from 'components/tasks/panel.module.css';
import { useListLibraryBooks } from 'queries/library-books';
import { useState } from 'react';
import { FCWithOptionalChildren } from 'utils/props';

import styles from './start-book-alert.module.css';

const newBookAlertTitles = [
  "Great choice, we think you'll like this",
  'Ready to dive into your next adventure?',
  'Could this be your next favourite book?',
];
const swappedBookAlertTitles = [
  'Well done for giving this another go',
  "Great, let's jump back in!",
];

interface IStartBookAlertProps {
  selectedBook: MetadataAbridged;
  otherBooks: MetadataAbridged[];
  onConfirm: () => void;
  isSwappedBook: boolean;
}

export const StartBookAlert: FCWithOptionalChildren<IStartBookAlertProps> = ({
  selectedBook,
  otherBooks,
  onConfirm,
  isSwappedBook,
}) => {
  const { sendEvent } = useClientEvent();
  const closeAlert = useClearAlert();
  const libraryBooks = useListLibraryBooks();

  const [alertTitle] = useState(
    isSwappedBook
      ? swappedBookAlertTitles[Math.floor(Math.random() * swappedBookAlertTitles.length)]
      : newBookAlertTitles[Math.floor(Math.random() * newBookAlertTitles.length)],
  );

  if (libraryBooks.isLoading || libraryBooks.isError) {
    return <Loading />;
  }

  const close = () => {
    sendEvent({ category: 'book choice', action: 'closed start book dialog' });
    closeAlert();
  };

  const activeBooks = libraryBooks.data.libraryBooks.filter(book => book.studentBook?.isActive);

  return (
    <div className={classNames(panelStyles.ConfirmAlert, panelStyles.ConfirmAlertLarge)}>
      <div className={panelStyles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={panelStyles.ModalTitle}>{alertTitle}</h2>

      <div className={styles.BooksContainer}>
        {activeBooks.map(book => (
          <div key={book.studentBook?.bookId}>
            <BookImage book={book.metadataAbridged} className={styles.BookCover} />
          </div>
        ))}
        <BookImage
          book={selectedBook}
          className={classNames(styles.BookCover, styles.NewBookCover)}
        />
      </div>

      {activeBooks.length ? (
        <p>
          Starting this book will complete your book shelf. You will not be able to start any more
          books without swapping.
        </p>
      ) : (
        <p>Starting this book will use up one of your shelf spaces.</p>
      )}

      <p>If you&apos;ve changed your mind, select cancel and choose again.</p>

      <div className={panelStyles.Buttons}>
        <Button variant="secondary" onClick={close} analyticsEvent={undefined}>
          Cancel
        </Button>
        <Button
          autoFocus={true}
          onClick={() => onConfirm()}
          analyticsEvent={{
            category: 'library',
            action: 'clicked start new book',
            labels: {
              selectedBook: selectedBook.name,
              otherBookOptions: otherBooks.map(book => book.name).join(','),
            },
          }}
        >
          {`${isSwappedBook ? 'Continue' : 'Start'} reading`}
        </Button>
      </div>
    </div>
  );
};
