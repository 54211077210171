import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'training-dashboard-widget';

export const clickedButton = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked button',
});

export const dismissedWidget = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'dismissed widget',
});
